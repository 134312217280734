<template>
  <div
    class="rounded border internet-connection-status px-1"
    :class="{
      'internet-connection-status-error': !onLine,
      'internet-connection-status-back-online': showBackOnline,
    }"
    v-if="!onLine || showBackOnline"
  >
    <i class="bi bi-wifi-off bi-xxlg px-1" v-if="!onLine"></i>
    <i class="bi bi-wifi bi-xxlg px-1" v-else></i>
    {{
      !onLine
        ? this.$t(
            "Components.CheckInternetConnection.YouAreOffline",
            {},
            { locale: this.$store.state.activeLang }
          )
        : showBackOnline
        ? this.$t(
            "Components.CheckInternetConnection.BackOnline",
            {},
            { locale: this.$store.state.activeLang }
          )
        : ""
    }}
  </div>
</template>
<script>
export default {
  data() {
    return {
      onLine: navigator.onLine,
      showBackOnline: false,
    };
  },
  methods: {
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";
    },
  },
  watch: {
    onLine(v) {
      if (v) {
        this.showBackOnline = true;
        setTimeout(() => {
          this.showBackOnline = false;
        }, 1000);
      }
    },
  },
  mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
};
</script>
