import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

var ls = new SecureLS({ isCompression: false });

const getDefaultState = () => {
  return {
    companyPublicId: null,
    userPublicId: null,
    userData: null,
    token: null,
    refreshToken: null,
    tokenExpiration: null,
    activeTheme: localStorage.getItem("activeTheme") || null,
    activeLang:
      localStorage.getItem("activeLang") ||
      process.env.VUE_APP_I18N_LOCALE ||
      "tr",
    dateFormat: null,
    dateTimeFormat: null,
    timeFormat: null,
    isMultiLanguage: localStorage.getItem("isMultiLanguage") || false,
    isMobile: false,
    customObjectDto: null,
    customObjectWithChildCountList: null,
    internalErrorDescription: null,
    companyLanguages: [],
  };
};

export default createStore({
  state: getDefaultState(),
  mutations: {
    initUserData(state, userData) {
      state.userData = userData;
    },
    signOutUser(state) {
      Object.assign(state, getDefaultState());
      localStorage.clear();
    },
    setUser(state, userData) {
      state.userData = userData;
      state.token = userData.token;
      state.refreshToken = userData.refreshToken;
      state.tokenExpiration = userData.expiration;
      state.isMultiLanguage = userData.isMultiLanguage;

      state.userPublicId = userData.publicId;
      localStorage.setItem("userPublicId", state.userPublicId);

      localStorage.setItem("token", encodeURIComponent(state.token));
      localStorage.setItem(
        "refreshToken",
        encodeURIComponent(state.refreshToken)
      );
      localStorage.setItem("tokenExpirationDate", state.tokenExpiration);

      if (!String.isNullOrWhiteSpace(userData.activeLanguage)) {
        state.activeLang = userData.activeLanguage;
        localStorage.setItem("activeLang", userData.activeLanguage);
      }
      if (!String.isNullOrWhiteSpace(userData.activeTheme)) {
        state.activeTheme = userData.activeTheme;
        localStorage.setItem("activeTheme", userData.activeTheme);
      }

      var currentCulture = localStorage.getItem("activeLang");
      if (currentCulture == "tr") {
        state.dateFormat = "dd.MM.yyyy";
        state.dateTimeFormat = "dd.MM.yyyy HH:mm";
        state.timeFormat = "HH:mm";
      } else if (currentCulture == "en") {
        state.dateFormat = "M/d/yyyy";
        state.dateTimeFormat = "M/d/yyyy hh:mm a";
        state.timeFormat = "hh:mm a";
      }
    },
    setUserInfo(state, userInfo) {
      state.userData.informations = userInfo;
      state.companyPublicId = userInfo?.company?.publicId;
    },
    setCompanyLanguages(state, languages) {
      state.companyLanguages = languages;
    },
    setCustomObjectWithChildCountList(state, customObjectWithChildCountList) {
      state.customObjectWithChildCountList = customObjectWithChildCountList;
    },
    setToken(state, info) {
      localStorage.setItem("token", encodeURIComponent(info.accessToken));
      localStorage.setItem(
        "refreshToken",
        encodeURIComponent(info.refreshToken)
      );
      localStorage.setItem("tokenExpirationDate", info.accessTokenExpiration);
      state.token = info.accessToken;
      state.refreshToken = info.refreshToken;
      state.tokenExpiration = info.accessTokenExpiration;
    },
    setActiveLang(state, lang) {
      state.activeLang = lang;
      localStorage.setItem("activeLang", lang);

      if (lang == "tr") {
        state.dateFormat = "dd.MM.yyyy";
        state.dateTimeFormat = "dd.MM.yyyy HH:mm";
        state.timeFormat = "HH:mm";
      } else if (lang == "en") {
        state.dateFormat = "M/d/yyyy";
        state.dateTimeFormat = "M/d/yyyy hh:mm a";
        state.timeFormat = "hh:mm a";
      }
    },
    setIsMobileDevice(state, value) {
      state.isMobile = value;
    },
    setCustomObject(state, customObjectDto) {
      state.customObjectDto = customObjectDto;
    },
    setInternalErrorDescription(state, value) {
      state.internalErrorDescription = value;
    },
  },
  getters: {
    getUserData(state) {
      return state?.userData;
    },
    userName(state) {
      var userName = `${
        state?.userData?.firstName
      } ${state?.userData?.lastName.substring(0, 1)}.`;
      return userName.substring(0, 10).trim();
    },
    companyName(state) {
      return state?.userData?.informations?.company?.name
        .substring(0, 15)
        .trim();
    },
    userInfo(state) {
      return state?.userData?.informations;
    },
    _isAuthenticated: (state) => state?.userData !== null,
    _currentUserId: (state) => state?.userData?.publicId,
    _activeLang: (state) => state?.activeLang,
    _activeLangTR: (state) => state?.activeLang === "tr",
    _activeLangEN: (state) => state?.activeLang === "en",
    _dateFormat: (state) => state?.dateFormat,
    _dateTimeFormat: (state) => state?.dateTimeFormat,
    _timeFormat: (state) => state?.timeFormat,
    _token: (state) => state?.token,
    _isMultiLanguage: (state) => state?.isMultiLanguage,
    _currentUserEmailAddress: (state) => state?.userData?.email,
    _isSuperAdmin: (state) => state?.userData?.informations?.isSuperAdmin,
    _userEmailAddress: (state) => state?.userData?.informations?.email,
    _isMobile: (state) => state?.isMobile,
    _customObjectDto: (state) => state?.customObjectDto,
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
