<template>
  <div
    class="modal fade"
    :id="modalId"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    :aria-labelledby="`${modalId}_modalDeleteLabel`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="`${modalId}_modalDeleteLabel`">
            <i class="bi bi-trash"></i>
            {{
              $t(
                "Components.DeleteModal.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            v-if="!this.buttonDisabled"
            @click="clear"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <span class="text-danger" v-if="errors.length == 0"
            ><i class="bi bi-exclamation-circle"></i>
            {{
              $t(
                "Components.DeleteModal.Description",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </span>
          <div v-if="errors.length > 0" class="alert alert-warning">
            <ul class="mb-0">
              <li v-for="error in errors" v-bind:key="error">
                {{ error }}
              </li>
            </ul>
          </div>
        </div>
        <div
          class="modal-footer"
          :class="
            errors.length == 0
              ? 'justify-content-between'
              : 'justify-content-right'
          "
        >
          <label
            :for="`${modalId}_deleteDataCheck`"
            v-if="errors.length == 0"
            :class="{ shake: shakeAnimate }"
          >
            <input
              type="checkbox"
              :id="`${modalId}_deleteDataCheck`"
              class="me-1"
              :checked="confirmDeletion"
              v-model="confirmDeletion"
              :disabled="this.buttonDisabled"
            />
            <text>{{
              $t(
                "Components.DeleteModal.IConfirmDeletion",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</text>
          </label>
          <div>
            <button
              type="button"
              class="btn btn-danger me-2 delete-record"
              v-if="errors.length == 0"
              :disabled="this.buttonDisabled"
              @click="deleteRecord"
            >
              <span>
                <i class="bi bi-trash"></i>
                {{
                  $t(
                    "Components.DeleteModal.DeleteButton",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</span
              >
            </button>
            <button
              type="button"
              class="btn btn-success btn-close-modal"
              @click="clear"
              v-if="!this.buttonDisabled"
              data-bs-dismiss="modal"
            >
              <i class="bi bi-x"></i>
              {{
                $t(
                  "Components.DeleteModal.DeleteClose",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "ModalDelete",
  data() {
    return {
      errors: [],
      shakeAnimate: false,
      confirmDeletion: false,
      buttonDisabled: false,
      submitButtonHtml: String.format(
        '<span class="loading-block d-flex justify-content-center align-items-center"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="ms-2">{0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
    };
  },
  props: {
    modalId: {
      type: String,
      default: "modalDelete",
    },
    deleteRecordId: {
      type: String,
      default: "",
    },
    deleteActionUrl: {
      type: String,
      default: "",
    },
    deleteBeforeActionUrl: {
      type: String,
      default: "",
    },
    isGatewayRequest: {
      type: Boolean,
      default: false,
    },
    isBrsRequest: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    shake() {
      this.shakeAnimate = true;
      setTimeout(() => {
        this.shakeAnimate = false;
      }, 3000);
    },
    clear: function () {
      this.confirmDeletion = false;
      this.buttonDisabled = false;
      this.errors = [];
    },
    success: function ($firstSpan, $loadingBlock) {
      $firstSpan.show();
      $loadingBlock.remove();
      this.buttonDisabled = false;
      this.confirmDeletion = false;
      setTimeout(() => {
        $(".btn-close-modal").trigger("click");
      }, 300);
    },
    error: function ($firstSpan, $loadingBlock) {
      $firstSpan.show();
      $loadingBlock.remove();
      this.buttonDisabled = false;
      this.confirmDeletion = false;
    },
    deleteRecordHttpRequest(instance, firstSpan, loadingBlock) {
      var self = this;
      instance
        .post(
          String.format("{0}?id={1}", this.deleteActionUrl, this.deleteRecordId)
        )
        .then((response) => {
          const result = response.data;
          if (result.isOk) {
            this.success(firstSpan, loadingBlock);
            this.$emit("deleteAfterCallMethod");
          } else {
            this.error(firstSpan, loadingBlock);
            if (!String.isNullOrWhiteSpace(result.msg))
              self.errors.push(result.msg);
            if (!String.isNullOrWhiteSpace(result.message))
              self.errors.push(result.message);
          }
        })
        .catch(function (error) {
          self.errors.push(error);
        });
    },
    deleteRecord: function () {
      if (
        !this.confirmDeletion ||
        String.isNullOrWhiteSpace(this.deleteRecordId) ||
        String.isNullOrWhiteSpace(this.deleteActionUrl)
      ) {
        this.shake();
        return;
      }

      this.buttonDisabled = true;

      var button = $(String.format(".delete-record")),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block");

      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      var instance = this.$appAxios;
      if (this.isBrsRequest) {
        instance = this.$brsAxios;
      } else if (this.isGatewayRequest) {
        instance = this.$prodGatewayAxios;
      }

      let self = this;
      if (!String.isNullOrWhiteSpace(this.deleteBeforeActionUrl)) {
        instance
          .get(
            String.format(
              "{0}?id={1}",
              this.deleteBeforeActionUrl,
              this.deleteRecordId
            )
          )
          .then((response) => {
            const result = response.data;
            if (result.isOk) {
              self.deleteRecordHttpRequest(instance, firstSpan, loadingBlock);
            } else {
              self.error(firstSpan, loadingBlock);
              if (!String.isNullOrWhiteSpace(result.msg))
                self.errors.push(result.msg);
              if (!String.isNullOrWhiteSpace(result.message))
                self.errors.push(result.message);
            }
          })
          .catch(function (error) {
            self.errors.push(error);
          });
      } else {
        self.deleteRecordHttpRequest(instance, firstSpan, loadingBlock);
      }
    },
  },
};
</script>
