<template>
  <p class="placeholder-glow" :class="classes">
    <span class="placeholder m-1 col-7"></span>
    <span class="placeholder m-1 col-4"></span>
    <span class="placeholder m-1 col-4"></span>
    <span class="placeholder m-1 col-6"></span>
    <span class="placeholder m-1 col-8"></span>
    <span class="placeholder m-1 col-7"></span>
    <span class="placeholder m-1 col-4"></span>
    <span class="placeholder m-1 col-4"></span>
    <span class="placeholder m-1 col-6"></span>
    <span class="placeholder m-1 col-8"></span>
  </p>
  <!-- <div class="set-loader" :class="classes">
    <img
      alt="SetXrm Cloud Solutions"
      class="img-fluid set-loader-loading set-loader-logo"
      :src="require('@/assets/images/logo/setxrm-logo-white.png')"
      width="400"
    /> 
     <div class="set-loader-main">
      <div class="set-loader-element text-center"></div>
     <div class="fw-bolder set-loader-element fs-4 text-primary">
        {{
          this.$t(
            "BaseModelFields.PageDesignLoading",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </div>
      <div class="set-loader-loading set-loader-element set-loader-title"></div>
      <div
        class="set-loader-loading set-loader-element set-loader-subtitle"
      ></div>
    </div>
  </div> -->
</template>
<script>
export default {
  name: "SetLoader",
  props: ["classes"],
};
</script>
