<template>
  <div class="app-sidebar border-end">
    <div
      class="logo border"
      :class="{ 'hidden-sidebar-logo': !$isMobile() && isSxAsideHidden }"
    >
      <router-link :to="{ path: '/' }" class="logo-icon">
        <span class="logo-text">
          {{ $t("Slogan1", {}, { locale: this.$store.state.activeLang })
          }}<br />
          {{ $t("Slogan2", {}, { locale: this.$store.state.activeLang }) }}
        </span>
      </router-link>
      <div class="sidebar-user-switcher user-activity-online">
        <router-link :to="{ path: '/' }" class="d-flex align-items-center">
          <!-- <span class="activity-indicator"></span> -->
          <template v-if="$store.getters._isAuthenticated">
            <span class="user-info-text"
              >{{ this.$store.getters.userName }}<br />
              <small class="fs-12">
                {{ this.$store.getters.companyName }}</small
              ></span
            >
          </template>
          <img :src="require('@/assets/images/user/user-icon-sm.png')" />
        </router-link>
      </div>
    </div>
    <div class="app-menu">
      <ul class="accordion-menu">
        <li class="sidebar-title d-none d-lg-block">
          <!-- {{$t('Sidebar.Apps', {}, {locale:this.$store.state.activeLang})}} -->
        </li>
        <li class="active-page">
          <router-link :to="{ path: '/' }"
            ><i class="bi bi-house-door-fill bi-style"></i
            >{{
              $t(
                "Sidebar.HomePage",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</router-link
          >
        </li>
        <li>
          <a href="#"
            ><i class="bi bi-box-fill bi-style"></i
            >{{
              $t(
                "Sidebar.CustomObjectManagements",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}<i class="bi bi-caret-right has-sub-menu"></i
          ></a>
          <ul class="sub-menu">
            <li>
              <router-link :to="{ path: '/CustomObject/List' }">{{
                $t(
                  "Sidebar.CustomObjects",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
          </ul>
        </li>
        <li>
          <a href="#"
            ><i class="bi bi-lightning-fill bi-style"></i
            >{{
              $t(
                "Sidebar.BusinessProcessManagement",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}<i class="bi bi-caret-right has-sub-menu"></i
          ></a>
          <ul class="sub-menu">
            <li>
              <router-link :to="{ path: '/BusinessProcess/Dashboard' }">{{
                $t(
                  "Sidebar.Dashboard",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/BusinessProcess/List' }">{{
                $t(
                  "Sidebar.BusinessProcess",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
          </ul>
        </li>
        <li>
          <a href="#"
            ><i class="bi bi-file-code-fill bi-style"></i
            >{{
              $t(
                "Sidebar.SetCodeFunctions",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}<i class="bi bi-caret-right has-sub-menu"></i
          ></a>
          <ul class="sub-menu">
            <li>
              <router-link :to="{ path: '/SetCode/Function/List' }">{{
                $t(
                  "Sidebar.Functions",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/SetCode/Model/List' }">{{
                $t(
                  "Sidebar.SetCodeModels",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/SetCode/HttpConnection/List' }">{{
                $t(
                  "Sidebar.HttpConnections",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/SetCode/Database/List' }">{{
                $t(
                  "Sidebar.SetCodeDatabases",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
          </ul>
        </li>
        <li>
          <a href="#"
            ><i class="bi bi-gear-fill bi-style"></i
            >{{
              $t(
                "Sidebar.ProjectGeneralSettings",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}<i class="bi bi-caret-right has-sub-menu"></i
          ></a>
          <ul class="sub-menu">
            <li>
              <router-link :to="{ path: '/Menu/List' }">{{
                $t(
                  "Sidebar.Menus",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <a
                href="#"
                oncontextmenu="return false;"
                @click="dashboardReportingButtonClick"
                >{{
                  $t(
                    "Sidebar.DashboardReporting",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</a
              >
            </li>
            <li>
              <a
                href="#"
                oncontextmenu="return false;"
                @click="xtraReportingButtonClick"
                >{{
                  $t(
                    "Sidebar.XtraReporting",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</a
              >
            </li>
            <li>
              <router-link :to="{ path: '/Configuration/List' }">{{
                $t(
                  "Sidebar.Configurations",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/CompanyParameter/List' }">{{
                $t(
                  "Sidebar.ProjectParameters",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/DataSummary/List' }">{{
                $t(
                  "Sidebar.DataSummary",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/Holiday/List' }">{{
                $t(
                  "Sidebar.Holidays",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/CustomObjectDeletionRule/List' }">{{
                $t(
                  "Sidebar.CustomObjectDeletionRules",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/RecordLog/List' }">{{
                $t(
                  "Sidebar.RecordLogs",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/FormulaErrorLog/List' }">{{
                $t(
                  "Sidebar.FormulaErrorLogs",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/SystemLog/List' }">{{
                $t(
                  "Sidebar.SystemLogs",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/SystemLoginInformation/List' }">{{
                $t(
                  "Sidebar.SystemLoginInformation",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/ImportBulkRecordData/List' }">{{
                $t(
                  "Sidebar.ImportBulkRecordData",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/NotificationSetting/List' }">{{
                $t(
                  "Sidebar.NotificationSettings",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/CustomPage/List' }">{{
                $t(
                  "Sidebar.CustomPage",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/ApiToken/List' }">{{
                $t(
                  "Sidebar.ApiToken",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/Localization/List' }">{{
                $t(
                  "Sidebar.MultiLanguageSettings",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link
                :to="{
                  path: '/CompanyInformation/Edit',
                }"
                >{{
                  $t(
                    "Sidebar.CompanyInformation",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</router-link
              >
            </li>
          </ul>
        </li>
        <li>
          <a href="#"
            ><i class="bi bi-pencil-fill bi-style"></i
            >{{
              $t(
                "Sidebar.ProjectDesign",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}<i class="bi bi-caret-right has-sub-menu"></i
          ></a>
          <ul class="sub-menu">
            <li>
              <router-link :to="{ path: '/ProjectDesign/Field/List' }">{{
                $t(
                  "CustomObjects.Fields",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/ProjectDesign/PageLayout/List' }">{{
                $t(
                  "CustomObjects.PageLayouts",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/ProjectDesign/ViewFilter/List' }">{{
                $t(
                  "CustomObjects.ViewFilters",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link
                :to="{ path: '/ProjectDesign/ObjectRelation/List' }"
                >{{
                  $t(
                    "CustomObjects.ObjectRelations",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ path: '/ProjectDesign/ValidationRules/List' }"
                >{{
                  $t(
                    "CustomObjects.ValidationRules",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</router-link
              >
            </li>
            <li>
              <router-link :to="{ path: '/ProjectDesign/BusinessRule/List' }">{{
                $t(
                  "CustomObjects.BusinessRules",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/ProjectDesign/SetScript/List' }">{{
                $t(
                  "CustomObjects.Scripts",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/ProjectDesign/Button/List' }">{{
                $t(
                  "CustomObjects.Buttons",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/ProjectDesign/FormTemplate/List' }">{{
                $t(
                  "CustomObjects.FormTemplates",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/ProjectDesign/Kanban/List' }">{{
                $t(
                  "CustomObjects.Kanban",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/ProjectDesign/Calendar/List' }">{{
                $t(
                  "CustomObjects.CalendarSettings",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/ProjectDesign/Map/List' }">{{
                $t(
                  "CustomObjects.MapSettings",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/ProjectDesign/TreeView/List' }">{{
                $t(
                  "CustomObjects.TreeViewSettings",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link
                :to="{ path: '/ProjectDesign/DependencyField/List' }"
                >{{
                  $t(
                    "CustomObjects.DependencyFields",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ path: '/ProjectDesign/MessageTemplate/List' }"
                >{{
                  $t(
                    "CustomObjects.MessageTemplates",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</router-link
              >
            </li>
          </ul>
        </li>
        <li>
          <a href="#"
            ><i class="bi bi-people-fill bi-style"></i
            >{{
              $t(
                "Sidebar.OrganizationManagement",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}<i class="bi bi-caret-right has-sub-menu"></i
          ></a>
          <ul class="sub-menu">
            <li>
              <router-link :to="{ path: '/User/List' }">{{
                $t(
                  "Sidebar.Users",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/UserGroup/List' }">{{
                $t(
                  "Sidebar.UserGroups",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/PermissionGroup/List' }">{{
                $t(
                  "Sidebar.PermissionGroups",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/RestrictedPermissionGroup/List' }">{{
                $t(
                  "Sidebar.RestrictedPermissionGroups",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
          </ul>
        </li>
        <li v-show="this.$store.getters._isSuperAdmin">
          <a href="#"
            ><i class="bi bi-person-badge-fill bi-style"></i
            >{{
              $t(
                "Sidebar.SuperAdminSettings",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}<i class="bi bi-caret-right has-sub-menu"></i
          ></a>
          <ul class="sub-menu">
            <li>
              <router-link :to="{ path: '/Project/List' }">{{
                $t(
                  "Sidebar.Projects",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/DatabaseSetting/List' }">{{
                $t(
                  "Sidebar.DatabaseSettings",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/SetXRMConfiguration/List' }">{{
                $t(
                  "Sidebar.Configurations",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/SuperAdmin/UserList' }">{{
                $t(
                  "Sidebar.Users",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/Usage/List' }">{{
                $t(
                  "Sidebar.UsageCounts",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/LocalizationLanguage/List' }">{{
                $t(
                  "Sidebar.LocalizationSettings",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/PageLayoutTemplate/List' }">{{
                $t(
                  "Sidebar.PageLayoutTemplates",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/SystemMessageTemplates/List' }">
                {{
                  $t(
                    "Sidebar.SystemMessageTemplates",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import PerfectScrollbar from "perfect-scrollbar";
var perfectScroolBar = PerfectScrollbar;

export default {
  name: "SideBar",
  components: {},
  data() {
    return {
      isSxAsideHidden: false,
      dashboardReportingButtonClicked: false,
      xtraReportingButtonClicked: false,
    };
  },
  methods: {
    dashboardReportingButtonClick($event) {
      $event.preventDefault();
      $event.stopPropagation();
      if (this.dashboardReportingButtonClicked) return;

      this.dashboardReportingButtonClicked = true;
      this.$root
        .createKeyForCurrentUser({
          url: "/DashboardReporting/List",
        })
        .then((response) => {
          this.dashboardReportingButtonClicked = false;
        })
        .catch((err) => {
          //TODO: Error
        });
    },
    xtraReportingButtonClick($event) {
      $event.preventDefault();
      $event.stopPropagation();
      if (this.xtraReportingButtonClicked) return;

      this.xtraReportingButtonClicked = true;
      this.$root
        .createKeyForCurrentUser({
          url: "/XtraReporting/List",
        })
        .then((response) => {
          this.xtraReportingButtonClicked = false;
        })
        .catch((err) => {
          //TODO: Error
        });
    },
    appMenu: function () {
      const submenu_animation_speed = 200;
      var select_sub_menus, active_page_sub_menu_link, ps, container;

      if (!$(".app-sidebar").length) {
        return;
      }

      select_sub_menus = $(".accordion-menu li:not(.open) ul");

      active_page_sub_menu_link = $(".accordion-menu li.active-page > a");

      select_sub_menus.hide();

      if ($(".app.menu-hover").length && $(window).width() > 1199) {
        ps.destroy();
        ps = null;
      } else {
        container = document.querySelector(".app-menu");
        ps = new perfectScroolBar(container);
      }

      $(window).resize(function () {
        if (
          $(".app.menu-hover").length &&
          $(window).width() > 1199 &&
          !ps.length
        ) {
          container = document.querySelector(".app-menu");
          ps = new perfectScroolBar(container);
        } else if (ps.length) {
          ps.destroy();
          ps = null;
        }
      });

      $(".accordion-menu li a").on("click", function (e) {
        var sub_menu = $(this).next("ul"),
          parent_list_el = $(this).parent("li"),
          active_list_element = $(".accordion-menu > li.open"),
          show_sub_menu = function () {
            sub_menu.slideDown(submenu_animation_speed);
            parent_list_el.addClass("open");
            ps.update();
          },
          hide_sub_menu = function () {
            sub_menu.slideUp(submenu_animation_speed);
            parent_list_el.removeClass("open");
            ps.update();
          },
          hide_active_menu = function () {
            parent_list_el
              .parent()
              .children(".open")
              .children("ul")
              .slideUp(submenu_animation_speed);
            parent_list_el.parent().children(".open").removeClass("open");
            ps.update();
          };

        if (sub_menu.length) {
          if ($(".app").hasClass("menu-hover") && $(window).width() > 1199) {
            e.preventDefault();
            return;
          }

          if (!parent_list_el.hasClass("open")) {
            if (active_list_element.length) {
              hide_active_menu();
            }
            show_sub_menu();
          } else {
            hide_sub_menu();
          }

          return false;
        }
      });

      if (!$(".app").hasClass("menu-off-canvas")) {
        if ($(window).width() < 1199 && !$(".app").hasClass("sidebar-hidden")) {
          if (!$(".hide-app-sidebar-mobile").length) {
            $(".app").append('<div class="hide-app-sidebar-mobile"></div>');
          }
          $(".sidebar-toggle-button i").attr(
            "class",
            "bi bi-arrow-bar-right bi-style"
          );
        } else {
          $(".sidebar-toggle-button i").attr(
            "class",
            "bi bi-arrow-bar-left bi-style"
          );
        }

        $(window).resize(function () {
          if (
            $(window).width() < 1199 &&
            !$(".app").hasClass("sidebar-hidden")
          ) {
            if (!$(".hide-app-sidebar-mobile").length) {
              $(".app").append('<div class="hide-app-sidebar-mobile"></div>');
            }
            $(".sidebar-toggle-button i").attr(
              "class",
              "bi bi-arrow-bar-right bi-style"
            );
          } else {
            $(".sidebar-toggle-button i").attr(
              "class",
              "bi bi-arrow-bar-left bi-style"
            );
          }
        });
      }

      if ($(".active-page > ul").length) {
        if (!$(".app").hasClass("menu-hover")) {
          active_page_sub_menu_link.click();
        } else if ($(window).width() < 1199) {
          active_page_sub_menu_link.click();
        }
      }
    },
  },
  mounted() {
    this.appMenu();
  },
  created() {
    // is aside minimize
    var sxAsideMinimize = Object.readCookie("SxAsideMinimize");
    if (
      !String.isNullOrWhiteSpace(sxAsideMinimize) &&
      sxAsideMinimize == "on"
    ) {
      this.isSxAsideHidden = true;
    }
  },
};
</script>
