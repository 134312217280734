<template>
  <template v-if="type === 'SelectWithLocalData'">
    <div
      :class="{
        'ms-invalid': isInvalid,
        'ms-valid': !isInvalid,
        'ms-multiple': isMultiple,
        'ms-disabled': isDisabled,
      }"
    >
      <multiselect
        ref="select"
        v-model="selectedValue"
        label="value"
        :openDirection="openDirection"
        :track-by="selectedPrimaryKey"
        :id="id"
        @select="onSelect"
        @close="onTouch"
        @remove="onRemove"
        :options="filteredLocalData"
        :multiple="isMultiple"
        :disabled="isDisabled"
        :searchable="isSearchable"
        :show-labels="!isMultiple"
        :select-label="localization.clickToChoose"
        :deselect-label="localization.clickToDeSelect"
        :selectedLabel="localization.selected"
        :placeholder="localization.placeholder"
      >
        <template v-slot:noOptions>
          <span>{{ localization.noRecords }}</span>
        </template>
        <template v-slot:noResult>
          <span>{{ localization.noResult }}</span>
        </template>
        <template v-slot:maxElements>
          <span>{{ localization.maxElements }}</span>
        </template>
        <template v-slot:clear>
          <div
            v-if="
              ((Array.isArray(selectedValue) && selectedValue.length > 0) ||
                (!String.isNullOrWhiteSpace(selectedValue) &&
                  selectedValue.key)) &&
              !this.isDisabled
            "
            @click="clearValue"
            class="multiselect__clear"
          ></div>
        </template>
      </multiselect>
    </div>
  </template>
  <template v-else-if="type === 'SelectWithRemoteData'">
    <div
      :class="{
        'ms-invalid': isInvalid,
        'ms-valid': !isInvalid,
        'ms-multiple': isMultiple,
        'ms-disabled': isDisabled,
      }"
    >
      <multiselect
        ref="select"
        v-model="selectedValue"
        :track-by="selectedPrimaryKey"
        label="value"
        :openDirection="openDirection"
        :id="id"
        @select="onSelect"
        @open="getData(false)"
        @close="onTouch"
        @remove="onRemove"
        :options="remoteData"
        :multiple="isMultiple"
        :disabled="isDisabled"
        :searchable="isSearchable"
        :loading="isLoading"
        :show-labels="!isMultiple"
        :select-label="localization.clickToChoose"
        :deselect-label="localization.clickToDeSelect"
        :selectedLabel="localization.selected"
        :placeholder="localization.placeholder"
      >
        <template v-slot:afterList>
          <div
            v-observe-visibility="visibilityChanged"
            v-if="hasNextPage()"
          ></div>
        </template>
        <template v-slot:noOptions>
          <span>{{ localization.noRecords }}</span>
        </template>
        <template v-slot:noResult>
          <span>{{ localization.noResult }}</span>
        </template>
        <template v-slot:maxElements>
          <span>{{ localization.maxElements }}</span>
        </template>
        <template v-slot:clear>
          <div
            v-if="
              ((Array.isArray(selectedValue) && selectedValue.length > 0) ||
                (!String.isNullOrWhiteSpace(selectedValue) &&
                  selectedValue.key)) &&
              !this.isDisabled
            "
            @click="clearValue"
            class="multiselect__clear"
          ></div>
        </template>
      </multiselect>
    </div>
  </template>
  <template v-else-if="type === 'SelectWithRemoteDataAndInternalSearch'">
    <div
      :class="{
        'ms-invalid': isInvalid,
        'ms-valid': !isInvalid,
        'ms-multiple': isMultiple,
        'ms-disabled': isDisabled,
      }"
    >
      <multiselect
        ref="select"
        v-model="selectedValue"
        :track-by="selectedPrimaryKey"
        label="value"
        :openDirection="openDirection"
        :id="id"
        @select="onSelect"
        @open="getData(false)"
        @search-change="search"
        @close="onTouch"
        @remove="onRemove"
        :multiple="isMultiple"
        :disabled="isDisabled"
        :searchable="isSearchable"
        :loading="isLoading"
        :options="remoteData"
        :show-labels="!isMultiple"
        :internal-search="false"
        :select-label="localization.clickToChoose"
        :deselect-label="localization.clickToDeSelect"
        :selectedLabel="localization.selected"
        :placeholder="localization.placeholder"
      >
        <template v-slot:afterList>
          <div
            v-observe-visibility="visibilityChanged"
            v-if="hasNextPage()"
          ></div>
        </template>
        <template v-slot:noOptions>
          <span>{{ localization.noRecords }}</span>
        </template>
        <template v-slot:noResult>
          <span>{{ localization.noResult }}</span>
        </template>
        <template v-slot:maxElements>
          <span>{{
            $t(
              "BaseModelFields.MaxElements",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</span>
        </template>
        <template v-slot:clear>
          <div
            v-if="
              ((Array.isArray(selectedValue) && selectedValue.length > 0) ||
                (!String.isNullOrWhiteSpace(selectedValue) &&
                  selectedValue.key)) &&
              !this.isDisabled
            "
            @click="clearValue"
            class="multiselect__clear"
          ></div>
        </template>
      </multiselect>
    </div>
  </template>
  <template v-else-if="type === 'SelectBreadcrumb'">
    <multiselect
      ref="select"
      v-model="selectedValue"
      :track-by="selectedPrimaryKey"
      label="value"
      :openDirection="openDirection"
      :id="id"
      @select="onSelect"
      @open="getData(false)"
      @search-change="search"
      @close="onTouch"
      :multiple="isMultiple"
      :disabled="isDisabled"
      :searchable="isSearchable"
      :loading="isLoading"
      :options="remoteData"
      :showLabels="false"
      :allow-empty="false"
      :internal-search="false"
      :select-label="localization.clickToChoose"
      :deselect-label="localization.clickToChoose"
      :selectedLabel="localization.selected"
      :placeholder="localization.placeholder"
    >
      <template v-slot:afterList>
        <div
          v-observe-visibility="visibilityChanged"
          v-if="hasNextPage()"
        ></div>
      </template>
      <template v-slot:noOptions>
        <span>{{ localization.noRecords }}</span>
      </template>
      <template v-slot:noResult>
        <span>{{ localization.noResult }}</span>
      </template>
      <template v-slot:maxElements>
        <span>{{ localization.maxElements }}</span>
      </template>
    </multiselect>
  </template>
</template>
<script>
import $ from "jquery";
import Multiselect from "@suadelabs/vue3-multiselect";
import "@suadelabs/vue3-multiselect/dist/vue3-multiselect.css";
import { ObserveVisibility } from "vue3-observe-visibility";
export default {
  name: "FormSelect",
  data() {
    return {
      selectedValue: this.selectedData,
      isLoading: false,
      remoteData: [],
      filteredLocalData: [],
      itemCount: 0,
      pageSize: 12,
      currentPage: 1,
      totalPages: "",
      searchQuery: "",
      nullObject: { key: null, value: null },
      localization: {
        clickToChoose: this.$t(
          "BaseModelFields.ClickToChoose",
          {},
          { locale: this.$store.state.activeLang }
        ),
        clickToDeSelect: this.$t(
          "BaseModelFields.ClickToDeSelect",
          {},
          { locale: this.$store.state.activeLang }
        ),
        maxElements: this.$t(
          "BaseModelFields.MaxElements",
          {},
          { locale: this.$store.state.activeLang }
        ),
        selected: this.$t(
          "BaseModelFields.Selected",
          {},
          { locale: this.$store.state.activeLang }
        ),
        placeholder: this.$t(
          "BaseModelFields.ChoosePlaceHolder",
          {},
          { locale: this.$store.state.activeLang }
        ),
        noRecords: this.$t(
          "BaseModelFields.NoRecords",
          {},
          { locale: this.$store.state.activeLang }
        ),
        noResult: this.$t(
          "BaseModelFields.NoResult",
          {},
          { locale: this.$store.state.activeLang }
        ),
      },
    };
  },
  emits: [
    "onChange",
    "onMultipleSelectChange",
    "onMultipleSelectChangeReturnValues",
  ],
  directives: { "observe-visibility": ObserveVisibility },
  components: { multiselect: Multiselect },
  computed: {
    isInvalid() {
      return !this.allowEmpty && String.isNullOrWhiteSpace(this.selectedValue);
    },
  },
  watch: {
    resetSelect: function () {
      this.clearValue();
    },
    selectedData: {
      deep: true,
      handler() {
        if (
          !String.isNullOrWhiteSpace(this.selectedData) &&
          this.isDetailIconShow
        ) {
          this.iconOperation(this.selectedData);
        }
      },
    },
    data() {
      this.filteredLocalData = this.data;
    },
    routeLink() {
      this.iconOperation(this.selectedValue);
    },
    selectedValue: {
      immediate: true,
      handler() {
        if (this.type === "SelectWithLocalData") {
          if (
            !String.isNullOrWhiteSpace(this.selectedValue) &&
            this.isMultiple
          ) {
            var onlySelectedKeys = Array.isArray(this.selectedValue)
              ? this.selectedValue.map(function (m) {
                  return m.key;
                })
              : [this.selectedValue.key];
            this.filteredLocalData = this.data.filter(function (f) {
              return !onlySelectedKeys.includes(f.key);
            });
          } else {
            this.filteredLocalData = this.data;
          }
        }
      },
    },
  },
  props: {
    type: { type: String, default: "" },
    selectedData: { type: Object },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    requestUrl: { type: String },
    isBRSRequest: { type: Boolean, default: false },
    isGatewayRequest: { type: Boolean, default: false },
    isMultiple: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    allowEmpty: { type: Boolean, default: true },
    isSearchable: { type: Boolean, default: true },
    isParameters: { type: Boolean, default: false },
    resetSelect: { type: Boolean, default: false },
    isDetailIconShow: { type: Boolean, default: false },
    routeLink: { type: String, default: "" },
    selectedPrimaryKey: { type: String, default: "key" },
    excludeId: { type: String, default: "" },
    id: { type: String },
    openDirection: { type: String, default: "bottom" },
  },
  methods: {
    clearValue() {
      this.selectedValue = "";
      this.iconRemoveOperation();
      if (this.isMultiple) {
        this.$emit("onMultipleSelectChange", null, this.$refs.select.id);
        this.$emit(
          "onMultipleSelectChangeReturnValues",
          null,
          this.$refs.select.id
        );
      } else {
        this.$emit("onChange", this.nullObject, this.$refs.select.id);
      }
    },
    hasNextPage() {
      return this.currentPage + 1 <= this.totalPages;
    },
    visibilityChanged(isVisible) {
      if (isVisible) {
        if (this.remoteData.length != 0) {
          this.currentPage = this.currentPage + 1;
          this.getData(true);
        }
      }
    },
    iconOperation(selectedValue) {
      if (!this.isMultiple && this.isDetailIconShow) {
        var select = $(this.$refs.select.$el),
          label = select
            .closest(".ms-invalid, .ms-valid")
            .parent("div")
            .find("label");

        label.find(".record-detail").remove();

        if (
          !String.isNullOrWhiteSpace(selectedValue) &&
          this.isDetailIconShow
        ) {
          label.append(
            String.format(
              '<a target="_blank" href="{0}{1}" class="record-detail ms-1 me-1"><i class="bi bi-box-arrow-up-right fs-16"></i></a></label>',
              this.routeLink,
              Array.isArray(selectedValue)
                ? selectedValue[0][this.selectedPrimaryKey]
                : selectedValue[this.selectedPrimaryKey]
            )
          );
        }
      }
    },
    iconRemoveOperation() {
      if (this.isDetailIconShow) {
        var select = $(this.$refs.select.$el),
          label = select
            .closest(".ms-invalid, .ms-valid")
            .parent("div")
            .find("label");

        label.find(".record-detail").remove();
      }
    },
    onSelect(selectedValue) {
      this.iconOperation(selectedValue);

      this.$emit("onChange", selectedValue, this.$refs.select.id);
    },
    reMultipleValue() {
      if (String.isNullOrWhiteSpace(this.selectedValue)) {
        this.$emit("onMultipleSelectChange", null, this.$refs.select.id);
        this.$emit(
          "onMultipleSelectChangeReturnValues",
          null,
          this.$refs.select.id
        );
        return;
      }
      var mapSelectedIds = this.selectedValue
        .map(function (m) {
          return m.key;
        })
        .join(this.$systemSeparator);
      var mapSelectedValues = this.selectedValue
        .map(function (m) {
          return m.value;
        })
        .join(this.$systemSeparator);
      this.$emit(
        "onMultipleSelectChange",
        mapSelectedIds,
        this.$refs.select.id
      );
      this.$emit(
        "onMultipleSelectChangeReturnValues",
        mapSelectedValues,
        this.$refs.select.id
      );
    },
    onRemove(currentItem) {
      if (this.isMultiple) {
        this.selectedValue = this.selectedValue.filter(function (f) {
          return f.key !== currentItem.key;
        });
        this.reMultipleValue();
      } else {
        this.iconRemoveOperation();

        this.$emit("onChange", this.nullObject, this.$refs.select.id);
      }
    },
    onTouch() {
      if (this.isMultiple) {
        this.reMultipleValue();
      }
    },
    search(query) {
      this.searchQuery = String.isNullOrWhiteSpace(query) ? "" : query;
      this.getData(false);
    },
    getData($nextRecord) {
      if (!$nextRecord) {
        this.currentPage = 1;
        this.remoteData = [];
      }

      var query = "";
      if (
        this.type === "SelectWithRemoteDataAndInternalSearch" ||
        this.type == "SelectBreadcrumb"
      ) {
        query = this.searchQuery;
      }

      this.isLoading = true;
      var remoteUrl = this.isParameters
          ? String.format(
              "{0}&q={1}&pageSize={2}&pageNumber={3}",
              this.requestUrl,
              query,
              this.pageSize,
              this.currentPage
            )
          : String.format(
              "{0}?q={1}&pageSize={2}&pageNumber={3}",
              this.requestUrl,
              query,
              this.pageSize,
              this.currentPage
            ),
        self = this,
        instance = this.$appAxios;

      if (this.isBRSRequest) {
        instance = this.$brsAxios;
      } else if (this.isGatewayRequest) {
        instance = this.$prodGatewayAxios;
      }

      instance
        .get(remoteUrl)
        .then((response) => {
          this.isLoading = false;

          var data = response.data,
            items = data.items;
          if (data.items === undefined && data.keyValues !== undefined) {
            items = data.keyValues;
          } else if (data.items === undefined && data.keyValues === undefined) {
            items = data;
          }

          if (
            !String.isNullOrWhiteSpace(this.selectedValue) &&
            (this.isMultiple || this.type == "SelectBreadcrumb")
          ) {
            var excludeIds = Array.isArray(this.selectedValue)
              ? this.selectedValue.map(function (m) {
                  return m[self.selectedPrimaryKey];
                })
              : [this.selectedValue[self.selectedPrimaryKey]];
            if (!String.isNullOrWhiteSpace(excludeIds)) {
              items = items.filter(function (f) {
                return !excludeIds.includes(f[self.selectedPrimaryKey]);
              });
            }
          }

          if (!String.isNullOrWhiteSpace(self.excludeId)) {
            items = items.filter(function (f) {
              return !f[self.selectedPrimaryKey].includes(self.excludeId);
            });
          }

          this.totalPages = data.pageCount;
          this.itemCount = data.itemCount;

          var dataItemCount =
            data.itemCount > 0 ? data.itemCount : items.length;
          if (this.remoteData.length === dataItemCount) {
            return;
          }

          if (this.currentPage == 1) {
            this.remoteData = items;
            return;
          }

          if (this.remoteData.length > 0) {
            items.forEach((value) => {
              this.remoteData.push(value);
            });
          } else {
            this.remoteData = items;
          }
        })
        .catch((error) => {
          // console.log(error);
          this.isLoading = false;
        });
    },
  },
};
</script>
