<template>
  <div class="row">
    <div
      class="d-flex justify-content-center mb-0 p-1 alert radius-0"
      :class="{
        'alert-warning':
          (!isRowEdit &&
            (String.isNullOrWhiteSpace(field) ||
              String.isNullOrWhiteSpace(operator))) ||
          (isRowEdit &&
            (String.isNullOrWhiteSpace(editField) ||
              String.isNullOrWhiteSpace(editOperator))),
        'alert-dark': !(
          (!isRowEdit &&
            (String.isNullOrWhiteSpace(field) ||
              String.isNullOrWhiteSpace(operator))) ||
          (isRowEdit &&
            (String.isNullOrWhiteSpace(editField) ||
              String.isNullOrWhiteSpace(editOperator)))
        ),
      }"
    >
      <template
        v-if="
          (!isRowEdit &&
            (String.isNullOrWhiteSpace(field) ||
              String.isNullOrWhiteSpace(operator))) ||
          (isRowEdit &&
            (String.isNullOrWhiteSpace(editField) ||
              String.isNullOrWhiteSpace(editOperator)))
        "
      >
        {{
          $t(
            "ViewFilters.FieldOrOperatorNotValid",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </template>
      <template v-else-if="isRowEdit">
        <button type="button" class="btn btn-success me-1" @click="editSave">
          <i class="bi bi-save pe-2"></i>
          {{ $t("Buttons.Save", {}, { locale: this.$store.state.activeLang }) }}
        </button>
        <button type="button" class="btn btn-danger me-0" @click="editCancel">
          <i class="bi bi-x"></i>
          {{
            $t("Buttons.Cancel", {}, { locale: this.$store.state.activeLang })
          }}
        </button>
      </template>
      <template v-else>
        <button type="button" class="btn btn-primary w-25" @click="add">
          <i class="bi bi-plus"></i>
          {{ $t("Buttons.Add", {}, { locale: this.$store.state.activeLang }) }}
        </button>
      </template>
    </div>
  </div>
  <div class="row">
    <div class="table-responsive-sm p-0">
      <table class="table table-warning table-layout-auto">
        <thead>
          <tr>
            <th width="32" class="text-left">
              {{
                $t(
                  "Components.QueryBuilder.CriteriaNo",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </th>
            <th class="mw-200">
              <label class="required">
                {{
                  $t(
                    "Components.QueryBuilder.CriteriaField",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label
              >
            </th>
            <th class="mw-200">
              <label class="required">
                {{
                  $t(
                    "Components.QueryBuilder.CriteriaOperator",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label
              >
            </th>
            <th width="32">
              <label>
                {{
                  $t(
                    "Components.QueryBuilder.AsFormula",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label
              >
            </th>
            <th class="mw-200">
              <label class="">
                {{
                  $t(
                    "Components.QueryBuilder.CriteriaValue",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="isRowEdit">
            <tr class="edit-row">
              <td class="align-middle">
                <span class="badge bg-primary rounded-pill fs-6">{{
                  this.editRow.number
                }}</span>
              </td>
              <td>
                <FormSelect
                  type="SelectWithLocalData"
                  @onChange="onChangeEditField"
                  :resetSelect="editFieldSelectReset"
                  :selectedData="[
                    {
                      key: this.editRow.fieldPublicId,
                      value: this.editRow.fieldName,
                    },
                  ]"
                  :data="fields"
                />
              </td>
              <td>
                <FormSelect
                  type="SelectWithLocalData"
                  @onChange="onChangeEditFieldOperator"
                  :data="editOperators"
                  :selectedData="[
                    {
                      key: this.editRow.operatorPublicId,
                      value: this.editRow.operatorName,
                    },
                  ]"
                  :resetSelect="editOperatorValueReset"
                  :isDisabled="String.isNullOrWhiteSpace(editField)"
                />
              </td>
              <td>
                <div class="form-check form-switch form-switch-lg">
                  <input
                    ref="checkbox"
                    class="form-check-input"
                    type="checkbox"
                    @change="asFormulaCheckOnChange"
                    v-model="this.editRow.asFormula"
                    :disabled="
                      String.isNullOrWhiteSpace(editField) ||
                      String.isNullOrWhiteSpace(editOperator)
                    "
                  />
                </div>
              </td>
              <td>
                <template v-if="String.isNullOrWhiteSpace(this.editFieldType)">
                  <input type="text" class="form-control" disabled />
                </template>
                <template v-else>
                  <div>
                    <div v-if="this.editFieldType == 'select'">
                      <template v-if="editFieldItemIsMultiple">
                        <FormSelect
                          type="SelectWithRemoteDataAndInternalSearch"
                          @onMultipleSelectChange="onChangeEditMultipleValue"
                          @onMultipleSelectChangeReturnValues="
                            onChangeEditMultipleNames
                          "
                          :selectedData="
                            !String.isNullOrWhiteSpace(this.editRow.value)
                              ? multipleSelectValueMapping(this.editRow)
                              : []
                          "
                          :isParameters="true"
                          id="criteria-edit-value-Select"
                          :isMultiple="true"
                          :isGatewayRequest="true"
                          :requestUrl="editFieldItemsRequestUrl"
                        />
                      </template>
                      <template v-else>
                        <FormSelect
                          type="SelectWithRemoteDataAndInternalSearch"
                          @onChange="onChangeEditValue"
                          id="criteria-edit-value-Select"
                          :selectedData="
                            !String.isNullOrWhiteSpace(this.editRow.value)
                              ? [
                                  {
                                    key: this.editRow.value,
                                    value: this.editRow.text,
                                  },
                                ]
                              : []
                          "
                          :isParameters="true"
                          :isGatewayRequest="true"
                          :requestUrl="editFieldItemsRequestUrl"
                        />
                      </template>
                    </div>
                    <div v-if="this.editFieldType === 'DateTime'">
                      <DatePicker
                        type="datetime"
                        :value="
                          !String.isNullOrWhiteSpace(editRow.text)
                            ? this.$moment(
                                editRow.text,
                                $store.getters._dateTimeFormat
                                  .replace('d', 'D')
                                  .replace('d', 'D')
                              ).format('YYYY-MM-DDTHH:mm:ss')
                            : null
                        "
                        id="criteria-edit-value-DateTime"
                      />
                    </div>
                    <div v-if="this.editFieldType === 'Date'">
                      <DatePicker
                        type="date"
                        :value="
                          !String.isNullOrWhiteSpace(editRow.text)
                            ? this.$moment(
                                editRow.text,
                                $store.getters._dateFormat.toUpperCase()
                              ).format('YYYY-MM-DDTHH:mm:ss')
                            : null
                        "
                        id="criteria-edit-value-Date"
                      />
                    </div>
                    <input
                      v-if="this.editFieldType == 'Text'"
                      class="form-control"
                      type="text"
                      v-model="this.editRow.text"
                      id="criteria-edit-value-Text"
                    />
                  </div>
                </template>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td class="align-middle">
                <span class="badge bg-primary rounded-pill fs-6">{{
                  this.getCriteriaNextRowNumber()
                }}</span>
              </td>
              <td>
                <FormSelect
                  type="SelectWithLocalData"
                  @onChange="onChangeField"
                  :resetSelect="fieldSelectReset"
                  :data="fields"
                />
              </td>
              <td>
                <FormSelect
                  type="SelectWithLocalData"
                  @onChange="onChangeFieldOperator"
                  :data="this.field ? this.operators : []"
                  :resetSelect="operatorValueReset"
                  :isDisabled="String.isNullOrWhiteSpace(field)"
                />
              </td>
              <td width="32">
                <div class="form-check form-switch form-switch-lg">
                  <input
                    ref="checkbox"
                    class="form-check-input"
                    type="checkbox"
                    @change="asFormulaCheckOnChange"
                    v-model="asFormula"
                    :disabled="
                      String.isNullOrWhiteSpace(field) ||
                      String.isNullOrWhiteSpace(operator)
                    "
                  />
                </div>
              </td>
              <td>
                <template v-if="String.isNullOrWhiteSpace(this.fieldType)">
                  <input type="text" class="form-control" disabled />
                </template>
                <template v-else>
                  <div>
                    <div v-if="this.fieldType == 'select'">
                      <template v-if="fieldItemIsMultiple">
                        <FormSelect
                          type="SelectWithRemoteDataAndInternalSearch"
                          @onMultipleSelectChange="onChangeMultipleValue"
                          @onMultipleSelectChangeReturnValues="
                            onChangeMultipleNames
                          "
                          :isParameters="true"
                          id="criteria-value-Select"
                          :isMultiple="true"
                          :requestUrl="fieldItemsRequestUrl"
                          :isGatewayRequest="true"
                        />
                      </template>
                      <template v-else>
                        <FormSelect
                          type="SelectWithRemoteDataAndInternalSearch"
                          @onChange="onChangeValue"
                          id="criteria-value-Select"
                          :isParameters="true"
                          :requestUrl="fieldItemsRequestUrl"
                          :isGatewayRequest="true"
                        />
                      </template>
                    </div>
                    <div v-if="this.fieldType === 'DateTime'">
                      <DatePicker
                        type="datetime"
                        :displayFormat="$store.getters._dateTimeFormat"
                        id="criteria-value-DateTime"
                      />
                    </div>
                    <div v-if="this.fieldType === 'Date'">
                      <DatePicker
                        type="date"
                        :displayFormat="$store.getters._dateFormat"
                        id="criteria-value-Date"
                      />
                    </div>
                    <input
                      v-if="this.fieldType == 'Text'"
                      class="form-control"
                      type="text"
                      id="criteria-value-Text"
                    />
                  </div>
                </template>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row">
    <p class="mt-2 mb-2 ms-0 ps-0" v-if="!criterias.length">
      <label class="required">
        {{
          $t(
            "Components.QueryBuilder.NoCriteria",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label
      >
    </p>
    <div class="table-responsive" v-else>
      <table
        class="table table-striped table-bordered table-primary table-hover"
      >
        <thead>
          <tr>
            <th class="text-left">
              {{
                $t(
                  "Components.QueryBuilder.CriteriaNo",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </th>
            <th class="col-md-3">
              <label class="">
                {{
                  $t(
                    "Components.QueryBuilder.CriteriaField",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label
              >
            </th>
            <th class="col-md-3">
              <label class="">
                {{
                  $t(
                    "Components.QueryBuilder.CriteriaOperator",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label
              >
            </th>
            <th>
              <label>
                {{
                  $t(
                    "Components.QueryBuilder.AsFormula",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label
              >
            </th>
            <th class="col-md-3">
              <label class="">
                {{
                  $t(
                    "Components.QueryBuilder.CriteriaValue",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label
              >
            </th>
            <th class="col-md-3 text-center">
              <label class="">
                {{
                  $t(
                    "Buttons.Actions",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="criteria-item"
            :data-number="item.number"
            v-for="item in criterias"
            :key="item.number"
          >
            <td class="align-middle">
              <span class="badge bg-primary rounded-pill fs-6">{{
                item.number
              }}</span>
            </td>
            <td class="align-middle">
              {{ item.fieldName }}
            </td>
            <td class="align-middle">
              {{ item.operatorName }}
            </td>
            <td class="align-center text-center">
              <i
                class="fa fa-check text-success fs-4"
                v-if="item.asFormula"
              ></i>
              <i class="fa fa-times text-danger fs-4" v-else></i>
            </td>
            <td class="align-middle">
              {{ item.text }}
            </td>
            <td class="align-middle" align="center">
              <button
                type="button"
                class="btn btn-sm btn-warning"
                @click="edit(item.number)"
                :disabled="isRowEdit"
              >
                {{
                  $t(
                    "Buttons.Edit",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </button>
              <button
                type="button"
                class="btn btn-sm btn-danger"
                @click="remove(item.number)"
                :disabled="isRowEdit"
              >
                {{
                  $t(
                    "Buttons.Delete",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </button>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <input
                type="text"
                v-model="expression"
                class="form-control fs-5"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { createToast } from "mosha-vue-toastify";
import $ from "jquery";
export default {
  name: "CriteriasEditor",
  props: {
    criteria: {
      type: String,
      default: "",
    },
    criteriaExpression: {
      type: String,
      default: "",
    },
    isCriteria: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      criterias: this.criteria ? this.criteria : [],
      expression: this.criteriaExpression ? this.criteriaExpression : null,
      //all fields
      fields: [],

      //new block properties
      field: null,
      fieldType: null,
      fieldItemsRequestUrl: null,
      fieldItemIsMultiple: false,
      operator: null,
      operators: [],
      fieldSelectReset: false,
      operatorValueReset: false,
      asFormula: false,
      oldInformations: {},

      //new block select values
      value: null,
      valueId: null,

      //edit block properties
      editRow: null,
      isRowEdit: false,
      editField: null,
      editFieldType: null,
      editFieldItemsRequestUrl: null,
      editFieldItemIsMultiple: false,
      editOperator: null,
      editOperators: [],
      editFieldSelectReset: false,
      editOperatorValueReset: false,
      editOldInformations: {},

      //edit block select values
      editValue: null,
      editValueId: null,
    };
  },
  mounted() {
    this.getFields();
  },
  methods: {
    getFields() {
      this.fields = [];
      var self = this;
      this.$root
        .asyncRequest(
          `/Lcdp-FieldListQueryBuilder?customObjectPublicId=${this.$route.params.customObjectId}&listTypeId=2&isCriteria=${this.isCriteria}`
        )
        .then((response) => {
          response.data.items.map((x) => {
            self.fields.push({
              key: x.publicId,
              value: x.name,
              model: x,
              operators: self.$root.localizationForQueryBuilderFieldOperators(
                x.operators.items
              ),
            });
          });
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    multipleSelectValueMapping(row) {
      var selectedItems = [];
      row.value.split("|").forEach((element, i) => {
        selectedItems.push({ key: element, value: row.text.split("|")[i] });
      });

      return selectedItems;
    },
    onChangeValue(selected) {
      this.value = selected.value;
      this.valueId = selected.key;
    },
    onChangeMultipleValue(ids) {
      this.valueId = ids;
    },
    onChangeMultipleNames(names) {
      this.value = names;
    },
    onChangeEditValue(selected) {
      this.editValue = selected.value;
      this.editValueId = selected.key;
    },
    onChangeEditMultipleValue(ids) {
      this.editValueId = ids;
    },
    onChangeEditMultipleNames(names) {
      this.editValue = names;
    },
    asFormulaCheckOnChange() {
      if (this.isRowEdit) {
        this.editValue = null;
        this.editValueId = null;
        this.editRow.value = null;
        this.editRow.text = null;
        if (this.editRow.asFormula) {
          this.editOldInformations = {
            editFieldType: this.editFieldType,
            editFieldItemsRequestUrl: this.editFieldItemsRequestUrl,
            editFieldItemIsMultiple: this.editFieldItemIsMultiple,
          };
          this.editFieldType = "Text";
        } else {
          this.editFieldType = this.editOldInformations.editFieldType;
          this.editFieldItemsRequestUrl =
            this.editOldInformations.editFieldItemsRequestUrl;
          this.editFieldItemIsMultiple =
            this.editOldInformations.editFieldItemIsMultiple;
        }
      } else {
        this.value = null;
        this.valueId = null;
        if (this.asFormula) {
          this.oldInformations = {
            fieldType: this.fieldType,
            fieldItemsRequestUrl: this.fieldItemsRequestUrl,
            fieldItemIsMultiple: this.fieldItemIsMultiple,
          };
          this.fieldType = "Text";
        } else {
          this.fieldType = this.oldInformations.fieldType;
          this.fieldItemsRequestUrl = this.oldInformations.fieldItemsRequestUrl;
          this.fieldItemIsMultiple = this.oldInformations.fieldItemIsMultiple;
        }
      }
    },
    edit(number) {
      var criteriaItem = this.criterias.find((f) => f.number === number);
      this.editRow = {
        number: criteriaItem.number,
        fieldPublicId: criteriaItem.fieldPublicId,
        fieldName: criteriaItem.fieldName,
        operatorPublicId: criteriaItem.operatorPublicId,
        operatorName: criteriaItem.operatorName,
        asFormula: criteriaItem.asFormula,
        value: criteriaItem.value,
        text: criteriaItem.text,
      };

      if (this.editRow !== null && this.editRow !== undefined) {
        this.isRowEdit = true;
      } else {
        this.isRowEdit = false;
        return;
      }

      this.editProcess(this.editRow);
    },
    editProcess(editRow) {
      $(".edit-row td").css("background-color", "#ffc107");
      setTimeout(() => {
        $(".edit-row td").css("background-color", "");
      }, 1000);

      this.editField = this.fields.find((f) => f.key === editRow.fieldPublicId);
      if (!String.isNullOrWhiteSpace(this.editField)) {
        this.editOperators = this.editField.operators.map((x) => {
          return { key: x.value, value: x.key };
        });
      }

      this.editFieldType = null;

      this.editOperator = this.editField.operators.find(
        (f) => f.value == editRow.operatorPublicId
      );

      this.getEditFieldOperatorDetail();
    },
    editSave() {
      if (
        String.isNullOrWhiteSpace(this.editField) ||
        String.isNullOrWhiteSpace(this.editOperator)
      ) {
        createToast(
          this.$t(
            "ViewFilters.FieldOrOperatorNotValid",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return;
      }

      if (this.criterias.length >= 11) {
        createToast(
          this.$t(
            "ViewFilters.MaxCriteriaItem",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return;
      }

      var value = "",
        text = "",
        valueSelector = $("#criteria-edit-value-" + this.editFieldType);
      if (
        this.editFieldType == "Text" ||
        this.editFieldType == "Date" ||
        this.editFieldType == "DateTime"
      ) {
        value = valueSelector.val();
        text = value;
      } else {
        value = this.editValueId;
        text = this.editValue;
      }

      var params = {
        number: this.editRow.number,
        fieldPublicId: this.editField.key,
        fieldName: this.editField.value,
        operatorPublicId: this.editOperator.value,
        operatorName: this.editOperator.key,
        asFormula: this.editRow.asFormula,
        value: value,
        text: text,
      };

      var valid = true;
      $.each(this.criterias, function (i, v) {
        if (
          params.fieldPublicId === v.fieldPublicId &&
          params.operatorPublicId === v.operatorPublicId &&
          params.value === v.value &&
          params.number !== v.number
        ) {
          valid = false;
          return;
        }
      });
      if (!valid) {
        createToast(
          this.$t(
            "ViewFilters.DuplicateCriteriaItems",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return;
      }

      var editedCriteriaItem = this.criterias.find(
        (f) => f.number === this.editRow.number
      );

      // editedCriteriaItem.number = params.number;
      editedCriteriaItem.fieldPublicId = params.fieldPublicId;
      editedCriteriaItem.fieldName = params.fieldName;
      editedCriteriaItem.operatorPublicId = params.operatorPublicId;
      editedCriteriaItem.operatorName = params.operatorName;
      editedCriteriaItem.asFormula = params.asFormula;
      editedCriteriaItem.value = params.value;
      editedCriteriaItem.text = params.text;

      this.editCancel();
    },
    editCancel() {
      this.editRow = null;
      this.isRowEdit = false;
    },
    add() {
      if (
        String.isNullOrWhiteSpace(this.field) ||
        String.isNullOrWhiteSpace(this.operator)
      ) {
        createToast(
          this.$t(
            "ViewFilters.FieldOrOperatorNotValid",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return;
      }

      if (this.criterias.length >= 11) {
        createToast(
          this.$t(
            "ViewFilters.MaxCriteriaItem",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return;
      }

      var value = "",
        text = "",
        valueSelector = $("#criteria-value-" + this.fieldType);
      if (
        this.fieldType == "Text" ||
        this.fieldType == "Date" ||
        this.fieldType == "DateTime"
      ) {
        value = valueSelector.val();
        text = value;
      } else {
        value = this.valueId;
        text = this.value;
      }

      var params = {
        number: this.getCriteriaNextRowNumber(),
        fieldPublicId: this.field.key,
        fieldName: this.field.value,
        operatorPublicId: this.operator.value,
        operatorName: this.operator.key,
        asFormula: this.asFormula,
        value: value,
        text: text,
      };

      var valid = true;
      $.each(this.criterias, function (i, v) {
        if (
          params.fieldPublicId === v.fieldPublicId &&
          params.operatorPublicId === v.operatorPublicId &&
          params.value === v.value
        ) {
          valid = false;
          return;
        }
      });
      if (!valid) {
        createToast(
          this.$t(
            "ViewFilters.DuplicateCriteriaItems",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return;
      }

      this.firstRowDefault();
      this.criterias.push(params);
      if (String.isNullOrWhiteSpace(this.expression)) {
        this.expression = String.format("{{0}}", params.number);
      } else {
        this.expression += String.format(" AND {{0}}", params.number);
      }
    },
    firstRowDefault() {
      this.fieldItemsRequestUrl = null;
      this.fieldItemIsMultiple = false;
      this.field = null;
      this.fieldType = null;
      this.operators = [];
      this.value = null;
      this.fieldSelectReset = !this.fieldSelectReset;
      this.operatorValueReset = !this.operatorValueReset;
      this.asFormula = false;
    },
    remove(number) {
      this.criterias = this.criterias.filter(function (item) {
        return item.number !== number;
      });

      // var index = 0;
      // this.criterias.forEach((element) => {
      //   element.number = ++index;
      // });

      if (this.criterias.length == 0) {
        this.expression = null;
      } else {
        this.removeCondition(number);
      }
    },
    removeCondition(mapIndex) {
      var self = this;
      var currentConds = [
        " AND {" + mapIndex + "}",
        " AND {" + mapIndex + "} ",
        "{" + mapIndex + "} AND ",
        "{" + mapIndex + "} AND",
        " OR {" + mapIndex + "}",
        " OR {" + mapIndex + "} ",
        "{" + mapIndex + "}",
        "{" + mapIndex + "} ",
      ];

      $.each(currentConds, function () {
        self.expression = self.expression.replace(this, "");
      });
    },
    getCriteriasMaxNumber() {
      var maxNumber = 0;
      $.each(this.criterias, function () {
        if (this.number > maxNumber) {
          maxNumber = this.number;
        }
      });

      return maxNumber;
    },
    onChangeField(selected) {
      this.operatorValueReset = !this.operatorValueReset;

      this.field = this.fields.find((f) => f.key === selected.key);
      if (!String.isNullOrWhiteSpace(this.field)) {
        this.operators = this.field.operators.map((x) => {
          return { key: x.value, value: x.key };
        });
      }
    },
    onChangeEditField(selected) {
      this.editOperatorValueReset = !this.editOperatorValueReset;

      this.editField = this.fields.find((f) => f.key === selected.key);
      if (!String.isNullOrWhiteSpace(this.editField)) {
        this.editOperators = this.editField.operators.map((x) => {
          return { key: x.value, value: x.key };
        });
      }

      this.editRow.value = null;
      this.editRow.text = null;

      this.editOperator = null;
      this.editValueId = null;
      this.editValue = null;
    },
    onChangeFieldOperator(selected) {
      if (String.isNullOrWhiteSpace(this.field)) return;

      this.operator = this.field.operators.find((f) => f.value == selected.key);
      this.getFieldOperatorDetail();

      this.fieldType = null;
    },
    onChangeEditFieldOperator(selected) {
      if (String.isNullOrWhiteSpace(this.editField)) return;

      this.editOperator = this.editField.operators.find(
        (f) => f.value == selected.key
      );
      this.getEditFieldOperatorDetail();

      this.editFieldType = null;
      this.editRow.value = null;
      this.editRow.text = null;
    },
    getFieldOperatorDetail() {
      if (
        String.isNullOrWhiteSpace(this.field) ||
        String.isNullOrWhiteSpace(this.operator)
      ) {
        this.fieldType = null;
        this.value = null;
        return;
      }

      this.fieldItemsRequestUrl = null;
      this.fieldItemIsMultiple = false;

      this.$prodGatewayAxios
        .get(
          String.format(
            "Lcdp-GetDetailForCriteria?id={0}&oId={1}",
            this.field.model.publicId,
            this.operator.value
          )
        )
        .then((field) => {
          field = field.data;

          if (
            // !field.isMultipleValue &&
            field.operatorKeyName === "includes" ||
            field.operatorKeyName === "not_includes"
          ) {
            field.isMultipleValue = true;
            this.fieldItemIsMultiple = true;
          }

          if (
            field.operatorKeyName === "equals" ||
            field.operatorKeyName === "not_equal_to" ||
            field.operatorKeyName === "not_includes" ||
            field.operatorKeyName === "includes"
          ) {
            this.fieldItemsRequestUrl = "";
            if (
              field.fieldType === "SelectList" ||
              field.fieldType === "Checkbox"
            ) {
              this.fieldItemsRequestUrl += String.format(
                "rws-FieldItems?id={0}",
                field.publicId
              );
            } else if (field.fieldType === "Lookup") {
              this.fieldItemsRequestUrl += String.format(
                "rws-LookupFieldValues?coId={0}&fieldPublicId={1}",
                field.customObjectPublicId,
                field.publicId
              );
            } else if (field.fieldType === "Predefined") {
              this.fieldItemsRequestUrl += String.format(
                "rws-PredefinedItems?parentId={0}",
                field.predefinedPublicId
              );
            } else if (
              field.fieldType === "OrganizationalUnit" ||
              field.fieldType === "FormulaOrganizationalUnit"
            ) {
              this.fieldItemsRequestUrl += String.format(
                "rws-OrganizationalUnitItems?publicId={0}&name={1}&filterType={2}&groupIds={3}&includeItself={4}&depth={5}&isAddCurrentKeys={6}",
                field.publicId,
                field.organizationalUnitName,
                field.organizationalUnitFilterType,
                field.organizationalUnitGroupPublicIds,
                field.organizationalUnitIncludeItself,
                field.organizationalUnitDepth,
                true
              );
            } else {
              this.fieldType = "Text";
            }

            if (
              !String.isNullOrWhiteSpace(this.fieldItemsRequestUrl) &&
              (field.fieldType === "SelectList" ||
                field.fieldType === "Checkbox" ||
                field.fieldType === "Lookup" ||
                field.fieldType === "Predefined" ||
                field.fieldType === "OrganizationalUnit" ||
                field.fieldType === "FormulaOrganizationalUnit")
            ) {
              this.fieldType = "select";
            }
          } else {
            this.fieldType = "Text";
          }

          if (
            field.fieldType == "Date" ||
            field.fieldType == "FormulaDate" ||
            field.fieldType == "RollupDate"
          ) {
            this.fieldType = "Date";
            // this.fieldType = "Text";
          } else if (
            field.fieldType == "DateTime" ||
            field.fieldType == "FormulaDateTime" ||
            field.fieldType == "RollupDateTime"
          ) {
            this.fieldType = "DateTime";
            // this.fieldType = "Text";
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    getEditFieldOperatorDetail() {
      if (
        String.isNullOrWhiteSpace(this.editField) ||
        String.isNullOrWhiteSpace(this.editOperator)
      ) {
        this.editFieldType = null;
        return;
      }

      this.editFieldItemsRequestUrl = null;
      this.editFieldItemIsMultiple = false;

      this.$prodGatewayAxios
        .get(
          String.format(
            "Lcdp-GetDetailForCriteria?id={0}&oId={1}",
            this.editField.model.publicId,
            this.editOperator.value
          )
        )
        .then((field) => {
          field = field.data;

          if (
            !field.isMultipleValue &&
            (field.operatorKeyName === "includes" ||
              field.operatorKeyName === "not_includes")
          ) {
            field.isMultipleValue = true;
            this.editFieldItemIsMultiple = true;
          }

          if (
            field.operatorKeyName === "equals" ||
            field.operatorKeyName === "not_equal_to" ||
            field.operatorKeyName === "not_includes" ||
            field.operatorKeyName === "includes"
          ) {
            this.editFieldItemsRequestUrl = "";
            if (
              field.fieldType === "SelectList" ||
              field.fieldType === "Checkbox"
            ) {
              this.editFieldItemsRequestUrl += String.format(
                "rws-FieldItems?id={0}",
                field.publicId
              );
            } else if (field.fieldType === "Lookup") {
              this.editFieldItemsRequestUrl += String.format(
                "rws-LookupFieldValues?coId={0}&fieldPublicId={1}",
                field.customObjectPublicId,
                field.publicId
              );
            } else if (field.fieldType === "Predefined") {
              this.editFieldItemsRequestUrl += String.format(
                "rws-PredefinedItems?parentId={0}",
                field.predefinedPublicId
              );
            } else if (
              field.fieldType === "OrganizationalUnit" ||
              field.fieldType === "FormulaOrganizationalUnit"
            ) {
              this.editFieldItemsRequestUrl += String.format(
                "rws-OrganizationalUnitItems?publicId={0}&name={1}&filterType={2}&groupIds={3}&includeItself={4}&depth={5}&isAddCurrentKeys={6}",
                field.publicId,
                field.organizationalUnitName,
                field.organizationalUnitFilterType,
                field.organizationalUnitGroupPublicIds,
                field.organizationalUnitIncludeItself,
                field.organizationalUnitDepth,
                true
              );
            } else {
              this.editFieldType = "Text";
            }

            if (
              !String.isNullOrWhiteSpace(this.editFieldItemsRequestUrl) &&
              (field.fieldType === "SelectList" ||
                field.fieldType === "Checkbox" ||
                field.fieldType === "Lookup" ||
                field.fieldType === "Predefined" ||
                field.fieldType === "OrganizationalUnit" ||
                field.fieldType === "FormulaOrganizationalUnit")
            ) {
              this.editFieldType = "select";
            }
          } else {
            this.editFieldType = "Text";
          }

          if (
            field.fieldType == "Date" ||
            field.fieldType == "FormulaDate" ||
            field.fieldType == "RollupDate"
          ) {
            this.editFieldType = "Date";
            // this.editFieldType = "Text";
          } else if (
            field.fieldType == "DateTime" ||
            field.fieldType == "FormulaDateTime" ||
            field.fieldType == "RollupDateTime"
          ) {
            this.editFieldType = "DateTime";
            // this.editFieldType = "Text";
          }

          if (this.editRow.asFormula) {
            this.editOldInformations = {
              editFieldType: this.editFieldType,
              editFieldItemsRequestUrl: this.editFieldItemsRequestUrl,
              editFieldItemIsMultiple: this.editFieldItemIsMultiple,
            };
            this.editFieldType = "Text";
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    getCriteriaNextRowNumber() {
      return this.getCriteriasMaxNumber() + 1;
    },
  },
};
</script>
