<template>
  <div
    :id="id"
    :class="`sx-toggle sx-toggle-btn sx-toggle-btn-default sx-toggle-default-size ${classes} ${
      !selected ? 'off' : ''
    }`"
  >
    <div class="sx-toggle-group">
      <label
        class="btn btn-primary active ps-0 sx-toggle-on"
        @click="onFirst"
        >{{ first }}</label
      ><label
        class="btn btn-warning ps-0 active sx-toggle-off"
        @click="onSecond"
        >{{ second }}</label
      ><span
        class="sx-toggle-handle sx-toggle-btn sx-toggle-btn-default"
      ></span>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "ToggleButton",
  props: ["first", "second", "selected", "classes"],
  emits: ["off", "on"],
  data() {
    return {
      id: String.newGuid(),
    };
  },
  methods: {
    onFirst() {
      $(`#${this.id}`).addClass("off");
      this.$emit("off");
    },
    onSecond() {
      $(`#${this.id}`).removeClass("off");
      this.$emit("on");
    },
  },
};
</script>
