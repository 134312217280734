export default {
  "HomePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page"])},
  "Slogan1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Life Time"])},
  "Slogan2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Management"])},
  "ProductName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetXRM Management Panel"])},
  "Sidebar": {
    "Apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps"])},
    "HomePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page"])},
    "CustomObjectManagements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object Management"])},
    "CustomObjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Objects"])},
    "ProjectGeneralSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project General Settings"])},
    "DataSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Summary"])},
    "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "CustomPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Page"])},
    "Holidays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holidays"])},
    "CustomObjectDeletionRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object Deletion Rules"])},
    "SystemLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Logs"])},
    "RecordLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Logs"])},
    "FormulaErrorLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Error Logs"])},
    "SystemLoginInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Login Information"])},
    "NotificationSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Settings"])},
    "SuperAdminSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super Admin Settings"])},
    "Projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "Users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "FormulaErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Errors"])},
    "UsageCounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage Counts"])},
    "MenuManagements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu Management"])},
    "Menus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menus"])},
    "OrganizationManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization Management"])},
    "UserGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Groups"])},
    "PermissionGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Groups"])},
    "RestrictedPermissionGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restricted Permission Groups"])},
    "DatabaseSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database Settings"])},
    "Configurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurations"])},
    "ProjectParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Parameters"])},
    "BusinessProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Process"])},
    "BusinessProcessManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Process Management"])},
    "ApiToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration API Keys"])},
    "ImportBulkRecordData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Bulk Data"])},
    "SetCodeFunctions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetCode Functions"])},
    "Functions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Functions"])},
    "HttpConnections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Http Connections"])},
    "SetCodeDatabases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database Connections"])},
    "SetCodeModels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Models"])},
    "SetCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetCode"])},
    "SetModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetModel"])},
    "DashboardReporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard Reports"])},
    "XtraReporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xtra Web Reports"])},
    "LocalizationSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localization Settings"])},
    "SystemMessageTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Message Templates"])},
    "PageLayoutTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Layout Templates"])},
    "MultiLanguageSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi Language Settings"])},
    "ProjectDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Design"])},
    "CompanyInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Information"])}
  },
  "Home": {
    "CustomObjectCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object Count"])},
    "UserCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Count"])},
    "FieldCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Count"])}
  },
  "Header": {
    "tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])},
    "Notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOTIFICATIONS"])}
  },
  "ApiToken": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Keys"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New API Key"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit API Key"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New API Key"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Key Name"])},
    "Token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Token"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "UsageCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage Count"])},
    "UserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API User"])}
  },
  "Language": {
    "tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])}
  },
  "FieldOperators": {
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["="])},
    "greather_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([">"])},
    "greather_than_or_equal_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([">="])},
    "less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<"])},
    "less_than_or_equal_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<="])},
    "not_equal_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<>"])},
    "starts_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starts With"])},
    "ends_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ends With"])},
    "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Includes"])},
    "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Includes"])},
    "in_radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Radius"])},
    "out_radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out Radius"])},
    "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contains"])},
    "does_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does Not Contain"])},
    "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Between"])}
  },
  "FieldTypeDescriptions": {
    "text_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can add text box up to 450 characters"])},
    "textarea_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can add text area up to 50,000 characters"])},
    "number_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can add whole and decimal numbers"])},
    "checkbox_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can add questions that check whether a condition exists or require answers such as Yes/No, Active/Passive etc."])},
    "email_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can add emails. Email confirmation and outlook link integration are provided for add and update processes"])},
    "phone_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can add their phone number including the 4-digit country code"])},
    "url_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can add URL addresses. There is a http:// mask in insertion and update operations. Values appear as links on the list and detail page"])},
    "datetime_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can add date and time in Day/Month/Year Hour:Minute format"])},
    "date_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can add dates in Day/Month/Year format"])},
    "time_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can add hours in Hour:Minute format"])},
    "image_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can upload an image and open the uploaded image on the detail page"])},
    "file_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can upload files and open uploaded files on the detail page"])},
    "html_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can add html type fields"])},
    "selectlist_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can select one or more items from a list they define"])},
    "lookup_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can add lookup as a selected list. The selected list item in Lookup is the record of the other object"])},
    "system_lookup_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can add lookup as selected list. The selected list item in Lookup can be users or user groups."])},
    "predefined_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can add a predefined list to the system as a selected list; Province, District, Sector, Sub-Sector"])},
    "encrypted_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can add fields of Encrypted type"])},
    "spatial_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can add area in map type"])},
    "auto_id_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can add Auto ID fields"])},
    "read_only_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can add fields of read-only type"])},
    "percent_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The value of the number is added to the system as a percentage. There is a '%' icon next to the number on detail and list pages."])},
    "double_precision_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimal numbers can be entered in the selected field."])},
    "long_integer_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integers can be entered in the selected field."])},
    "decimal_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whole and decimal numbers can be entered in the selected field. Double Precision takes up more memory than space."])},
    "formula_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can trade with predetermined formula"])},
    "rollup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can perform calculations with fields in related objects"])}
  },
  "BaseModelFields": {
    "CreatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "CreatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "UpdatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
    "UpdatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
    "PasswordDontMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords don't match!"])},
    "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "SerialNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#"])},
    "NoRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Records"])},
    "NoResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No record found to display"])},
    "MaxElements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the maximum number of elements"])},
    "ClickToChoose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to choose"])},
    "ClickToDeSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to deselect"])},
    "Selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
    "ChoosePlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose"])},
    "IsActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Active"])},
    "SaveChangesAndClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and Close"])},
    "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "SelectlistWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is not possible to update this feature after the field opened as Multi-selection or Single-selection."])},
    "NoUpdatedFeature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This property cannot be changed"])},
    "DisplayOrderSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Display Order"])},
    "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "ChangeTheme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Theme"])},
    "Copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied"])},
    "CurrentFieldSystemField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field was created automatically by the system"])},
    "CustomSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Settings"])},
    "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "AlertUnSavedData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have unsaved changes. Do you want to leave this page?"])},
    "Field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field"])},
    "MainSheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Sheet"])},
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object"])},
    "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "Explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explanation"])},
    "GeneralSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Settings"])},
    "TriggerSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger Settings"])},
    "PublicId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public Id"])},
    "RequiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required Field"])},
    "Successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "Failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
    "Waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting"])},
    "DeletedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted At"])},
    "FailedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed At"])},
    "SucceededAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succeeded At"])},
    "PerformanceDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance Duration"])},
    "Latency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latency"])},
    "ExceptionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exception Type"])},
    "ExceptionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exception Message"])},
    "ExceptionDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exception Details"])},
    "ChangeLanguageMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your language setting has been updated, please login again."])},
    "ChangeLanguageWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updating your language setting, please wait..."])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])},
    "UnexpectedErrorHasOccurred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unexpected error has occurred"])},
    "Copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "SwitchFullScreenMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press Esc with the editor selected to switch to full screen mode"])},
    "SaveShortcut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For a quick update, press CTRL + S while the editor is selected. (This action does not redirect to the list page.)"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "Passive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passive"])}
  },
  "CustomObjects": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Objects"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Custom Object"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Custom Object"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Custom Object"])},
    "DetailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Custom Object"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "PluralName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plural Name"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])},
    "FieldCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Count"])},
    "RecordCopiedIsActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Copied Is Active"])},
    "FirstFieldType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Field Type"])},
    "RequiredFieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required Field Name"])},
    "RequiredFieldFormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required Field Formula Name"])},
    "Formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula"])},
    "IsCalculatedOnlyOnInsert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Calculated Only On Insert?"])},
    "TextField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Field"])},
    "FormulaField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Field"])},
    "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
    "Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields"])},
    "PageLayouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Designs"])},
    "ViewFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Filters"])},
    "ObjectRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object Relations"])},
    "ValidationRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation Rules"])},
    "BusinessRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Rules"])},
    "Scripts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom JS Codes"])},
    "Buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buttons"])},
    "FormTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Templates"])},
    "DependencyFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dependency Fields"])},
    "MessageTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message Templates"])},
    "KanbanSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanban Settings"])},
    "CalendarSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar Settings"])},
    "MapSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map Settings"])},
    "TreeViewSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tree View Settings"])},
    "DeletionRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletion Rules"])},
    "Kanban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanban Settings"])},
    "Map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map Settings"])},
    "CreatedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created on "])},
    "UpdatedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated on "])},
    "WFieldCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIELD COUNT"])},
    "ValidationRuleCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VALIDATION RULE COUNT"])},
    "BusinessRuleCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BUSINESS RULE COUNT"])},
    "ScriptCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUSTOM JS COUNT"])},
    "RecordCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECORD COUNT"])},
    "ButtonCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BUTTON COUNT"])},
    "ObjectRelationCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OBJECT RELATION COUNT"])},
    "BusinessRuleActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Rule Actions"])},
    "BrCacheTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Rules of Custom Object"])},
    "ExampleLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example Links"])},
    "ShowExampleLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Example Links"])},
    "HideExampleLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide Example Links"])},
    "RelationalRecordDeleteCustomObjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defining an Associated Object to be Deleted"])},
    "Relations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object Relations"])},
    "ActivePassive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active/Passive"])},
    "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "Clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])}
  },
  "ValidationRules": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Validation Rule"])},
    "NewValidationRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Validation Rule"])},
    "EditValidationRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Validation Rule"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object"])},
    "ValidationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation Type"])},
    "ValidationTypeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "ValidationTypeWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
    "ValidationTypeInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "ValidationTypeDetailBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Block"])},
    "ValidationTypeEditBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Block"])},
    "ValidationTypeDetailForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Forward"])},
    "ValidationTypeEditForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Forward"])},
    "ValidationTypeValidationForComponent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation For Component"])},
    "ValidationTypeValidationForButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation For Button"])},
    "ValidationTypeDeleteBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Block"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "Formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula"])},
    "FormulaInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the validation to work, the written formula must return true. This warning is for informational purposes only."])},
    "ErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
    "ErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error Message"])},
    "IsShowMessageInModal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Message in Modal"])},
    "IsCheckOnFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Check Page Layout on Formula "])},
    "PageLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Designer"])},
    "FieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Name"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])}
  },
  "ViewFilters": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New View Filter"])},
    "NewViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New View Filter"])},
    "EditViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit View Filter"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])},
    "isDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
    "SystemViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System View Filter"])},
    "ViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Filter"])},
    "GeneralSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Settings"])},
    "Criterias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criterias"])},
    "Columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Columns"])},
    "Orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
    "Totals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totals"])},
    "VisibleFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible Fields"])},
    "HiddenFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hidden Fields"])},
    "TotalField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Field"])},
    "TotalType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Type"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "IsDefaultFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Default"])},
    "IsSystemFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is System"])},
    "IsEditInlineOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inline Update On"])},
    "IsEditInlineButtonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inline Setting On Page"])},
    "ActiveButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Function Buttons"])},
    "FilterSearchBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter Search Box"])},
    "InstantFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant Filter"])},
    "KanbanButtonGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanban"])},
    "CalendarButtonGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])},
    "MapButtonGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
    "ExportButtonGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "BulkProcessButtonGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulk Process"])},
    "New": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "TreeButtonGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tree"])},
    "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "RelationDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relation Detail"])},
    "IsRelationDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Relation Detail"])},
    "ActiveActionButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Action Buttons"])},
    "LookupRelation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lookup Relation"])},
    "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
    "OrderField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Field"])},
    "OrderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Type"])},
    "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "Remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "Ascending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascending"])},
    "Descending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descending"])},
    "Sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
    "Avarage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avarage"])},
    "Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])},
    "Max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
    "Min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
    "CriteriaFieldsNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No field found for criteria."])},
    "DuplicateCriteriaItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are duplicate items in your criteria. Please check."])},
    "MaxCriteriaItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add a maximum of 11 criteria. Please check."])},
    "EnterValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a value"])},
    "PleaseAddTheFieldToDisplayMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add the field(s) to display and try again."])},
    "Buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buttons"])},
    "Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Button"])},
    "ColumnsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your visible or hidden fields"])},
    "DesignButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Design Tool"])},
    "Preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview and Sort Buttons Toggle"])},
    "PageFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Filter"])},
    "CriteriaNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "CriteriaField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field"])},
    "CriteriaOperator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator"])},
    "IsPageFilterCollapsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is page filter collapsed"])},
    "FieldOrOperatorNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete the field and operator selections"])},
    "PageCriteriaNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven't added any criteria yet"])},
    "IsCellEditingEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cell Editing Enable"])},
    "SaveAndNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save And New"])},
    "AllAppendVisibleFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add All Fields Visible"])},
    "AllRemoveVisibleFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear All Visible Fields"])},
    "TableWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table Width"])},
    "ColumnWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column Width"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])}
  },
  "ObjectRelations": {
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Object Relation"])},
    "NewObjectRelation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Object Relation"])},
    "EditObjectRelation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Object Relation"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object"])},
    "RelationLookupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relation Lookup Name"])},
    "LookupObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lookup Object Name"])},
    "ViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Filter"])},
    "FieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Name"])},
    "RelationField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relation Field"])},
    "PageSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Size"])},
    "IsEditInline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is edit inline"])},
    "IsAddInline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is add inline"])},
    "ShowInNewPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show in new page"])},
    "ShowInEditPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show in edit page"])},
    "IsItLoadedOnFirstRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is it loaded on first request?"])},
    "New": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "ActiveButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Buttons"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "IsSaveAndContinueButtonEnabledOnWindowOpener": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Save And Continue Button Enabled On Window Opener?"])}
  },
  "KanbanView": {
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FormulaName"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Kanban "])},
    "NewKanban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Kanban Setting"])},
    "EditKanban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Kanban Setting"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object"])},
    "KanbanColumnField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanban Column Field"])},
    "HeaderField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Header Field"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "PageLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Designer"])},
    "CalculatedField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculated Field"])},
    "CalculatedFieldSuffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculated Field Suffix"])},
    "AggregateFunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agggregate Function"])},
    "CountSuffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count Suffix"])},
    "AggregateSum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
    "AggregateAverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average"])},
    "AggregateCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])},
    "AggregateMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
    "AggregateMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])}
  },
  "MapView": {
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Map "])},
    "NewMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Map Setting"])},
    "EditMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Map Setting"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object"])},
    "SpatialField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spatial Field"])},
    "LabelTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label Title"])},
    "DefaultMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Map"])},
    "OpenStreetMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Street Map"])},
    "Google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
    "Atlas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atlas"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])}
  },
  "SetScript": {
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object"])},
    "ClientScript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Scripts"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Script"])},
    "NewSetScript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Script"])},
    "EditSetScript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Script"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "ScriptCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Script Code"])},
    "ScriptType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Script"])},
    "ScriptTypeNewRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Record View"])},
    "ScriptTypeEditRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Record View"])},
    "ScriptTypeListRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Listing View"])},
    "ScriptTypeDetailRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Detail View"])},
    "ScriptTypeKanban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanban View"])},
    "ScriptTypeCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar View"])},
    "ScriptTypeMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map View"])},
    "ScriptTypeTree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tree View"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object Name"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "IsRunAfterPageLayoutLoaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is run after page layout loaded?"])}
  },
  "Fields": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Field"])},
    "NewField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Field"])},
    "EditField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Field"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object Name"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "HelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Text"])},
    "DefaultValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Value"])},
    "Length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length"])},
    "DecimalPlaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimal Places"])},
    "IsCalculateOnClientChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Calculate On Client Change"])},
    "CalculateTriggerField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate Trigger Field"])},
    "IsClientCalculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Client Calculate"])},
    "Required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
    "IsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Required"])},
    "IsUnique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Unique"])},
    "IsEditable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Editable"])},
    "IsCheckUnchanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Check Unchanged"])},
    "IsUniqueCheckClientCalculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Unique Check Client Calculate"])},
    "IsThousandSeparator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Thousand Separator"])},
    "Checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checked"])},
    "Unchecked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unchecked"])},
    "ZeroIsUnlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Zero is unlimited)"])},
    "Max450Character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max 450 character"])},
    "MultipleSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Multiple"])},
    "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "Article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article"])},
    "Document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
    "FieldType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Type"])},
    "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SelectList"])},
    "Table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table"])},
    "ImageSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image Selection"])},
    "OnylViewOnDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onyl View on Detail"])},
    "OnlyViewOnModal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only View on Modal"])},
    "OnlyViewOnDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only View on Download"])},
    "IsShortAlphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short Alphabetically"])},
    "SelectListItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectlist Items"])},
    "AddSelecListItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Selectlist Items"])},
    "IsControllingFilterAnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Controlling Filter And"])},
    "IsRadio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Radio Button"])},
    "SelectListSelectedItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Selected Value/Values"])},
    "IsParentPredefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is there a parent relation?"])},
    "IsPlusButtonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Button Active"])},
    "IsViewingFilterButtonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewing Filter ButtonActive"])},
    "IsSplitSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Split Search"])},
    "IsLookupFieldGroupWanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you willing to customize the visible fields?"])},
    "IsRelationTableCreatedOnReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Relation Table on Report DB"])},
    "OrganizationalUnitShowColumns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Columns"])},
    "LookupFieldPublicIds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lookup Fields"])},
    "UserGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Group"])},
    "PermissionGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Group"])},
    "AllUserGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Groups"])},
    "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "AllUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Users"])},
    "UseroftheUserGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User(s)of the User Group"])},
    "UsersofthePermissionGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users of the Permission Group"])},
    "ChildrenGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Children Groups"])},
    "FilterFromField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter From Field"])},
    "FilterFromGiven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter From Given"])},
    "AllDescendantsGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Descendants Groups"])},
    "IncludeItself": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Include Itself"])},
    "Predefinedlists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predefined lists"])},
    "TurkeyCities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cities in Turkey"])},
    "TurkeyCounties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Counties in Turkey"])},
    "TurkeyTowns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Towns in Turkey"])},
    "TurkeyDistricts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Districts in Turkey"])},
    "TurkeyPostalCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Codes in Turkey"])},
    "Currencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currencies"])},
    "Languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
    "CountriesInTurkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countries in Turkish"])},
    "CountriesInEnglish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countries in English"])},
    "IsEncrypted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Encrypted"])},
    "EncryptedType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encrypted Type"])},
    "Regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regex"])},
    "ReadMask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read Mask"])},
    "IsUpdateWholeRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update whole records"])},
    "AggregateFunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggregate Function"])},
    "Sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
    "Avarage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avarage"])},
    "Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])},
    "Max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
    "Min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
    "IsTableRollup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table Rollup"])},
    "ObjectsTableFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object Table Fields"])},
    "TableFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table Fields"])},
    "LookupObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lookup Object"])},
    "ViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Filter"])},
    "CustomSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Settings"])},
    "OrgUnitShowColumForName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "OrgUnitShowColumnForSurname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
    "OrgUnitShowColumnForEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "ControlingRelationField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrol İlişki Alanı"])},
    "ControlingField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controling Field"])},
    "EncryptedCreditCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encrypted Credit Card"])},
    "EncryptedEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encrypted E-mail"])},
    "EncryptedPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encrypted Password"])},
    "RollupFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields"])},
    "Formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula"])},
    "IsCalculatedOnlyOnInsert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Calculated Only On Insert?"])},
    "IsCalculatedOnlyOnInsertUnCheckedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Value will be calculated on every update request. Are you sure?"])},
    "IsAutoIdTextBasedIncrement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Based Increment"])},
    "AutoIdIncrementField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "Passive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passive"])},
    "FormulaIsEditableLabelText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not calculate on edit if value is not null for record"])},
    "NotFormulaIsEditableLabelText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read Only On Edit Pages"])},
    "FieldValueCopyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which field would you like to copy the value of?"])},
    "SetSelectedItemByFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Selected Item By Formula"])},
    "IsDataReadFromMainTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is data read from main table"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "FieldDiagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Diagram"])}
  },
  "PageLayouts": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Page Layout"])},
    "NewPageLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Page Layout"])},
    "EditPageLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Page Layout"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "Main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main"])},
    "FormTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Templates"])},
    "DeleteButtonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "EditButtonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "HistoryButtonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
    "IsCellEditingEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cell Editing Enable"])},
    "ActiveButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Buttons"])},
    "PageDesignEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no field in the page design. Please check."])},
    "IsMobileLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Page Layout"])},
    "DependentPageLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dependent Mobile PageLayout"])},
    "MobileSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Settings"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])},
    "DependentPageLayoutName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dependent Mobile PageLayout"])},
    "IsApiModelEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Create API Model Enabled"])},
    "LayoutCopyTemplateButon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Page Design With No Item (Field, Button etc.)"])},
    "LayoutPasteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste Page Design. Current Design Will Be Deleted"])},
    "LayoutCopyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clone With Items"])},
    "CopyApiRequestForLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy API Request For Page Layout"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object Name"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])}
  },
  "CalendarViews": {
    "NewCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Calendar"])},
    "EditCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Calendar"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])},
    "StartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
    "EndDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
    "TitleObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label Title"])},
    "TimeLineField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field to Display On Calendar"])},
    "PageLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Designer"])},
    "IsInsertOnCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert on Calendar"])},
    "IsEditableOnCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit on Calendar"])},
    "Views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar Type"])},
    "DefaultView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Calendar View"])},
    "MinTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Range Start Time"])},
    "MaxTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Range End Time"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "LightboxRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light Box Rate"])},
    "DataFetchSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Fetch Size"])},
    "ColorField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color Code Field"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object Name"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "ColorFieldFormulaExample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<u>Example Usage</u><br>if(STATUS.Equals(&quot;74e392c1-2d1c-4c8f-9499-8a6cc81f3bf0&quot;);&quot;#13eb0c&quot;;&quot;#ebe01e&quot;)"])}
  },
  "TreeViews": {
    "NewTree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Tree View"])},
    "EditTree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Tree View"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])},
    "ViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Filter"])},
    "HeaderField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Header Field"])},
    "SubObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subobject"])},
    "LayerViewField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layer View Field"])},
    "ChildField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child Field"])},
    "Icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon"])},
    "IconColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon Color"])},
    "ChildIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child Icon"])},
    "ChildIconColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child Icon Color"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object Name"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "LookupRelationWarningMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The custom object to which this relation object belongs does not have any relation objects."])},
    "LayerViewWarningMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To add a new tree branch, first fill in the fields."])}
  },
  "DependencyFields": {
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object"])},
    "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "ControllingField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlling Field"])},
    "ControllingFieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlling Field Name"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Dependency Field"])},
    "NewDependencyField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Dependency Field"])},
    "DetailDependencyField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Dependency Field"])},
    "General": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "SelectTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Table"])},
    "EditDependencyField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Dependency Field"])},
    "FieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Name"])},
    "DependentField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dependent Field"])},
    "PageSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Size"])},
    "New": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "ActiveButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Buttons"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object Name"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "PleaseRelateTheFieldsWithEachOther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please relate the fields with each other"])}
  },
  "MessageTemplates": {
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object"])},
    "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "TemplateType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template Type"])},
    "IsActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "TemplateDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template Design"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Message Template"])},
    "NewMessageTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Message Template"])},
    "DetailMessageTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Message Template"])},
    "General": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "SelectTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Table"])},
    "EditMessageTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Message Template"])},
    "FieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Name"])},
    "DependentField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dependent Field"])},
    "PageSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Size"])},
    "New": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "ActiveButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Buttons"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])}
  },
  "SystemMessageTemplates": {
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object"])},
    "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "TemplateType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template Type"])},
    "IsActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "TemplateDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template Design"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Message Template"])},
    "NewMessageTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Message Template"])},
    "DetailMessageTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Message Template"])},
    "General": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "SelectTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Table"])},
    "EditMessageTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Message Template"])},
    "FieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Name"])},
    "DependentField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dependent Field"])},
    "PageSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Size"])},
    "New": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "ActiveButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Buttons"])},
    "TemplateLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "TemplateKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template Key"])},
    "BulkImports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulk Data Import"])},
    "ResetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
    "DataExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Export"])},
    "SendTestMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Test Mail"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])}
  },
  "FormTemplates": {
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object"])},
    "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "OutputType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Output Type"])},
    "File": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
    "IsActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Form Template"])},
    "NewFormTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Form Template"])},
    "General": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "SelectTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Table"])},
    "EditFormTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Form Template"])},
    "FieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Name"])},
    "DependentField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dependent Field"])},
    "PageSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Size"])},
    "New": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "ActiveButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Buttons"])},
    "BrowseOrDragandDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse Or Drag & Drop"])},
    "IsGetFromFilenameFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Get From Filename Fields?"])},
    "IsSetEmptyIfValueNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Set Empty If Value Not Found?"])},
    "IsPermissionApply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Permission Apply?"])},
    "IsValidationApply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Validation Apply?"])},
    "ValidationTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation can be applied per record and users can make transactions according to validation result."])},
    "FileNameFieldNames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Name Field Names"])},
    "Formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula"])},
    "Validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object Name"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "DashboardReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard Reports"])},
    "XtraReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xtra Web Reports"])},
    "ReportType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Type"])},
    "IsPreviewEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Preview Enabled?"])},
    "FirstParameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Parameter"])},
    "SecondParameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Parameter"])}
  },
  "ReportTypes": {
    "WordTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Word Template"])},
    "XtraReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xtra Report"])},
    "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
  },
  "CustomButtons": {
    "Buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buttons"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Button"])},
    "EditButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Button"])},
    "ButtonType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Button Type"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Active"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "BusinessRuleButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Rule"])},
    "DirectForwardButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct Forward"])},
    "CustomForwardButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Forward"])},
    "FormTemplateButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Template"])},
    "DirectForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct Forward"])},
    "NewTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Tab"])},
    "LightBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light Box"])},
    "ActiveButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Buttons"])},
    "StaticLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Static Link"])},
    "ForwardType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forward Type"])},
    "LightboxRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light Box Rate"])},
    "Icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon"])},
    "IconColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon Color"])},
    "IsIconViewEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Icon View Enabled"])},
    "Field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field"])},
    "BusinessRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Rule"])},
    "SendDefaultValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Default Value"])},
    "SendDefaultValueObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object"])},
    "IsValidationApply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Validation Apply?"])},
    "Validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation"])},
    "ValidationTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation can be applied per record and users can see this button according to validation result."])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "IsShowApprovalMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Show Approval"])},
    "ApprovalMessageContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approval Message"])},
    "IsPageRefreshAfterProcessDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Page Refresh After Process Done?"])},
    "AppearanceSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appearance Settings"])},
    "ValidationAppearanceSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation Appearance Settings"])},
    "ShowAtDetailPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show At Detail Page"])},
    "ShowAtNewPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show At New Page"])},
    "ShowAtEditPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show At Edit Page"])},
    "IsApplyForHideShowOnViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is For Hide View Filter Apply?"])},
    "IsApplyForHideShowOnPageLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is For Hide Page Layout Apply?"])},
    "ApplyHideShowMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It may affect performance depending on the number of records and columns on the page."])}
  },
  "Menus": {
    "Menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menus"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Menu"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])},
    "Url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Site"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Menu"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Menu"])},
    "IsSideBarActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Sidebar Active"])},
    "IsDocumentActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Button Active"])},
    "IsChatActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat Button Active"])},
    "IsDelegationActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegation Button Active"])},
    "IsSystemNotificationActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Notification Button Active"])},
    "MenuItems": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu Items"])},
      "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "Url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Site"])},
      "MenuTree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu Tree"])},
      "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Menu Item"])},
      "EditSubItemTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Menu Sub-Item"])},
      "NewMenuItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Menu Item"])},
      "NewMenuSubItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Menu Sub-Item"])},
      "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Menu Item"])},
      "NewSubItemTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Menu Sub Item"])},
      "IsOpenNewTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Open New Tab"])},
      "IsSidebarItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Sidebar Item"])},
      "IconColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon Color"])},
      "DetailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu Item Detail"])},
      "Icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon"])},
      "SidebarItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidebar Items"])},
      "NavbarItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navbar Items"])},
      "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object"])},
      "IsCustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Custom Object Path"])},
      "IsSidebarItemIconRequiredMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon selection is required in adding a Sidebar Item. Please check."])}
    }
  },
  "ProjectParameters": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Parameters"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Project Parameters"])},
    "NewProjectParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Project Parameter"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Project Parameter"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Project Parameter"])}
  },
  "Projects": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Project"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Project"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Project"])},
    "CompanyId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company id"])},
    "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Name"])},
    "CompanyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])},
    "CompanyAdminEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Admin Email"])},
    "RelatedPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related Person"])},
    "RecordCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Count"])},
    "UserCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Count"])},
    "ActiveUserCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active User Count"])},
    "CreateDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Date"])},
    "DateOfContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Date"])},
    "DetailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Detail"])},
    "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
    "Configurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurations"])},
    "UsageInformations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage Informations"])},
    "ContractInformations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Informations"])},
    "FormulaErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Errors"])},
    "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "Instance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database"])},
    "FirstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "LastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "PasswordAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Again"])},
    "BlankProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blank Project"])},
    "Clone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clone"])},
    "ProjectInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Information"])},
    "DatabaseInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database Information"])},
    "UserInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Information"])},
    "TaxOffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Office"])},
    "TaxNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Number"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "County": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["County"])},
    "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "Fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
    "ContactPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person"])},
    "ContactPersonEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person Email"])},
    "ContractStartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Start Data"])},
    "UnitPriceOfUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Price of User"])},
    "StartUserCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start User Count"])},
    "CurrentUserCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current User Count"])},
    "IsIpControl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Ip Control"])},
    "IsFormulaErrorLogOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Formula Error Log Open"])},
    "IsRecordLogOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Record Log Open"])},
    "IsRecordViewLogOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Record View Log Open"])},
    "IsLabelMultiLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Label Multi Language"])},
    "IsApiLogOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Api Log Open"])},
    "IsMessageOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Message On"])},
    "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "IsTwoFactorAuth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Two Factor Auth"])},
    "ProjectPassive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The project is inactive. Login cannot be performed. You can contact your consultant"])},
    "ActivePassive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active/Passive"])},
    "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "Clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])}
  },
  "DatabaseSettings": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database Settings"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Database Setting"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Database Setting"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Database Setting"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "DbType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "InstanceIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ip Address"])},
    "SchemaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schema Name"])},
    "CnnStringWrite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection String"])},
    "CnnStringRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection String Read"])},
    "Postgresql": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postgresql"])},
    "Sql": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MsSql"])}
  },
  "FieldErrors": {
    "UrlNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid url"])},
    "EmailNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email address"])},
    "TextNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill this field"])},
    "FileNotSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select file"])},
    "ImageNotSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select image"])},
    "NoGuidFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the Record Public Id field in the desired format. (00000000-0000-0000-0000-000000000000)"])},
    "FilterItemsNotChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please change the filtering options and try again."])},
    "UploadFileNotSupportedForWord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload word file"])},
    "UploadFileNotSupportedForExcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload excel file"])},
    "ExcelColumnsEmptyOrWrongDataType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel columns are empty or contain the wrong data type. Please check and try again"])},
    "FillInTheRequiredFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in the required fields"])},
    "NoOrderChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No order changes are made on filtered listings. Please clear the search criteria and try again."])}
  },
  "RecordLogs": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Logs"])},
    "PageFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Filter"])},
    "ActionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action Type"])},
    "StartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
    "EndDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
    "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "Clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
    "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "Source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
    "Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "Record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record"])},
    "RecordPublicId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Public Id"])},
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object"])},
    "DifferenceBetweenTwoRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Difference Between Two Records"])},
    "FieldServiceCreateRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Created"])},
    "FieldServiceUpdateRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Updated"])},
    "FieldServiceDeleteRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Deleted"])},
    "FieldServiceUpdateFieldValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Updated"])},
    "KanbanViewServiceUpdateFieldValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanban Field Updated"])},
    "CalendarViewServiceUpdateFieldValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar Field Updated"])},
    "FieldServiceGetRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record View"])},
    "FieldServiceExportRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Export"])},
    "FieldServiceBulkUpdateRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Bulk Update"])},
    "UserStatusUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Status Updated"])},
    "FieldServiceBulkImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Bulk Import"])},
    "LogDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Detail"])},
    "LogCompare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare Records"])},
    "LogIsEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The contents of the log file could not be found."])},
    "FieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Name"])},
    "NewValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Value"])},
    "OldValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old Value"])},
    "InvertTableColors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invert Table Colors"])}
  },
  "FormulaErrorLogs": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Error Logs"])},
    "ErrorLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error Log"])},
    "ExceptionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exception Message"])},
    "Formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula"])}
  },
  "ImportBulkRecordData": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Bulk Record Data"])},
    "NewBulkImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Bulk Import"])},
    "DataCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veri Adeti"])},
    "NewImportBulkRecordData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Bulk Import Data"])},
    "Insert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert"])},
    "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "RecordUploadType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Upload Type"])},
    "DoNotTriggerBusinessRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not Trigger Business Rule"])},
    "DoNotCalculateRollupFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not Calculate Rollup Fields"])},
    "AreYouSureYouWantToDeletetheRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the record?"])},
    "DoNotCalculateFormulaFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not Calculate Formula Fields"])},
    "InstantStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant Start"])},
    "StartAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Time"])},
    "ImportFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Excel File (xls,xlsx)"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email to For Process Notification"])},
    "ReloadMapping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your matched data will be reset. Are you sure?"])},
    "MappingTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match Fields"])},
    "ExcelField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel Field"])},
    "ObjectField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object Field"])},
    "ReferenceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference Type"])},
    "ReferenceField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference Field"])},
    "PleaseSelectReferenceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select reference type"])},
    "PleaseSelectReferenceField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select reference field"])},
    "ReferenceTypeForName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "ReferenceTypeForField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields"])},
    "NoUnique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You added similar match before. Please check the matching table"])},
    "NoUniqueItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You added similar matches before. Please check the matching table"])},
    "NoMatchField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please match at least one excel field and custom object field"])},
    "CompletedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed At"])},
    "UploadedFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded File"])},
    "ProcessedFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processed File"])},
    "Monitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulk Data Import Monitoring"])},
    "CreatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "ExpireAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date to Delete Transaction Log"])},
    "FetchedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fetched At"])},
    "StateName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "StateReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Description"])},
    "StateData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Data"])},
    "Waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting"])},
    "Processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
    "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
    "CompletedWithErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed With Errors"])},
    "Failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
    "Succeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succeeded"])},
    "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])},
    "Canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled"])}
  },
  "DataSummary": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Data Summary"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Summary"])}
  },
  "Dashboard": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Dashboard"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Dashboard"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Dashboard"])},
    "EditHtmlTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Html&Script"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "isDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
    "HTML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML"])},
    "Script": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Script"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])}
  },
  "CustomPage": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Custom Page"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Custom Page"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Custom Page"])},
    "EditHtmlTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Html&Script&Css"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Page"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "isDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
    "HTML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML"])},
    "Script": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Script"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])},
    "CSS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSS"])},
    "IsSwitchCustomPagesEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Switch Custom Pages Enabled"])},
    "IsMenuHidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Menu Hidden"])},
    "IsHideOnDropdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Hide On Dropdown"])}
  },
  "Holidays": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Holiday"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Holiday"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Holiday"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holidays"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
    "IsHalf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Half"])}
  },
  "CustomObjectDeleteRules": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Custom Object Delete Rule"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object Delete Rules"])}
  },
  "SystemLogs": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Logs"])}
  },
  "SystemLoginInformations": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Login Informations"])}
  },
  "NotificationSettings": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Settings"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Notifier Setting"])},
    "SendMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Test Mail"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Notifier Setting"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Notifier Setting"])},
    "DisplayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display Name"])},
    "NotifierType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifier Type"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "SMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
    "UserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Name"])},
    "Domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "IsExchangeServer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange Server"])},
    "SenderEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMTP Email"])},
    "Port": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMTP Port"])},
    "Host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMTP Host"])},
    "SSL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is SMTP Using"])},
    "TestEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send test Email"])},
    "TestSMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send test SMS"])},
    "SMSBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS Balance"])},
    "IsBeingUsedForSystemEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Being Used For System Email"])}
  },
  "Users": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New User"])},
    "DeletedUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted Users"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New User"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit User"])},
    "DetailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail User"])},
    "GeneralSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Info"])},
    "CustomSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Info"])},
    "ChangeProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Profile Picture"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "FirstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "LastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "Degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree"])},
    "UserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Name"])},
    "GSM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Mobile Number"])},
    "IdentityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity Number"])},
    "Extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extension"])},
    "Department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
    "BirthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
    "StartWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employment Start Date"])},
    "DepartureWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure Date"])},
    "PermissionGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Groups"])},
    "UserGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Groups"])},
    "WelcomeUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome Url"])},
    "ActiveLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Language"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
    "EN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "SpecificCode1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Code 1"])},
    "SpecificCode2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Code 2"])},
    "SpecificCode3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Code 3"])},
    "Level1Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level 1 User Manager"])},
    "Level2Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level 2 User Manager"])},
    "Level3Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level 3 User Manager"])},
    "Buddy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buddy"])},
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object"])},
    "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "Read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read"])},
    "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
    "Objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objects"])},
    "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "ViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Filter"])},
    "PageDesigner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Designer"])},
    "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "CustomPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Page"])},
    "PermissionSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Settings"])},
    "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])},
    "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "IsAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Allowed"])},
    "PermissionGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Group Name"])},
    "UserGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Group Name"])},
    "MarkAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark All"])},
    "UnmarkAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unmark All"])},
    "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "DetailRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Read"])},
    "FileRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Read"])},
    "DataExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Export"])},
    "AddRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A/R"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "PasswordAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Again"])},
    "CacheTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cache"])},
    "CacheDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Page Shows Cache Data For <b class='badge badge-warning text-capitalize'><i class='bi bi-person-fill'></i> USER_NAME</b> <br>User Permissions are showns as sum of personal permissions and perission groups with childrelations. <br> If there is a problem with data, save related records again and wait one minute. <br>  If problem still occurs, contact with your <u>consultant.</u>"])},
    "Menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "Permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
    "ChildRecordCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child Element Count"])},
    "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
    "PageDesigners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Designers"])},
    "ViewFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Filters"])},
    "CacheReload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cache Data Reload"])},
    "IsApiUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Api User"])},
    "CustomPages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Pages"])},
    "CustomPageName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Page Name"])},
    "DashboardReportOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
    "DashboardReportFormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])},
    "DashboardReportName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Name"])},
    "DashboardReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard Reports"])},
    "XtraReportOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
    "XtraReportFormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])},
    "XtraReportName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Name"])},
    "XtraReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xtra Reports"])},
    "PageFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Filter"])},
    "ActivePassive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active/Passive"])},
    "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "Clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
    "UndoDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undo Delete"])},
    "ActiveMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Menu"])}
  },
  "UserGroups": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Groups"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New User Group"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New User Group"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit User Group"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "ParentUserGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent Name"])},
    "DetailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Group Detail"])},
    "ParentUserGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent User Group"])},
    "SpecificCode1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Code 1"])},
    "SpecificCode2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Code 2"])},
    "SpecificCode3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Code 3"])},
    "GroupAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Admin"])},
    "PageFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Filter"])},
    "ActivePassive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active/Passive"])},
    "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "Clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
    "ParentUserGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent User Groups"])}
  },
  "PermissionGroups": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Groups"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Permission Group"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Permission Group"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Permission Group"])},
    "DetailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Group Detail"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "ParentPermissionGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent Name"])},
    "ParentPermissionGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent Permission Group"])},
    "PermissionSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Settings"])},
    "ActiveMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Menu"])},
    "SpecificCode1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Code 1"])},
    "SpecificCode2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Code 2"])},
    "SpecificCode3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Code 3"])},
    "GroupAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Admin"])},
    "IsGetPermissionFromParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Get Permission From Parent"])},
    "CheckAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark All"])},
    "UnCheckAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unmark All"])},
    "CustomObjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Objects"])},
    "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "ViewFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Filters"])},
    "ViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Filter"])},
    "PageLayouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Layouts"])},
    "PageLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Layout"])},
    "DashboardReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard Reports"])},
    "XtraReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xtra Web Reports"])},
    "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "CustomPages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Pages"])},
    "CustomPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Page"])},
    "AddRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A/R"])},
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object"])},
    "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "Read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read"])},
    "DetailRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Read"])},
    "FileRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Read"])},
    "DataExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Export"])},
    "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
    "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])},
    "IsAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Allowed"])},
    "WelcomeUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome Url"])},
    "PageFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Filter"])},
    "ActivePassive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active/Passive"])},
    "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "Clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
    "ParentPermissionGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent Permission Groups"])}
  },
  "RestrictedPermissionGroups": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restricted Permission Groups"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Restricted Permission Group"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Restricted Permission Group"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Restricted Permission Group"])},
    "DetailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restricted Permission Group Detail"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "PermissionSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Settings"])},
    "ActiveMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Menu"])},
    "SpecificCode1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Code 1"])},
    "SpecificCode2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Code 2"])},
    "SpecificCode3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Code 3"])},
    "GroupAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Admin"])},
    "CheckAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark All"])},
    "UnCheckAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unmark All"])},
    "CustomObjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Objects"])},
    "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "ViewFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Filters"])},
    "ViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Filter"])},
    "PageLayouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Layouts"])},
    "PageLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Layout"])},
    "DashboardReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard Reports"])},
    "XtraReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xtra Web Reports"])},
    "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "CustomPages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Pages"])},
    "CustomPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Page"])},
    "AddRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A/R"])},
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object"])},
    "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "Read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read"])},
    "DetailRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Read"])},
    "FileRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Read"])},
    "DataExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Export"])},
    "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
    "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])},
    "IsAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Allowed"])},
    "WelcomeUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome Url"])},
    "PageFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Filter"])},
    "ActivePassive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active/Passive"])},
    "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "Clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])}
  },
  "FormulaErrors": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Errors"])}
  },
  "UsageCounts": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage Counts"])}
  },
  "Configurations": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurations"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Configuration"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Configuration"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Configuration"])},
    "SetXrmKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetXrm Key"])},
    "SetXrmDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetXrm Description"])},
    "Key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key"])},
    "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
    "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "String": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["String"])},
    "Integer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integer"])},
    "Bool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bool"])},
    "Encrypted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encrypted"])},
    "TypeSetXRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetXRM"])},
    "TypeCustom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Custom Configurations"])},
    "TypeAllSetxrm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All SetXRM Configurations"])},
    "TypeSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetXRM Configuration"])},
    "TypeCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Configuration"])},
    "NewSetxrmConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New SetXRM Configuration"])},
    "NewCustomConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Custom Configuration"])},
    "CompanyId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Id"])},
    "CompanyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
    "Decimal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimal"])},
    "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "DateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DateTime"])},
    "Boolean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boolean"])}
  },
  "Components": {
    "ScriptHelperModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Script Helper"])},
      "SXClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SX(SetXrm) Class"])},
      "EventListenerParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Listener Parameters"])},
      "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "Example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example"])},
      "Parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters"])},
      "CRUD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create, Read, Update and Delete (CRUD)"])},
      "FormElements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Elements"])},
      "Required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
      "SX_NEW_RECORD_CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggered when create a record"])},
      "SX_EDIT_RECORD_UPDATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggered when record is updated"])},
      "SXGRID_ON_PAGING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggered when switching between pages in a grid element"])},
      "SXGRID_ON_SORTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggered when sorting a grid element"])},
      "SXGRID_ON_REFRESH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggered when grid element refreshes"])},
      "SXGRID_ON_SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggered when searching a grid element"])},
      "SXGRID_GETDATA_FINALLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggered when the Grid element finishes fetching records"])},
      "SXGRID_GETDATA_REQUEST_UNSUCCESSFUL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggered if an error occurs while fetching grid element records"])},
      "SXGRID_CONTENT_READY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggered when drawing on the grid element is finished"])},
      "SXGRID_INITIALIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggered when init grid element"])},
      "SXGRID_INIT_NEW_ROW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggered when a new record is entered on a grid element"])},
      "SXGRID_ON_SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggered when adding or updating records on a grid element"])},
      "SXGRID_EDITING_CHANGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggered when there is a change in any row on the grid element"])},
      "CreateNotificationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can create instant notification"])},
      "FormIsValidDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provides validation of the form object in the window"])},
      "PrepareDataGridDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can draw a devexpress data grid within the window element you specify. For total and settings you can visit https://js.devexpress.com/Vue/Documentation/Guide/UI_Components/DataGrid/Getting_Started_with_DataGrid/"])},
      "GetDxDataGridInstanceDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It allows you to access the devexpress data grid object in the window"])},
      "AddEventListenerDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can assign listeners to the system"])},
      "GetEventListenersDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can access the listeners you have assigned to the system"])},
      "RemoveEventListenersDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletes the listeners you have assigned to the system"])},
      "Select2ResetValueDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resets the value of the Select2 object"])},
      "Select2SetValueDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can assign the value of Select2 object"])},
      "PrepareSelect2WithDataDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can create the Select2 object with the elements you specify"])},
      "PrepareDatePickerDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can create a Devexpress date (date, datetime, time) picker"])},
      "PrepareSelect2Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can create Select2 object"])},
      "PrepareNumberInputDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can create a number input box"])},
      "PreparePhoneInputDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can create a phone number box"])},
      "UpdateRecordDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performs record update operation"])},
      "CreateRecordDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performs record insert operation"])},
      "GetRelationalRecordsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performs a list of related records linked to a record"])},
      "GetRecordDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performs retrieval of registration details"])},
      "GetRecordsCountDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returns the count of records"])},
      "GetRecordsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performs the extraction of records as a list"])},
      "DeleteRecordDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performs registration deletion"])},
      "GetBlobImageUrlDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We can get the image in the system as a blob. When the blob taken from here is given to the img src section, the image is previewed"])},
      "CreateMapViewDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can draw a map view to specific Latitude and Longitude values"])}
    },
    "LanguageFlagSelectBox": {
      "canada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada"])},
      "chile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chile"])},
      "china": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China"])},
      "colombia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colombia"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United Kingdom"])},
      "japan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japan"])},
      "peru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peru"])},
      "singapore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapore"])},
      "spain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spain"])},
      "tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkey"])},
      "united-states": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United States"])},
      "france": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["France"])}
    },
    "QueryBuilder": {
      "CriteriaNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "CriteriaField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field"])},
      "CriteriaOperator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator"])},
      "CriteriaValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
      "CriteriaExpression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "NoCriteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven't added any criteria yet"])},
      "AsFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula"])}
    },
    "CronGenerator": {
      "Minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minute"])},
      "Hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hourly"])},
      "Daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
      "Weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
      "Monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
      "Yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yearly"])},
      "Every": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every"])},
      "StartsAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starts At"])},
      "EveryWeekDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every Week Day"])},
      "StartTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Time"])},
      "Monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
      "Tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
      "Wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
      "Thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
      "Friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
      "Saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
      "Sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
      "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
      "Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
      "First": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First"])},
      "Second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second"])},
      "Third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third"])},
      "Fourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fourth"])},
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Cron Expression"])},
      "January": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
      "February": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
      "March": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
      "April": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
      "May": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
      "June": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
      "July": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
      "August": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
      "September": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
      "October": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
      "November": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
      "December": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])},
      "TimeTriggerExpression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Trigger Expression"])},
      "Hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hour"])},
      "Generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
      "CronTabFieldsValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some fields of your cron expression is missing! Please check."])}
    },
    "CheckInternetConnection": {
      "YouAreOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are offline"])},
      "BackOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back online"])}
    },
    "DeleteModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Warning"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the recording?"])},
      "DeleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "DeleteClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "IConfirmDeletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Confirm Deletion"])}
    },
    "404": {
      "GotoDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Dashboard"])},
      "GotoCustomPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Custom Page"])},
      "Oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops!"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It seems that the page you are looking for no longer exists."])}
    },
    "PermissionNone": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission None"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have permission to access this page"])}
    },
    "PageUnderConstruction": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Under Construction"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This page is not yet available for use."])}
    },
    "IconPicker": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon Picker"])},
      "AllIcons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Icons"])},
      "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by name..."])},
      "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
      "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])}
    },
    "BlogList": {
      "ReadDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read Detail"])},
      "WhatIsSetXRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is SetXRM and Advantages of SetXRM"])},
      "SetXRMWorkflowManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetXRM Workflow Management"])},
      "WhatIsDashboardReporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is Dashboard Reporting? What are its features?"])},
      "WhatIsCustomPageReporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is Custom Page Reporting? What are its features?"])},
      "AllOurArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Our Articles"])}
    },
    "JsonViewerModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Json Viewer"])},
      "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
    },
    "BpmModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bpm Designer"])}
    },
    "PermissionAlertModal": {
      "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure that unsaved data will be lost?"])}
    },
    "FormulaEditorModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Editor"])},
      "OpenFormulaEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Formula Editor"])},
      "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields"])},
      "Functions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Functions"])},
      "Operators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operators"])},
      "DateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and Time"])},
      "Logical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logical"])},
      "Custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])},
      "Math": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Math"])},
      "String": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["String"])},
      "Spatial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spatial"])},
      "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
      "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "SearchField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search field..."])},
      "SearchFunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search function..."])},
      "SearchOperator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search operator..."])},
      "ActiveThemeFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor theme is set to <a class='THEME_ID border-dark border me-1 ms-1'></a>"])}
    },
    "FieldDiagramModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagram"])}
    },
    "PageDesignerModal": {
      "ModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Designer"])},
      "DesignItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design Items"])},
      "CustomObjectItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object Items"])},
      "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for field, object relation or button..."])},
      "Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields"])},
      "ObjectRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object Relations"])},
      "Buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buttons"])},
      "CustomObjectItemNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object items not found."])},
      "Preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
      "SaveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Changes"])},
      "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "EditItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Item"])},
      "Field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field"])},
      "ObjectRelation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object Relation"])},
      "Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Button"])},
      "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])},
      "FieldType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Type"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "ColumnRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column Range"])},
      "IsItTableSheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is it a Table Sheet?"])},
      "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "Class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
      "Icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon"])},
      "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
      "Color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
      "Collapsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapsed"])},
      "Size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
      "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "Justified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justified"])},
      "IsNextStepRequiredFieldValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Next Step Required Field Validation"])},
      "ChildItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child Items"])},
      "FontColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Font Color"])},
      "TableTitleFontColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title Font Color"])},
      "TableFontColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table Font Color"])},
      "LinearGradient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linear Gradient"])},
      "ResetLinearGradient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Linear Gradient"])},
      "IsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Required?"])},
      "IsDisabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Disabled?"])},
      "IsHidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Hidden?"])},
      "SeeDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See the Document"])},
      "ColumRangeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you set it to '0' it will be free across the other columns."])},
      "ColorDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set your color of the item."])},
      "ContainerDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set your viewport of container."])},
      "ColumnDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set your viewport of columns."])},
      "HowItWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it work"])},
      "GridOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grid Options"])},
      "BorderDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set the class of the border. For example: rounded border-5"])},
      "IconDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please copy-paste an icon value from"])},
      "Icons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icons"])},
      "SetYourUtilitiesOfTheItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set your utilities of the item."])},
      "LabelClassNames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label Class Names"])},
      "ValueClassNames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value Class Names"])},
      "AlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A simple primary alert - check it out!"])},
      "DeleteMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELEMENT contains ELEMENT_COUNT children. Are you sure?"])},
      "Element": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Element"])},
      "DesignArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design Area"])},
      "CustomClasses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Classes"])}
    },
    "BusinessRuleActionViewModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Rule Actions"])},
      "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
    },
    "NotificationSettingTestModal": {
      "EmailTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Test Email"])},
      "EmailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
      "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
      "SMSTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Test SMS"])},
      "PhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
      "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
    },
    "NotificationSMSBalanceModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS Balance"])},
      "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
    },
    "JsonPasteModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Json Paste Modal"])},
      "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "Warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current data of the record will be replaced with the pasted data after save."])}
    },
    "TranslationModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi Language Support Translation Model"])},
      "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "TranslationKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation Key"])},
      "TranslationKeyType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation Key Type"])},
      "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
      "TranslationValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation Value"])},
      "PluralNameTranslationValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plural Name Translation Value"])}
    },
    "PasswordChangeModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
      "Warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will update the password of user <b class='badge badge-warning text-capitalize'><i class='bi bi-person-fill'></i> USER_NAME</b>. Are you sure?"])}
    },
    "AreYouSureModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure"])},
      "BulkImportCancelTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "BulkImportCancelWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will the bulk import cancel. Are you sure?"])},
      "UndoDeleteTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undo Delete"])},
      "UndoDeleteWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will undo user delete. Are you sure?"])},
      "ClearCompanyCacheTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear Company Cache"])},
      "ResetCompanyCacheTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Company Cache"])},
      "CompanyClearCacheWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will clear company cache. Are you sure?"])},
      "CompanyResetCacheWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will reset company cache. Are you sure?"])},
      "IConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Confirm "])},
      "ResetButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
      "ClearButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
      "CancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "UndoDeleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undo Delete"])},
      "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
    },
    "FunctionDebugModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debug"])},
      "Execute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execute"])},
      "Output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Output"])},
      "Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
    },
    "LostWave": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, something has gone wrong"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're unable to fulfill your request. Rest assured we have been notified and are looking into the issue. Please refresh your browser. If the error continues, please contact our support team."])},
      "DescriptionWithInternalErrorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We cannot fulfill your request. <br>You can contact our support team by specifying the error code below."])}
    }
  },
  "Buttons": {
    "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "SaveAndNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save And New"])},
    "Clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
    "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "ForgotYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Your Password"])},
    "ForgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Password"])},
    "PleaseWait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait..."])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "RecordView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record View"])},
    "TryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try Again"])},
    "Refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
    "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object Name"])},
    "ReturnHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Home"])},
    "SupportTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Team"])},
    "ReturnToPreviousPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to Previous Page"])}
  },
  "Messages": {
    "SuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your process was successful."])},
    "ErrorMesage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong."])},
    "Loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your transaction is being processed, please wait."])}
  },
  "Login": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "AuthDescription1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please login with your account and proceed to the control panel."])},
    "AuthDescription2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account?"])},
    "InvalidEmailOrPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your mail address or password is invalid. <br /> Please check."])},
    "AuthenticationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication Error. <br> Please contact with system administrator."])}
  },
  "Register": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "FirstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "LastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "AuthDescription1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your credentials to create an account."])},
    "AuthDescription2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])},
    "PasswordHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password must be at least 8 characters long*"])}
  },
  "ForgotPassword": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "AuthDescription1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you do not remember your account password, you can send a password reset link and reset your password."])},
    "AuthDescription2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have an account?"])},
    "SuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your email address is registered in our system, an email containing password reset instructions will be sent to <span class='text-primary text-decoration-underline'>EMAIL_ADDRESS</span>."])}
  },
  "PasswordChange": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use 8 or more characters with a mix of letters, numbers & symbols."])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "ConfirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "PasswordDontMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords don't match!"])},
    "PasswordLengthError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password must be at least 6 characters!"])},
    "SuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password reset process has been completed successfully, you can login with your new password."])},
    "FormHiddenMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The form is automatically hidden because the usage limit of this link has been reached."])},
    "PasswordRulesMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password must contain at least 8 characters, one uppercase letter and at least one special character. Please try again."])},
    "PasswordResetNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have to change your password to login"])}
  },
  "DeletionRule": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletion Rule"])},
    "NewDeleteRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Delete Rule"])},
    "EditDeleteRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Delete Rule"])},
    "DeletionRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Rules"])},
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object"])},
    "ViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Filter"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Delete Rule"])}
  },
  "BusinessProcess": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Business Process"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Process"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Business Process"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Business Process"])}
  },
  "BusinessProcessDashboard": {
    "BusinessProcessCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Process"])},
    "BusinessRuleCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Rule"])},
    "BusinessRuleActionCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "CreateRecordCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Record"])},
    "UpdateRecordCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Record"])},
    "SendNotificationCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Notification"])},
    "SendHTTPRequestCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send HTTP Request"])},
    "SendReportCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Report"])},
    "SendXtraReportCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Xtra Report"])},
    "SendDashboardCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Dashboard"])},
    "BusinessRuleStatistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Rule Statistics"])},
    "LastFailedJobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last <b class='text-danger'>Failed</b> Jobs"])},
    "DeletionPeriodDaysForSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<i class='bi bi-info-circle-fill'></i> Deletion Period of Successful Jobs: NUMBER days"])}
  },
  "BusinessProcessCustomPage": {
    "BusinessProcessCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Process"])},
    "BusinessRuleCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Rule"])},
    "BusinessRuleActionCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "CreateRecordCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Record"])},
    "UpdateRecordCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Record"])},
    "SendNotificationCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Notification"])},
    "SendHTTPRequestCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send HTTP Request"])},
    "SendReportCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Report"])},
    "SendXtraReportCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Xtra Report"])},
    "SendCustomPageCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Custom Page"])},
    "BusinessRuleStatistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Rule Statistics"])},
    "LastFailedJobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last <b class='text-danger'>Failed</b> Jobs"])},
    "DeletionPeriodDaysForSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<i class='bi bi-info-circle-fill'></i> Deletion Period of Successful Jobs: NUMBER days"])}
  },
  "BusinessRule": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Business Rule"])},
    "NewBusinessRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Business Rule"])},
    "EditBusinessRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Business Rule"])},
    "GeneralSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Settings"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "FieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Name"])},
    "Criterias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criterias"])},
    "CriteriaExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criteria Explanation"])},
    "FirstRunIntervalInSeconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Run Interval In Seconds"])},
    "RepeatIntervalInSeconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat Interval In Seconds"])},
    "RepeatCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat Count(0-unlimited)"])},
    "IsExecuteForever": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Execute Forever?"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Rules"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Business Rule"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Business Rule"])},
    "BusinessRuleTriggerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Rule Trigger"])},
    "RecordCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Created"])},
    "RecordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Updated"])},
    "RecordCreatedOrUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Created Or Updated"])},
    "RecordDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Deleted"])},
    "TimeTriggered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Triggered"])},
    "UserTriggered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Triggered"])},
    "BusinessProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Process"])},
    "CriteriaFieldsNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No field found for criteria."])},
    "DuplicateCriteriaItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are duplicate items in your criteria. Please check."])},
    "MaxCriteriaItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add a maximum of 11 criteria. Please check."])},
    "EnterValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a value"])},
    "PleaseAddTheFieldToDisplayMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add the field(s) to display and try again."])},
    "BusinessRuleActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Rule Actions"])},
    "CacheTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cache"])},
    "CacheDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Page Shows Cache Data For <b class='badge badge-warning'><i class='bi bi-box'></i> CUSTOMOBJECT_NAME</b> <br>User If you cannot see BusinessRules, please click Reload Button. <br> If there is a problem with data, save related records again and wait one minute. <br>  If problem still occurs, contact with your <u>consultant.</u>"])},
    "CacheReload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cache Data Reload"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "Validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation"])},
    "IsNotShowResultToClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result Does Not Show To Client"])}
  },
  "BusinessRuleActions": {
    "BusinessRuleActionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Rule Action Type"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Business Rule Action"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Business Rule Action"])},
    "NewBusinessRuleAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Business Rule Action"])},
    "EditBusinessRuleAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Business Rule Action"])},
    "BusinessAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Action"])},
    "BusinessActionObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Action Object"])},
    "BusinessActionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Action Type"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "IsForcingSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore Data Validation Rules"])},
    "IsRunOnceForEachRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Run Once For Each Record"])},
    "IsActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "CreateRecordRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Record Request"])},
    "CreateRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Record"])},
    "UpdateRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Record"])},
    "SendHTTPRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send HTTP Request"])},
    "SendNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Notification"])},
    "SendDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Dashboard"])},
    "SendCustomPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Custom Page"])},
    "SendReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Report"])},
    "SendXtraReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Xtra Report"])},
    "NewActionCreateRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Action Create Record"])},
    "EditActionCreateRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Action Create Record"])},
    "SelectObjectYouWantToCreateRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the Object You Want to Create a Record"])},
    "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "SelectObjectYouWantToUpdateRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the Object You Want to Update a Record"])},
    "SelectRelatedLookupField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the Related Lookup Field"])},
    "NotificationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Type"])},
    "MessageTemplateType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template Type"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "SMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
    "SystemNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Notification"])},
    "NotificationSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Settings"])},
    "SelectMessageTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Message Template"])},
    "SendByEmailOfUserWhoUpdatesRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send by Email of the User Who Updates the Registration?"])},
    "Subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "SelectTemplateForNotificationPlugin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Template for Notification Plugin"])},
    "SelectAttachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Attachment"])},
    "ToWho": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Who"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CC"])},
    "BCC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCC"])},
    "SourceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source Type"])},
    "FromTheText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From the Text"])},
    "FromTheOrganizationUnitsOfTheCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From the Organization Units of the Company"])},
    "FromTheFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From the Fields"])},
    "Source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
    "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "UserGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Group"])},
    "PermissionGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Group"])},
    "Method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method"])},
    "Get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get"])},
    "Post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
    "Put": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Put"])},
    "HTTPRequestAdress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTTP Request Adress"])},
    "HTTPHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTTPHeader"])},
    "HTTPRequestContentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTTP Request Content Type"])},
    "AllRecordAsDto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AllRecordAsDto"])},
    "SelectedRecordValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SelectedRecordValues"])},
    "Custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])},
    "HTTPContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTTP Content"])},
    "DoNotTriggerBusinessRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't Trigger Business Rule"])},
    "DoNotCalculateFormulaFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't Calculate Formula Fields"])},
    "DoNotCalculateRollupFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't Calculate Rollup Fields"])},
    "IsResponseReturnToClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Response Return To Client"])},
    "IsSynchAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Run Action Synchronously"])},
    "Related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related"])},
    "FirstManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Manager"])},
    "SecondManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Manager"])},
    "ThirdManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Manager"])},
    "Teammate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teammate"])},
    "IsRunSeparatelyForEachUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Run Separately For Each User"])},
    "IsNotSendEmailIfThereIsNoRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Not Send Email If There Is No Record"])},
    "EmailAdressInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Adress Information"])},
    "DashboardId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard Id"])},
    "CustomPageId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Page Id"])},
    "ReportExportType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Export Type"])},
    "XtraReportId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xtra Report Id"])},
    "ViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Filter"])},
    "GetOtherFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Other Fields"])},
    "ResponseText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response Text"])},
    "DownloadResponseAsDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DownloadResponseAsDocument"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])}
  },
  "BusinessRuleTriggerTypes": {
    "RecordCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Created"])},
    "RecordCreatedOrUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Created Or Updated"])},
    "RecordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Updated"])},
    "RecordDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Deleted"])},
    "TimeTriggered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Triggered"])},
    "UserTriggered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Triggered"])}
  },
  "ReportExportTypes": {
    "Pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pdf"])},
    "Excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel"])},
    "Word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Word"])},
    "Csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csv"])},
    "ExcelXLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel(xls)"])}
  },
  "SetCodeFunctions": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New SetCode Function"])},
    "EditButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit SetCode Function"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetCode Functions"])},
    "New": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New SetCode Function"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit SetCode Function"])},
    "FunctionCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Function Code"])},
    "FunctionDefinition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Function Definition"])},
    "OutputModelText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OutputModel"])},
    "InputModelText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["InputModel"])},
    "InputModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" model"])},
    "ClearSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear Selection"])},
    "OutputModelCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Output Model Code"])},
    "InputModelCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input Model Code"])},
    "RecordIsAvailableOnTheSystemMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This record is a record that already exists in the system. The update process will be reflected immediately."])},
    "RedirectUpdatePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Model in Update Page"])},
    "ModelUpdateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model updated successfully"])},
    "ExecutableFunctionCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executable Function Code"])}
  },
  "SetModel": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New SetCode Model"])},
    "EditButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit SetCode Model"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetCode Models"])},
    "New": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New SetCode Model"])},
    "ModelCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model Code"])},
    "PickInputModelForFunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick Input Model For Function"])},
    "PickOutputModelForFunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick Output Model For Function"])},
    "PickFromCurrentModels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick From Current Models"])},
    "CreateNewModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New Model"])},
    "SaveAsCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save As Code"])},
    "EditInputModelForFunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Input Model For Function"])},
    "EditOutputModelForFunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Output Model For Function"])}
  },
  "SuperAdmin": {
    "UserId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Id"])},
    "FirstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "LastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "CompanyId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Id"])},
    "CompanyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "Project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
    "UserSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Search"])}
  },
  "Localizations": {
    "Keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keys"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Key"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Key"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Key"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])},
    "KeyType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key Type"])},
    "ParentPublicId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent Public Id"])}
  },
  "LocalizationKeyTypes": {
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object Name"])},
    "CustomObjectPluralName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Object Plural Name"])},
    "Field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field"])},
    "ViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Filter"])},
    "BusinessRuleButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Rule Button"])},
    "PageLayoutPanel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Layout Panel"])},
    "Menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "MenuItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu Item"])},
    "SelectListItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select List Item"])},
    "ObjectRelation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object Relation"])},
    "Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Button"])},
    "FormTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Template"])},
    "CalendarView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar View"])},
    "TreeView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tree View"])},
    "KanbanView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanban View"])},
    "MapView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map View"])}
  },
  "LocalizationLanguages": {
    "Languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Language"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Language"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Language"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "LanguageIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LanguageIcon"])},
    "LanguageCulture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LanguageCulture"])},
    "IsActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])}
  },
  "PageLayoutTemplates": {
    "NewPageLayoutTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Page Layout Template"])},
    "EditPageLayoutTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Page Layout Template"])},
    "ListPageLayoutTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Layout Templates"])}
  },
  "SetCodeDatabases": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetCode Database Connections"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New SetCode Database Connection"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New SetCode Database Connection"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit SetCode Database Connection"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "DbType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "InstanceIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ip Address"])},
    "SchemaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schema Name"])},
    "CnnStringWrite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection String"])},
    "CnnStringRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection String Read"])},
    "Postgresql": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postgresql"])},
    "Sql": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MsSql"])}
  },
  "SetCodeModels": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetCode Models"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New SetCode Model"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New SetCode Model"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit SetCode Model"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "ModelCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model Code"])}
  },
  "CompanyInformation": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Information"])},
    "DetailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Information"])},
    "GeneralDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Details"])},
    "GlobalScripts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Html & Css & Html"])},
    "CompanyId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Id"])},
    "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Name"])},
    "CompanyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])},
    "CompanyAdminEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Admin Email"])},
    "TaxOffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Office"])},
    "TaxNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Number"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "County": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["County"])},
    "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "Fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
    "ContactPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person"])},
    "ContactPersonEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person Email"])},
    "ContactPersonTel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person Phone"])},
    "IsIpControl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Ip Control"])},
    "AcceptIpAddresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept Ip Addresses"])},
    "GoogleMapsApiKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Maps Api Key"])},
    "HTML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML"])},
    "CSS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSS"])},
    "Script": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Script"])}
  },
  "HttpConnections": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetCode HTTP Connections"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New SetCode HTTP Connection"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTTP Connection Definition"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit HTTP Connection"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])},
    "HTTPHeaders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTTP Headers"])},
    "Json": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JSON"])},
    "Xml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XML"])},
    "WarningMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To add a new Http Header, first fill in the fields."])},
    "BodyParameterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Body Parameter Name"])},
    "ReturnType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Type"])},
    "SystemVariables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Variables"])},
    "CustomModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Model"])}
  },
  "Relations": {
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formula Name"])},
    "isDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
    "Rollup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollup"])},
    "Lookup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lookup"])},
    "ObjectRelation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object Relation"])},
    "ViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Filter"])}
  }
}