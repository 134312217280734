import { appAxios } from "@/core/axios/appAxios";
import { prodGatewayAxios } from "@/core/axios/prodGatewayAxios";
import { brsAxios } from "@/core/axios/brsAxios";
import { authAxios } from "@/core/axios/authAxios";

import moment from "moment/moment";
import { createToast } from "mosha-vue-toastify";

var refreshTokenRequestSent = false,
  authPages = [
    "/RefreshToken",
    "/Authenticate",
    "/PasswordReset",
    "/PasswordChange",
  ];

function isAuthPage(configUrl) {
  return authPages.includes(configUrl);
}

function isTokenExpired(config, store) {
  if (isAuthPage(config.url)) return false;

  var currentDate = moment(new Date()).format(),
    tokenExpirationDate = moment(
      // "2023-07-06T14:50:15Z",
      localStorage.getItem("tokenExpirationDate"),
      "YYYY-MM-DDTHH:mm:ss"
    ).format();

  return currentDate >= tokenExpirationDate;
}

async function refreshToken(store, router) {
  refreshTokenRequestSent = true;
  await authAxios
    .post("/RefreshToken", {
      accessToken: localStorage.getItem("token"),
      refreshToken: localStorage.getItem("refreshToken"),
      authRequestFromTypeId: 2,
    })
    .then((response) => {
      var result = response.data;
      if (result.isOk) {
        store.commit("setToken", {
          accessToken: result.accessToken,
          refreshToken: result.refreshToken,
          accessTokenExpiration: result.accessTokenExpiration,
        });
        refreshCurrentUser(store, router);
      } else {
        let path = router.currentRoute.value.fullPath,
          query = {};
        if (!router.currentRoute.value.meta.isHelpPage) {
          query = {
            redirect: path,
          };
        }
        router.push({
          path: "/sign-in",
          query: query,
        });
        store.commit("signOutUser");
      }
    })
    .finally(() => {
      refreshTokenRequestSent = false;
    })
    .catch((err) => {
      router.push({
        path: "/sign-in",
      });
      store.commit("signOutUser");
    });
}

function refreshCurrentUser(store, router) {
  prodGatewayAxios.get("/Lcdp-GetUserCacheValue").then((response) => {
    var result = response.data;
    if (result.isOk) {
      var item = result.item;

      store.commit("setUserInfo", item);
    } else {
      router.push({
        path: "/sign-in",
      });
      store.commit("signOutUser");
    }
  });
}

const setup = (store, router) => {
  //use request
  authAxios.interceptors.request.use(
    (config) => {
      if (Object.readCookie("CurrentLocation")) {
        config.headers["X-Current-Location"] =
          Object.readCookie("CurrentLocation");
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  appAxios.interceptors.request.use(
    (config) => {
      if (localStorage.getItem("token")) {
        config.headers["Authorization"] =
          "Bearer " + localStorage.getItem("token");
      }

      if (Object.readCookie("CurrentLocation")) {
        config.headers["X-Current-Location"] =
          Object.readCookie("CurrentLocation");
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  brsAxios.interceptors.request.use(
    (config) => {
      if (localStorage.getItem("token")) {
        config.headers["Authorization"] =
          "Bearer " + localStorage.getItem("token");
      }

      if (Object.readCookie("CurrentLocation")) {
        config.headers["X-Current-Location"] =
          Object.readCookie("CurrentLocation");
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  prodGatewayAxios.interceptors.request.use(
    async (config) => {
      var tokenExpired = isTokenExpired(config, store);
      if (refreshTokenRequestSent || tokenExpired) {
        //refreshing token
        if (!refreshTokenRequestSent && tokenExpired) {
          refreshToken(store, router);
        }

        return Promise.reject({ config: config, _retryRequest: true });
      }

      var isAuthPages = isAuthPage(config.url);
      if (!tokenExpired) {
        if (Object.readCookie("CurrentLocation")) {
          config.headers["X-Current-Location"] =
            Object.readCookie("CurrentLocation");
        }

        if (!String.isNullOrWhiteSpace(store.getters._currentUserId)) {
          config.headers["Authorization"] =
            "Bearer " + localStorage.getItem("token");
        }
      }

      if (
        String.isNullOrWhiteSpace(localStorage.getItem("token")) &&
        !isAuthPages
      ) {
        return Promise.reject({ config: config, loginRedirect: true });
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  prodGatewayAxios.interceptors.response.use(
    (response) => {
      if (!response.data.isOk) {
        if (
          response.data.msg ===
          process.env.VUE_APP_SYSTEM_CUSTOM_OBJECT_NOT_VALID_KEY
        ) {
          router.push({
            path: "/",
          });
        } else if (
          response.data.msg ===
          process.env.VUE_APP_SYSTEM_CUSTOM_OBJECT_NOT_FOUND_KEY
        ) {
          router.push({
            path: "/CustomObject/List",
          });
        } else if (
          response.data.msg === process.env.VUE_APP_SYSTEM_PERMISSION_NONE_KEY
        ) {
          router.push({
            path: "/PermissionNone",
          });
        } else if (
          response.data.msg === process.env.VUE_APP_USER_DATA_NOT_FOUND_KEY
        ) {
          router.push({
            path: "/User/List",
          });
        }
      }
      return response;
    },
    async (err) => {
      var config = err.config,
        response = err.response;

      if (err._retryRequest) {
        const delayRetryRequest = new Promise((resolve) => {
          setTimeout(() => {
            // console.log("retry the request", config.url);
            resolve();
          }, 4000);
        });
        return delayRetryRequest.then(() => prodGatewayAxios(config));
      }

      if (err.loginRedirect) {
        if (router.currentRoute.value.meta.isSignInPage) {
          return Promise.reject(err);
        }

        let path = router.currentRoute.value.fullPath,
          query = {};
        if (!router.currentRoute.value.meta.isHelpPage) {
          query = { redirect: path };
        }
        router.push({
          path: "/sign-in",
          query: query,
        });
        store.commit("signOutUser");
        return Promise.reject(err);
      }

      if (response) {
        if (response.status === 401) {
          let path = router.currentRoute.value.fullPath,
            query = {};
          if (!router.currentRoute.value.meta.isHelpPage) {
            query = { redirect: path };
          }
          router.push({
            path: "/sign-in",
            query: query,
          });
          store.commit("signOutUser");
        } else if (response.status === 555) {
          var responseMessage = response.data.msg;
          // if ([].includes(config.url.split("?")[0])) {
          //   if (!String.isNullOrWhiteSpace(responseMessage)) {
          //     createToast(responseMessage, {
          //       showIcon: true,
          //       position: "top-right",
          //       type: "danger",
          //       transition: "zoom",
          //     });
          //   }
          // } else
          if (
            !String.isNullOrWhiteSpace(responseMessage) &&
            responseMessage.includes(
              process.env.VUE_APP_SYSTEM_INTERNAL_ERROR_KEY
            )
          ) {
            store.commit("setInternalErrorDescription", responseMessage);
            router.push({
              name: "InternalError",
            });
          }
        }
        return Promise.reject(err);
      }
    }
  );

  appAxios.interceptors.response.use(
    (response) => {
      if (!response.data.isOk) {
        if (
          response.data.msg ===
          process.env.VUE_APP_SYSTEM_CUSTOM_OBJECT_NOT_VALID_KEY
        ) {
          router.push({
            path: "/",
          });
        } else if (
          response.data.msg ===
          process.env.VUE_APP_SYSTEM_CUSTOM_OBJECT_NOT_FOUND_KEY
        ) {
          router.push({
            path: "/CustomObject/List",
          });
        } else if (
          response.data.msg === process.env.VUE_APP_SYSTEM_PERMISSION_NONE_KEY
        ) {
          router.push({
            path: "/PermissionNone",
          });
        } else if (
          response.data.msg === process.env.VUE_APP_USER_DATA_NOT_FOUND_KEY
        ) {
          router.push({
            path: "/User/List",
          });
        }
      }
      return response;
    },
    async (err) => {
      let path = router.currentRoute.value.fullPath;
      if (router.currentRoute.value.meta.isHelpPage) {
        path = "/";
      }
      router.push({
        path: "/sign-in",
        query: { redirect: path },
      });
      if (err.response.status === 401) {
        store.commit("signOutUser");
      } else if (err.response.status === 404) {
        router.push("/");
      }

      return Promise.reject(err);
    }
  );

  brsAxios.interceptors.response.use(
    (response) => {
      if (!response.data.isOk) {
        if (
          response.data.msg ===
          process.env.VUE_APP_SYSTEM_CUSTOM_OBJECT_NOT_VALID_KEY
        ) {
          router.push({
            path: "/",
          });
        } else if (
          response.data.msg ===
          process.env.VUE_APP_SYSTEM_CUSTOM_OBJECT_NOT_FOUND_KEY
        ) {
          router.push({
            path: "/CustomObject/List",
          });
        } else if (
          response.data.msg === process.env.VUE_APP_SYSTEM_PERMISSION_NONE_KEY
        ) {
          router.push({
            path: "/PermissionNone",
          });
        } else if (
          response.data.msg === process.env.VUE_APP_USER_DATA_NOT_FOUND_KEY
        ) {
          router.push({
            path: "/User/List",
          });
        }
      }
      return response;
    },
    async (err) => {
      let path = router.currentRoute.value.fullPath;
      if (router.currentRoute.value.meta.isHelpPage) {
        path = "/";
      }
      router.push({
        path: "/sign-in",
        query: { redirect: path },
      });
      if (err.response.status === 401) {
        store.commit("signOutUser");
      } else if (err.response.status === 404) {
        router.push("/");
      }

      return Promise.reject(err);
    }
  );
};

export default setup;
