import $ from "jquery";

String.format = function () {
  var s = arguments[0];
  for (var i = 0; i < arguments.length - 1; i++) {
    var reg = new RegExp("\\{" + i + "\\}", "gm");
    s = s.replace(reg, arguments[i + 1]);
  }
  return s;
};

String.SetAttributes = function (el, attrs) {
  Object.keys(attrs).forEach((key) => el.setAttribute(key, attrs[key]));
};

String.isNullOrWhiteSpace = function (str) {
  if (typeof str === "undefined") return true;
  if (str == null) return true;
  var result = str.toString().replace(/\s/gi, "").length < 1;
  return result;
};

String.replaceAll = function (find, replace) {
  var str = this;
  return str.replace(
    new RegExp(find.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&"), "g"),
    replace
  );
};

String.replaceAlphanumeric = function (str, newChar) {
  if (String.isNullOrWhiteSpace(str)) return "";
  if (String.isNullOrWhiteSpace(newChar)) newChar = "_";

  var searchAndReplaceStringKeys = [
    {
      search: "ü",
      replace: "u",
    },
    {
      search: "ğ",
      replace: "g",
    },
    {
      search: "ı",
      replace: "i",
    },
    {
      search: "ş",
      replace: "s",
    },
    {
      search: "ç",
      replace: "c",
    },
    {
      search: "ö",
      replace: "o",
    },
    {
      search: "Ü",
      replace: "U",
    },
    {
      search: "Ğ",
      replace: "G",
    },
    {
      search: "İ",
      replace: "I",
    },
    {
      search: "Ş",
      replace: "S",
    },
    {
      search: "Ç",
      replace: "C",
    },
    {
      search: "Ö",
      replace: "O",
    },
    {
      search: "0",
      replace: newChar,
    },
    {
      search: "1",
      replace: newChar,
    },
    {
      search: "2",
      replace: newChar,
    },
    {
      search: "3",
      replace: newChar,
    },
    {
      search: "4",
      replace: newChar,
    },
    {
      search: "5",
      replace: newChar,
    },
    {
      search: "6",
      replace: newChar,
    },
    {
      search: "7",
      replace: newChar,
    },
    {
      search: "8",
      replace: newChar,
    },
    {
      search: "9",
      replace: newChar,
    },
    {
      search: /\W+/g,
      replace: newChar,
    },
  ];
  searchAndReplaceStringKeys.forEach((item) => {
    str = str.replaceAll(item.search, item.replace);
  });

  if (!String.isNullOrWhiteSpace(str)) {
    var splitedStr = str.split(newChar),
      notNullStrItems = splitedStr.filter((f) => !String.isNullOrWhiteSpace(f));
    if (notNullStrItems.length == 0) {
      return "";
    } else {
      return notNullStrItems.join(newChar);
    }
  }

  return str;
};

String.newGuid = function () {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

String.isGuid = function (value) {
  var regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  var match = regex.exec(value);
  return match != null;
};

String.createGetUrl = function (action, requestParameters) {
  if (String.isNullOrWhiteSpace(action)) return null;

  var searchUrl = action;
  if (requestParameters.length > 0) {
    requestParameters.forEach((element) => {
      var separator = searchUrl.includes("?") ? "&" : "?";
      searchUrl += `${separator}${element.key}=${element.value}`;
    });
  }

  return searchUrl;
};

String.numberWithCommas = function (value, activeLanguage) {
  if (String.isNullOrWhiteSpace(value)) return;

  return value
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, activeLanguage == "en" ? "," : ".");
};

String.QueryString = function (param) {
  var href = window.location.href,
    url = href.slice(href.indexOf("?") + 1).split("&");
  for (var i = 0; i < url.length; i++) {
    var urlparam = url[i].split("=");
    if (urlparam[0] == param) {
      return urlparam[1];
    }
  }
  return "";
};

var index = 0,
  oldHeight = 0;
Object.iframeCalcHeight = function (iframeId) {
  var iframe = $(String.format("#{0}", iframeId)),
    src = iframe.attr("src");
  if (!src.includes("/#/")) {
    //dış adresler
    index = 0;
    oldHeight = 0;
    document.getElementById(iframeId).height = "760px";
    return false;
  }
  iframe.contents().find("body").addClass("aside-none");
  iframe.contents().find("body").removeClass("aside-enabled");
  iframe.contents().find("body").removeClass("aside-fixed");
  iframe.contents().find("body").removeClass("toolbar-tablet-and-mobile-fixed");
  iframe.contents().find("#kt_toolbar").css("top", "0");
  iframe.contents().find("#kt_wrapper").css({
    "padding-top": "50px",
  });
  iframe.contents().find("#kt_aside, #kt_header, #kt_footer").remove();

  var the_height =
    document.getElementById(iframeId).contentWindow.document.body.scrollHeight;
  if (the_height != oldHeight) {
    oldHeight = the_height;
    document.getElementById(iframeId).height = the_height + "px";
    return true;
  } else {
    index++;
    if (index >= 3) {
      index = 0;
      oldHeight = 0;
      return false;
    } else {
      return true;
    }
  }
};

Object.setCookie = function (key, value, expiry) {
  var expires = new Date();
  // expires.setTime(expires.getTime() + (expiry * 24 * 60 * 60 * 1000));
  expires.setTime(expires.getTime() + expiry * 15 * 60 * 1000);
  document.cookie =
    key + "=" + value + ";expires=" + expires.toUTCString() + ";secure=true";
};

Object.readCookie = function (name) {
  var nameEq = encodeURIComponent(name) + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEq) === 0)
      return decodeURIComponent(c.substring(nameEq.length, c.length));
  }
  return null;
};

Object.base64ToBlob = function (base64) {
  var base64String = base64.split(","),
    sliceSize = 1024,
    byteChars = window.atob(base64String[1]),
    byteArrays = [],
    mime = base64String[0].split(":")[1].split(";")[0];

  for (
    var offset = 0, len = byteChars.length;
    offset < len;
    offset += sliceSize
  ) {
    var slice = byteChars.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, {
    type: mime,
  });
};

Object.isObject = function (obj) {
  return obj === Object(obj);
};

String.setModal = {
  // modals creator
  Create: function (params) {
    var object = {
      id: "",
      globalClass: "",
      html: {
        header: "",
        body: "",
        footer: "default",
      },
      settings: {
        hasStatic: false,
        widthClass: "", // modal-lg, modal-sm, modal-full-width
        maxHeight: $(window).height() - 60, // page height
        close: true,
      },
    };

    var $set = $.extend(true, object, params);

    if ($set.id != "") {
      $set.id = 'id="' + $set.id + '"';
    }

    if ($set.html.footer.length && $set.html.footer == "default") {
      $set.html.footer =
        '<button type="button" class="btn btn-default" data-dismiss="modal">' +
        window.lblCancel +
        "</button>";
    }

    var modal = [
      "<div " +
        $set.id +
        ' class="modal ' +
        ($set.settings.hasStatic ? "modal-static" : "") +
        ' fade" tabindex="-1" role="dialog" aria-hidden="true">',
      '<div class="modal-dialog ' + $set.settings.widthClass + '">',
      '<div class="modal-content">',
      $set.html.header.length
        ? '<div class="modal-header">' +
          ($set.settings.close
            ? '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>'
            : "") +
          '<h4 class="modal-title">' +
          $set.html.header +
          "</h4></div>"
        : "",

      '<div class="modal-body" style="overflow-x: auto; max-height: ' +
        $set.settings.maxHeight +
        'px;">',
      $set.html.body,
      "</div>",

      $set.html.footer.length
        ? '<div class="modal-footer">' + $set.html.footer + "</div>"
        : "",
      "</div>",
      "</div>",
      "</div>",
    ].join("");

    $("body").append(modal);
  },
  // modals remover
  Remove: function () {},
};

String.setUtil = {
  alert: function (params) {
    var options = {
      container: "body",
      message: "",
      alertClass: "alert-danger", //alert-success, alert-info, alert-warning, alert-danger,
      autoClose: true,
    };

    var $set = $.extend(true, options, params);

    var alert = $("<div/>", {
      class: "alert alert-dismissible",
      role: "alert",
    })
      .append(
        $("<button/>", {
          type: "button",
          class: "close",
          "data-dismiss": "alert",
          "aria-label": "Close",
        }).html('<span aria-hidden="true">&times;</span>')
      )
      .append(params.message);

    if ($set.autoClose) {
      alert.fadeTo(2000, 1000).slideUp(1000, function () {
        alert.alert("close");
      });
    }

    alert.addClass($set.alertClass);

    $($set.container).append(alert);
  },
  confirm: function (params) {
    var options = {
      title: window.confirmTitle,
      message: "",
      lblCancel: window.lblCancel,
      lblOk: window.lblOk,
    };

    var $set = $.extend(true, options, params);

    window.setModal.Create({
      id: "confirm-modal",
      html: {
        header: $set.title,
        body: $set.message,
        footer:
          '<button type="button" class="btn btn-sm btn-default" data-dismiss="modal">' +
          $set.lblCancel +
          '</button><button type="button" class="btn btn-sm btn-primary" data-dismiss="modal">' +
          $set.lblOk +
          "</button>",
      },
    });

    var modal = $("#confirm-modal");
    modal.find(".modal-footer").on("click", ".btn-primary", function () {
      params.callback(true);
    });

    modal.modal("show");

    modal.on("hidden.bs.modal", function () {
      params.callback(false);
      modal.remove();
    });
  },
  proggess: function (params) {
    var options = {
      container: "body",
    };

    var $set = $.extend(true, options, params);

    var progress = $("<div/>", {
      class: "progress",
      style: "width:100%",
    }).html(
      '<div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>'
    );

    $($set.container).append(progress);

    return progress;
  },
};

String.setDangerAlertMessage = function (msg) {
  return String.format(
    '<span class="text-danger"><i class="fas fa-exclamation-circle"></i> {0}</span>',
    msg
  );
};

String.setSuccessAlertMessage = function (msg) {
  return String.format(
    '<span class="text-success"><i class="far fa-check-circle"></i> {0}</span>',
    msg
  );
};

Number.isFloat = function (n) {
  return Number(n) === n && n % 1 !== 0;
};

Number.isInt = function (n) {
  return Number(n) === n && n % 1 === 0;
};

Number.sum = function (array) {
  var total = 0;

  if (!array || (array && array.length == 0)) return total;

  array.forEach((item) => {
    if (String.isNullOrWhiteSpace(item)) item = 0;

    total += Number(item);
  });

  return total;
};

Boolean.toBool = function (obj) {
  if (obj == undefined) return false;
  if (!obj.length) return false;
  return JSON.parse(obj.toString().toLowerCase());
};
