<template>
  <router-view />
  <input
    type="hidden"
    class="hidden"
    id="CurrentLocation"
    name="CurrentLocation"
    :value="Object.readCookie('CurrentLocation')"
  />
</template>
<style>
@import "../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-vue-dropdowns/styles/bootstrap5.css";
@import "~sweetalert2/dist/sweetalert2.css";
</style>
<script>
import intlTelInput from "intl-tel-input";
import Inputmask from "inputmask";

//only list box component used
import { setCulture } from "@syncfusion/ej2-base";

setCulture(
  localStorage.getItem("activeLang") || process.env.VUE_APP_I18N_LOCALE || "tr"
);

import { createToast } from "mosha-vue-toastify";
import $ from "jquery";

//devexpress localization
import trMessages from "devextreme/localization/messages/tr.json";
import { locale, loadMessages } from "devextreme/localization";

export default {
  name: "App",
  data() {
    return {
      ace: {
        commands: {
          fullScreen: {
            name: "Toggle Fullscreen",
            bindKey: { win: "Esc", mac: "Esc" },
            exec: function (editor) {
              $("body").toggleClass("fullScreen");
              $(editor.container).toggleClass("fullScreen");
              editor.setAutoScrollEditorIntoView(!false);
              editor.resize();
            },
          },
        },
      },

      /**
       * Bootswatch Helper
       * @param folder: src\assets\CREATED_FOLDER_URL !!!
       * @param file Path: ${bootswatch.folder}/${file}
       * @param image Path: ${bootswatch.folder}/${items !!! CurrentThemeId}/${bootswatch.image}
       * @param defaultTheme items !!! CurrentThemeId
       * @param items Bootswatch Themes or Custom Themes
       */
      bootswatch: {
        title: "bootswatchThemes",
        folder: "/extras/themes/",
        file: "bootstrap.min.css",
        image: "thumbnail.png",
        defaultTheme: "zephyr",
        items: [
          { id: "cerulean", name: "Cerulean", description: null },
          { id: "cosmo", name: "Cosmo", description: null },
          { id: "cyborg", name: "Cyborg", description: null },
          { id: "darkly", name: "Darkly", description: null },
          { id: "flatly", name: "Flatly", description: null },
          { id: "journal", name: "Journal", description: null },
          { id: "litera", name: "Litera", description: null },
          { id: "lumen", name: "Lumen", description: null },
          { id: "lux", name: "Lux", description: null },
          { id: "materia", name: "Meteria", description: null },
          { id: "minty", name: "minty", description: null },
          { id: "morph", name: "Morph", description: null },
          { id: "pulse", name: "Pulse", description: null },
          { id: "quartz", name: "Quartz", description: null },
          {
            id: "sandstone",
            name: "Sandstone",
            description: null,
          },
          { id: "simplex", name: "Simplex", description: null },
          { id: "sketchy", name: "Sketchy", description: null },
          { id: "slate", name: "Slate", description: null },
          { id: "solar", name: "Solar", description: null },
          { id: "spacelab", name: "Spacelab", description: null },
          {
            id: "superhero",
            name: "Superhero",
            description: null,
          },
          { id: "united", name: "United", description: null },
          { id: "vapor", name: "Vapor", description: null },
          { id: "yeti", name: "Yeti", description: null },
          {
            id: "zephyr",
            name: "Zephyr",
            description: null,
          },
        ],
      },
    };
  },
  methods: {
    getMessageByKey(key) {
      switch (key) {
        case process.env.VUE_APP_MEMBERSHIP_PASSWORD_RULE_ERROR_KEY:
          return this.$t(
            "PasswordChange.PasswordRulesMessage",
            {},
            { locale: this.$store.state.activeLang }
          );
        case process.env.VUE_APP_MEMBERSHIP_INVALID_EMAIL_OR_PASSWORD_KEY:
          return this.$t(
            "Login.InvalidEmailOrPassword",
            {},
            { locale: this.$store.state.activeLang }
          );
        case process.env.VUE_APP_MEMBERSHIP_AUTHENTICATION_ERROR_KEY:
          return this.$t(
            "Login.AuthenticationError",
            {},
            { locale: this.$store.state.activeLang }
          );
        default:
          return key;
      }
    },
    applyTheme() {
      var activeThemeName = localStorage.getItem("activeTheme"),
        bootswatch = this.bootswatch,
        bsThemes = bootswatch.items,
        defaultBsTheme = bsThemes.find((f) => f.id == bootswatch.defaultTheme),
        activeBsTheme = bsThemes.find((f) => f.id == activeThemeName);

      var bsTheme = defaultBsTheme,
        themePrefix = `${bootswatch.folder}/THEME_ID/${bootswatch.file}`,
        filePath = themePrefix.replaceAll("THEME_ID", defaultBsTheme.id),
        title = `${bootswatch.title}|${defaultBsTheme.id}`;
      if (activeBsTheme) {
        bsTheme = activeBsTheme;
        filePath = themePrefix.replaceAll("THEME_ID", activeBsTheme.id);
        title = `${bootswatch.title}|${activeBsTheme.id}`;
      }

      var self = this;
      $.get(filePath, function (data) {
        var css = data,
          head = document.head || document.getElementsByTagName("head")[0],
          style = document.createElement("style");

        style.id = title;
        style.type = "text/css";

        var oldStyles = $(`style[id*="${self.bootswatch.title}"]`);
        if (oldStyles) {
          oldStyles.each(function (i, style) {
            style.remove();
          });
        }

        head.appendChild(style);

        if (style.styleSheet) {
          // This is required for IE8 and below.
          style.styleSheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css));
        }
      });
    },
    checkSession() {
      var self = this;
      $(document).on("visibilitychange", function () {
        if (document.visibilityState == "hidden") {
          // page is hidden
        } else {
          // page is visible
          if (
            self.$store.state.userPublicId !==
            localStorage.getItem("userPublicId")
          ) {
            window.location.reload();
          }
        }
      });
    },
    encrypt(value, isEncodeURIComponent = false) {
      const secretKey = this.$cryptoJs.enc.Utf8.parse(this.$cryptoSecretKey),
        IV = this.$cryptoJs.enc.Hex.parse(this.$cryptoIvKey),
        encryptedValue = this.$cryptoJs.AES.encrypt(value, secretKey, {
          mode: this.$cryptoJs.mode.CBC,
          padding: this.$cryptoJs.pad.Pkcs7,
          iv: IV,
        }).toString();

      return isEncodeURIComponent
        ? encodeURIComponent(encryptedValue)
        : encryptedValue;
    },
    decrypt(value) {
      const bytes = this.$cryptoJs.AES.decrypt(
        value,
        this.$cryptoJs.enc.Utf8.parse(this.$cryptoSecretKey),
        {
          iv: this.$cryptoJs.enc.Hex.parse(this.$cryptoIvKey),
        }
      );

      return bytes.toString(this.$cryptoJs.enc.Utf8);
    },
    getCurrentUser(loginRedirect = false) {
      var self = this;
      this.$prodGatewayAxios
        .get("Lcdp-GetUserCacheValue")
        .then((response) => {
          var result = response.data;
          if (result.isOk) {
            this.$store.commit("setUserInfo", result.item);

            if (loginRedirect) {
              this.$router.push(this.$route.query.redirect || "/");
            }

            if (this.$store.state.isMultiLanguage) {
              this.getCompanyLanguages();
            }
          } else {
            this.signOut();
          }
        })
        .catch(function (error) {
          self.signOut();
        });
    },
    signOut() {
      this.$store.commit("signOutUser");
      localStorage.clear();
      var path = this.$route.fullPath;
      if (this.$route.meta.isHelpPage) {
        path = "/";
      }
      this.$router.push({
        path: "/sign-in",
        query: { redirect: path },
      });
    },
    changeDocumentTitle(text) {
      document.title = String.format(
        String.isNullOrWhiteSpace(text) ? "{1}" : "{0} / {1}",
        text,
        this.$t("ProductName", {}, { locale: this.$store.state.activeLang })
      );
    },
    async createKeyForCurrentUser(model) {
      var request = this.$prodGatewayAxios
        .post("/CreateKeyForCurrentUser", model)
        .then((response) => {
          var token = response.data;
          if (!String.isNullOrWhiteSpace(token)) {
            var reportDomain = process.env.VUE_APP_REPORT_LOCAL_WEB_SITE_URL;
            if (process.env.NODE_ENV !== "development") {
              if (this.$config.isCustomConfig) {
                reportDomain = this.$config.webSites.report;
              } else {
                reportDomain = process.env.VUE_APP_REPORT_WEB_SITE_URL;
              }
            }

            window.open(
              `${reportDomain}/${
                this.$setXRMReportAuthorizeActionUrl
              }?key=${encodeURIComponent(token)}`,
              "_blank"
            );
          } else {
            createToast(
              this.$t(
                "BaseModelFields.UnexpectedErrorHasOccurred",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: true,
                position: "top-right",
                type: "danger",
                transition: "zoom",
              }
            );
          }
        })
        .catch(function (error) {
          //alert(error);
        });

      return await request;
    },
    async asyncRequest(url, type = "GET") {
      if (String.isNullOrWhiteSpace(url)) return;

      if (type === "POST") {
        return await this.$prodGatewayAxios.post(url).catch(function (error) {
          //alert(error);
        });
      }
      return await this.$prodGatewayAxios.get(url).catch(function (error) {
        //alert(error);
      });
    },
    getCompanyLanguages() {
      this.$prodGatewayAxios
        .get("/Brs-GetLanguagesWithoutPermission")
        .then((response) => {
          this.$store.commit("setCompanyLanguages", response.data.items);
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    getLocalizationValueForPageDesigner(element) {
      if (element.localization && element.localization.length > 0) {
        var translationValue = element.localization.find(
          (f) => f.culture === localStorage.getItem("activeLang")
        );
        if (translationValue) {
          return translationValue;
        }
        return null;
      }
      return null;
    },
    aceToggleFullScreen(editor) {
      $(editor.container).toggleClass("fullScreen");
      editor.setAutoScrollEditorIntoView(!false);
      editor.resize();
    },
    localizationForQueryBuilderFieldOperators(items) {
      var localizedItems = [];
      items.forEach((item) => {
        localizedItems.push({
          key: this.$t(
            "FieldOperators." + item.key,
            {},
            { locale: this.$store.state.activeLang }
          ),
          value: item.value,
          originalKey: item.key,
        });
      });
      return localizedItems;
    },
    getFieldIcon(fieldType) {
      var icon = "";
      switch (fieldType) {
        case "Text":
        case "FormulaString":
          icon = "bi-type";
          break;
        case "TextArea":
        case "FormulaTextArea":
          icon = "bi-textarea-resize";
          break;
        case "Number":
        case "FormulaNumber":
          icon = "bi-123";
          break;
        case "Checkbox":
          icon = "bi-toggles";
          break;
        case "Email":
          icon = "bi-envelope-at";
          break;
        case "Phone":
          icon = "bi-phone";
          break;
        case "Url":
          icon = "bi-browser-chrome";
          break;
        case "Date":
        case "FormulaDate":
        case "RollupDate":
          icon = "bi-calendar-date";
          break;
        case "DateTime":
        case "FormulaDateTime":
        case "RollupDateTime":
          icon = " bi-calendar-plus";
          break;
        case "Time":
          icon = "bi-clock";
          break;
        case "Image":
          icon = "bi-image";
          break;
        case "File":
          icon = "bi-file-richtext";
          break;
        case "Html":
          icon = "bi-code-slash";
          break;
        case "Spatial":
        case "FormulaSpatial":
          icon = "bi-map";
          break;
        case "SelectList":
          icon = "bi-list";
          break;
        case "Lookup":
          icon = "bi-arrow-left-right";
          break;
        case "Predefined":
          icon = "bi-arrows-angle-contract";
          break;
        case "Encrypted":
          icon = "bi-lock";
          break;
        case "OrganizationalUnit":
        case "FormulaOrganizationalUnit":
          icon = "bi-people";
          break;
        case "ReadOnly":
          icon = "bi-ban";
          break;
        case "AutoId":
          icon = "bi-fingerprint";
          break;
        case "Rollup":
          icon = "bi-calculator";
          break;
        case "Formula":
          icon = "bi-hash";
          break;
        default:
          break;
      }
      return icon;
    },
    setCurrentUserLanguage(code, isUserNotAuth = false) {
      var oldLocale = this.$i18n.locale;

      if (isUserNotAuth) {
        this.$i18n.locale = code;
        this.$store.commit("setActiveLang", code);
        return;
      }

      // createToast(
      //   this.$t(
      //     "BaseModelFields.ChangeLanguageWarning",
      //     {},
      //     { locale: this.$store.state.activeLang }
      //   ),
      //   {
      //     showIcon: true,
      //     position: "top-left",
      //     type: "warning",
      //     transition: "zoom",
      //   }
      // );

      this.$prodGatewayAxios
        .post("/Lcdp-ChangeUserActiveLanguage/" + code)
        .then((result) => {
          var response = result.data;
          if (!response.isOk) {
            this.$i18n.locale = oldLocale;
            this.$store.commit("setActiveLang", oldLocale);
            setCulture(oldLocale);
            locale(oldLocale);

            createToast(
              this.$t(
                "BaseModelFields.ChangeUserActiveLanguageError",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: true,
                position: "top-right",
                type: "danger",
                transition: "zoom",
              }
            );
          } else {
            this.$i18n.locale = code;
            this.$store.commit("setActiveLang", code);
            setCulture(code);
            locale(code);

            this.signOut();

            createToast(
              this.$t(
                "BaseModelFields.ChangeLanguageMessage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: true,
                position: "top-left",
                type: "warning",
                transition: "zoom",
              }
            );
          }
        });
    },
    preparePhoneNumber(querySelector, value) {
      const phoneSelector = document.getElementById(querySelector);

      // https://github.com/jackocnr/intl-tel-input
      var iti = intlTelInput(phoneSelector, {
        nationalMode: false,
        preferredCountries: ["tr"],
        placeholderNumberType: "MOBILE",
        customPlaceholder: function (
          selectedCountryPlaceholder,
          selectedCountryData
        ) {
          // eslint-disable-next-line no-undef
          var placeholder = intlTelInputUtils.getExampleNumber(
            selectedCountryData.iso2,
            this.nationalMode,
            // eslint-disable-next-line no-undef
            intlTelInputUtils.numberType[this.placeholderNumberType]
          );
          if (String.isNullOrWhiteSpace(placeholder)) return;

          var im = new Inputmask(
            placeholder.replace(new RegExp("\\d", "gm"), "9")
          );
          im.mask(phoneSelector);

          return placeholder;
        },
        utilsScript: "/extras/plugins/intl-tel-input/utils.js",
      });

      if (!String.isNullOrWhiteSpace(value)) {
        iti.setNumber(value);
      }
    },
    checkUnSavedData(data) {
      var unSavedData = false;
      Object.keys(data).forEach(function (key) {
        if (
          !key.includes("old") &&
          data[key] !== data["old_" + key] &&
          !$.isArray(data[key]) &&
          typeof data[key] !== "object"
        ) {
          unSavedData = true;
        }
      });
      return unSavedData;
    },
    brActionValidity(form) {
      var validation = true;
      Array.from(form.elements).forEach((input) => {
        if (input.checkVisibility() && !input.checkValidity()) {
          validation = false;
        }
      });

      return validation;
    },
    copyClipboard(text, leftMessage) {
      navigator.clipboard.writeText(text);

      var copiedMessage = this.$t(
          "BaseModelFields.Copied",
          {},
          { locale: this.$store.state.activeLang }
        ),
        message = copiedMessage;
      if (!String.isNullOrWhiteSpace(leftMessage)) {
        message = String.format("{0} {1}", leftMessage, copiedMessage);
      }

      createToast(message, {
        showIcon: "true",
        position: "top-right",
        type: "success",
        transition: "zoom",
      });
    },
    getLocation() {
      var self = this;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            var location = String.format(
              "{0}|{1}",
              position.coords.latitude,
              position.coords.longitude
            );
            document
              .getElementById("CurrentLocation")
              .setAttribute("value", location);
            Object.setCookie("CurrentLocation", location, 1);
          },
          function (error) {
            switch (error.code) {
              case error.POSITION_UNAVAILABLE:
              case error.TIMEOUT:
              case error.UNKNOWN_ERROR:
                self.getLocation();
            }
            // this.getLocation();
          }
        );
      }
    },
  },
  mounted() {
    var currentLocation = Object.readCookie("CurrentLocation");
    if (currentLocation == null) {
      this.getLocation();
    }

    this.checkSession();
  },
  created() {
    this.applyTheme();

    //!! set is mobile
    this.$store.commit("setIsMobileDevice", this.$isMobile());

    loadMessages(trMessages);
    locale(
      localStorage.getItem("activeLang") ||
        process.env.VUE_APP_I18N_LOCALE ||
        "tr"
    );
  },
};
</script>
