import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { appAxios } from "@/core/axios/appAxios";
import { prodGatewayAxios } from "@/core/axios/prodGatewayAxios";
import { brsAxios } from "@/core/axios/brsAxios";
import { authAxios } from "@/core/axios/authAxios";
import setupInterceptors from "@/core/axios/setupInterceptors";

import "devextreme/dist/css/dx.light.css";
import "devexpress-diagram/dist/dx-diagram.min.css";
import "@/assets/css/devexpress-components.css";
import "intl-tel-input/build/css/intlTelInput.css";
import "@/assets/css/bootstrap.min.css";
import "@/assets/css/sx-toggle.css";
import "@/assets/css/main.css";
import "@/assets/js/set.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import "mosha-vue-toastify/dist/style.css";

import SetLoader from "@/components/custom/loader/SetLoader.vue";
import SideBar from "@/components/layout/SideBar.vue";
import Header from "@/components/layout/Header.vue";
import BreadCrumb from "@/components/layout/Breadcrumb.vue";
import ActionButtons from "@/components/layout/ActionButtons";
import ModalDelete from "@/components/layout/DeleteModal";
import QueryBuilder from "@/components/custom/query-builder/QueryBuilder.vue";
import ToggleButton from "@/components/custom/button/Toggle.vue";
import TranslationModal from "@/components/custom/multi-language/TranslationModal";

// import ThemeHelper from "@/components/custom/theme-helper/ThemeHelper.vue";

import FormSelect from "@/components/custom/vmulti-select/FormSelect";

import Grid from "@/components/devexpress/grid/Grid.vue";
import DatePicker from "@/components/devexpress/datebox/DatePicker.vue";
import ColorPicker from "@/components/devexpress/colorbox/ColorPicker.vue";

import Vue3MobileDetection from "vue3-mobile-detection";

import cronLight from "@vue-js-cron/light";
import "@vue-js-cron/light/dist/light.css";

import { Tooltip } from "bootstrap";
import "bootstrap";
new Tooltip(document.body, {
  selector: "[data-bs-toggle='tooltip']",
});

import moment from "moment/moment";
import cryptoJS from "crypto-js";
import { createI18n, useI18n } from "vue-i18n";
import en from "./locales/en.json";
import tr from "./locales/tr.json";

const localizations = createI18n({
  warnHtmlInMessage: "off",
  locale: store.getters._activeLang,
  messages: {
    en: en,
    tr: tr,
  },
  numberFormats: {
    en: {
      currency: {
        style: "currency",
        currency: "USD",
        decimal: ".",
        group: ",",
      },
    },
    tr: {
      currency: {
        style: "currency",
        currency: "TRY",
        decimal: ",",
        group: ".",
      },
    },
  },
  datetimeFormats: {
    en: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
        calendar: "M/d/yyyy",
        time: "hh:mm A",
      },
      long: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        calendar: "M/d/yyyy hh:mm A",
        time: "hh:mm:ss A",
      },
    },
    tr: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
        calendar: "dd.MM.yyyy",
        time: "HH:mm",
      },
      long: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        calendar: "dd.MM.yyyy HH:mm",
        time: "HH:mm:ss",
      },
    },
  },
});

//Note: n: number format d: datetime format
const app = createApp(App, {
  setup() {
    const { n, d } = useI18n();
    return {
      n,
      d,
    };
  },
});

// Registering Syncfusion license key - syncfusion.com/account/downloads
import { registerLicense } from "@syncfusion/ej2-base";
//20.3.49 license key
registerLicense(
  "ORg4AjUWIQA/Gnt2VVhjQlFaclZJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0ViUX9fcnxWQWVVV0c="
);

app.use(router);
app.use(store);
app.use(localizations);
app.use(Vue3MobileDetection);
app.use(cronLight);
setupInterceptors(store, router);

app.component("SetLoader", SetLoader);
app.component("SideBar", SideBar);
app.component("Header", Header);
app.component("Breadcrumb", BreadCrumb);
app.component("ActionButtons", ActionButtons);
app.component("FormSelect", FormSelect);
app.component("ModalDelete", ModalDelete);
// app.component("ThemeHelper", ThemeHelper);
app.component("QueryBuilder", QueryBuilder);
app.component("Grid", Grid);
app.component("DatePicker", DatePicker);
app.component("ColorPicker", ColorPicker);
app.component("ToggleButton", ToggleButton);
app.component("TranslationModal", TranslationModal);

//app global properties
app.config.globalProperties.$isAutoComplete =
  process.env.VUE_APP_TEXBOXES_AUTOCOMPLETE;
app.config.globalProperties.$systemSeparator =
  process.env.VUE_APP_SYSTEM_SEPARATOR;
app.config.globalProperties.$isTextSpellCheck =
  process.env.VUE_APP_TEXTBOXES_SPELLCHECK;
app.config.globalProperties.$setXRMReportAuthorizeActionUrl =
  process.env.VUE_APP_REPORT_WEB_SITE_AUTHORIZE_ACTION_URL;
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$cryptoJs = cryptoJS;
app.config.globalProperties.$cryptoSecretKey =
  process.env.VUE_APP_CRYPTO_SECRET_KEY;
app.config.globalProperties.$cryptoIvKey = process.env.VUE_APP_CRYPTO_IV_KEY;
app.config.globalProperties.$appSaveAndNewMessageDuration =
  process.env.VUE_APP_SAVE_AND_NEW_SUCCESS_MESSAGE_DURATION;

// add axios instances
app.config.globalProperties.$authAxios = authAxios;
app.config.globalProperties.$appAxios = appAxios;
app.config.globalProperties.$prodGatewayAxios = prodGatewayAxios;
app.config.globalProperties.$brsAxios = brsAxios;

import { getAppConfigurationFile } from "@/core/config/app-config.js";
getAppConfigurationFile().then((configFile) => {
  app.config.globalProperties.$config = configFile;

  const isDevelopment = process.env.NODE_ENV === "development",
    configIsCustomConfig = configFile.isCustomConfig;

  if (!isDevelopment) {
    authAxios.defaults.baseURL = !configIsCustomConfig
      ? process.env.VUE_APP_API_URL
      : configFile.api.gateway;
    prodGatewayAxios.defaults.baseURL = !configIsCustomConfig
      ? process.env.VUE_APP_API_URL
      : configFile.api.gateway;
    brsAxios.defaults.baseURL = !configIsCustomConfig
      ? process.env.VUE_APP_BRS_API_URL
      : configFile.api.brs;
    appAxios.defaults.baseURL = !configIsCustomConfig
      ? process.env.VUE_APP_PROD_API_URL
      : configFile.api.prod;
  }

  app.mount("#app");
});
