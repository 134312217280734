<template>
  <div
    class="page-description"
    :class="this.$route.meta.isHomePage ? 'border-bottom-none mb-0 pb-0' : ''"
  >
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li
          v-for="(breadcrumb, idx) in breadcrumbList"
          :key="idx"
          :class="{ active: !breadcrumb.link }"
          class="breadcrumb-item"
        >
          <span
            v-if="idx == Object.keys(breadcrumbList).length - 1 || idx == 0"
          >
            {{
              $t(breadcrumb.name, {}, { locale: this.$store.state.activeLang })
            }}</span
          >
          <router-link
            v-else-if="!String.isNullOrWhiteSpace(breadcrumb.link)"
            :to="breadcrumb.link"
          >
            {{
              $t(breadcrumb.name, {}, { locale: this.$store.state.activeLang })
            }}
          </router-link>
          <span v-else>
            {{
              $t(breadcrumb.name, {}, { locale: this.$store.state.activeLang })
            }}
          </span>
        </li>
      </ol>
    </nav>
    <div v-if="isDropdownList" class="col-md-3 ms-0 mx-1 col-xs-12 col-sm-12">
      <FormSelect
        type="SelectBreadcrumb"
        @onChange="onChangeForCustomObject"
        :selectedData="[
          { key: this.$route.params.customObjectId, value: this.objectName },
        ]"
        :isDisabled="
          $route.name.includes('Edit') ||
          $route.name.includes('BusinessRuleAction')
            ? true
            : false
        "
        :isGatewayRequest="true"
        requestUrl="/Lcdp-SummaryCustomObjects"
      />
    </div>
    <h3 v-else-if="this.detailTitle && !this.$route.meta.isHomePage">
      {{ detailTitle }}
    </h3>
    <h3 v-else-if="!this.$route.meta.isHomePage && pageTitle != ''">
      {{ $t(pageTitle, {}, { locale: this.$store.state.activeLang }) }}
    </h3>
  </div>
</template>
<script>
export default {
  name: "Breadcrumb",
  components: {},
  props: {
    detailTitle: {
      type: String,
      default: "",
    },
    objectName: {
      type: String,
      default: "",
    },
    parentTitle: {
      type: String,
      default: "",
    },
    isDropdownList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      breadcrumbList: [],
      pageTitle: "",
    };
  },
  mounted() {
    this.updateList();
  },
  watch: {
    $route() {
      this.updateList();
    },
    detailTitle() {
      this.$root.changeDocumentTitle(this.detailTitle);
    },
  },
  methods: {
    onChangeForCustomObject: function (selected) {
      if (
        this.$route.params.customObjectId !== selected.key &&
        this.isDropdownList
      ) {
        this.$router.push({
          name: this.$route.name,
          params: { customObjectId: selected.key },
        });
      }
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb(this);

      this.pageTitle = this.$route.meta.pageTitle;
      if (this.detailTitle) {
        this.$root.changeDocumentTitle(this.detailTitle);
      } else if (this.pageTitle) {
        this.$root.changeDocumentTitle(
          this.$t(this.pageTitle, {}, { locale: this.$store.state.activeLang })
        );
      } else {
        this.$root.changeDocumentTitle("");
      }
    },
  },
};
</script>
