<template>
  <div class="dx-viewport">
    <DxColorBox
      :value="value"
      :showClearButton="true"
      :openOnFieldClick="true"
      :disabled="isDisabled"
      :onValueChanged="valueChanged"
    />
  </div>
</template>
<script>
import { DxColorBox } from "devextreme-vue/color-box";
export default {
  name: "ColorPicker",
  props: ["value", "isDisabled"],
  emits: ["valueChanged"],
  components: {
    DxColorBox,
  },
  methods: {
    valueChanged(e) {
      this.$emit("valueChanged", e.value);
    },
  },
};
</script>
