<template>
  <div class="col-12" :class="classes">
    <button
      type="button"
      class="btn btn-success btn-save me-1 mb-1"
      :disabled="isDisabled"
      @click="this.$emit('methodSave')"
    >
      <span
        ><i class="bi bi-save"></i>
        {{
          $t("Buttons.Save", {}, { locale: this.$store.state.activeLang })
        }}</span
      >
    </button>
    <button
      v-if="isNewRecord"
      type="button"
      class="btn btn-primary btn-save-and-new me-1 mb-1"
      :disabled="isDisabled"
      @click="this.$emit('methodSaveAndNew')"
    >
      <span
        ><i class="bi bi-plus-lg"></i>
        {{
          $t("Buttons.SaveAndNew", {}, { locale: this.$store.state.activeLang })
        }}</span
      >
    </button>
    <button
      v-if="isNewRecord"
      type="button"
      class="btn btn-danger btn-clear mb-1"
      @click="this.$emit('clear')"
    >
      <i class="bi bi-arrow-repeat"></i>
      {{ $t("Buttons.Clear", {}, { locale: this.$store.state.activeLang }) }}
    </button>
    <button
      v-if="
        $route.name.includes('SetScript') ||
        $route.name.includes('EditHtmlScriptCss')
      "
      type="button"
      class="btn btn-info ms-1 mb-1"
      data-bs-toggle="modal"
      data-bs-target="#scriptHelperModal"
    >
      <span
        ><i class="bi bi-info-circle"></i>
        {{
          $t(
            "Components.ScriptHelperModal.Title",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</span
      >
    </button>
  </div>
</template>
<script>
export default {
  name: "ActionButtons",
  props: {
    isNewRecord: { type: Boolean, default: true },
    classes: { type: String, default: "" },
    isDisabled: { type: Boolean, default: false },
  },
};
</script>
