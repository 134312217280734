import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store";

/**
 * Breadcrumb Object
 * @param app: You can get store, router information of the application.
 * @description Ex: app.$route, app.$store
 * @example
 * breadcrumb(app) {
 *  return [];
 * },
 */

const routes = [
  {
    name: "Layout",
    path: "/",
    component: () => import("@/views/Shared/Layout.vue"),
    meta: {
      isAuthRequired: true,
    },
    children: [
      {
        name: "HomePage",
        path: "/",
        component: () => import("@/views/Home.vue"),
        meta: {
          isHomePage: true,
          pageTitle: "HomePage",
          breadcrumb(app) {
            return [{ name: "HomePage" }];
          },
        },
      },
      {
        name: "CustomObjectList",
        path: "/CustomObject/List",
        component: () => import("@/views/CustomObject/List.vue"),
        meta: {
          pageTitle: "CustomObjects.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.CustomObjectManagements",
              },
              {
                name: "Sidebar.CustomObjects",
              },
            ];
          },
        },
      },
      {
        name: "CustomObjectNew",
        path: "/CustomObject/New",
        component: () => import("@/views/CustomObject/New.vue"),
        meta: {
          pageTitle: "CustomObjects.NewTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.CustomObjectManagements",
              },
              {
                name: "Sidebar.CustomObjects",
                link: "/CustomObject/List",
              },
              {
                name: "CustomObjects.NewTitle",
              },
            ];
          },
        },
      },
      {
        name: "CustomObjectEdit",
        path: "/CustomObject/Edit/:customObjectId",
        component: () => import("@/views/CustomObject/Edit.vue"),
        meta: {
          pageTitle: "CustomObjects.EditTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.CustomObjectManagements",
              },
              {
                name: "Sidebar.CustomObjects",
                link: "/CustomObject/List",
              },
              {
                name: "CustomObjects.EditTitle",
              },
            ];
          },
        },
      },
      {
        name: "CustomObjectLayout",
        path: "/",
        component: () => import("@/views/Shared/LayoutCustomObject.vue"),
        meta: {
          pageTitle: "",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.CustomObjectManagements",
              },
              {
                name: "Sidebar.CustomObjects",
                link: "/CustomObject/List",
              },
              {
                name: "CustomObjects.DetailTitle",
              },
            ];
          },
        },
        children: [
          {
            name: "CustomObjectNewField",
            path: "/CustomObject/NewField/:customObjectId",
            component: () => import("@/views/CustomObject/Field/NewField.vue"),
            meta: {
              pageTitle: "",
              viewName: "fields",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.Fields",
                    link: `/CustomObject/Fields/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "Fields.NewField",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectEditField",
            path: "/CustomObject/EditField/:customObjectId&fieldId=:fieldId",
            component: () => import("@/views/CustomObject/Field/EditField.vue"),
            meta: {
              pageTitle: "",
              viewName: "fields",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.Fields",
                    link: `/CustomObject/Fields/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "Fields.EditField",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectDetail",
            path: "/CustomObject/Detail/:customObjectId",
            component: () => import("@/views/CustomObject/Detail.vue"),
            meta: {
              pageTitle: "",
              viewName: "detail",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectFields",
            path: "/CustomObject/Fields/:customObjectId",
            component: () => import("@/views/CustomObject/Field/Fields.vue"),
            meta: {
              pageTitle: "",
              viewName: "fields",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.Fields",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectValidationRules",
            path: "/CustomObject/ValidationRules/:customObjectId",
            component: () =>
              import("@/views/CustomObject/ValidationRule/ValidationRules.vue"),
            meta: {
              pageTitle: "",
              viewName: "validationrules",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.ValidationRules",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectNewValidationRule",
            path: "/CustomObject/NewValidationRule/:customObjectId",
            component: () =>
              import(
                "@/views/CustomObject/ValidationRule/NewValidationRule.vue"
              ),
            meta: {
              pageTitle: "",
              viewName: "validationrules",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.ValidationRules",
                    link: `/CustomObject/ValidationRules/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "ValidationRules.NewValidationRule",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectEditValidationRule",
            path: "/CustomObject/EditValidationRule/:customObjectId&validationRuleId=:validationRuleId",
            component: () =>
              import(
                "@/views/CustomObject/ValidationRule/EditValidationRule.vue"
              ),
            meta: {
              pageTitle: "",
              viewName: "validationrules",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.ValidationRules",
                    link: `/CustomObject/ValidationRules/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "ValidationRules.EditValidationRule",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectBusinessRules",
            path: "/CustomObject/BusinessRules/:customObjectId",
            component: () =>
              import("@/views/CustomObject/BusinessRule/BusinessRules.vue"),
            meta: {
              pageTitle: "",
              viewName: "businessRules",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRules",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectNewBusinessRule",
            path: "/CustomObject/NewBusinessRule/:customObjectId",
            component: () =>
              import("@/views/CustomObject/BusinessRule/NewBusinessRule.vue"),
            meta: {
              pageTitle: "",
              viewName: "businessRules",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRules",
                    link: `/CustomObject/BusinessRules/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "BusinessRule.NewBusinessRule",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectEditBusinessRule",
            path: "/CustomObject/EditBusinessRule/:customObjectId&businessRuleId=:businessRuleId",
            component: () =>
              import("@/views/CustomObject/BusinessRule/EditBusinessRule.vue"),
            meta: {
              pageTitle: "",
              viewName: "businessRules",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRules",
                    link: `/CustomObject/BusinessRules/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "BusinessRule.EditBusinessRule",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectBusinessRuleActions",
            path: "/CustomObject/BusinessRuleActions/:customObjectId&businessRuleId=:businessRuleId",
            component: () =>
              import(
                "@/views/CustomObject/BusinessRule/BusinessRuleAction/list/BusinessRuleActions.vue"
              ),
            meta: {
              pageTitle: "",
              viewName: "businessRules",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRules",
                    link: `/CustomObject/BusinessRules/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRuleActions",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectNewBusinessRuleActionCreateRecord",
            path: "/CustomObject/NewBusinessRuleActionCreateRecord/:customObjectId&businessRuleId=:businessRuleId",
            component: () =>
              import(
                "@/views/CustomObject/BusinessRule/BusinessRuleAction/new/NewBusinessRuleActionCreateRecord.vue"
              ),
            meta: {
              pageTitle: "",
              viewName: "businessRules",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRules",
                    link: `/CustomObject/BusinessRules/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRuleActions",
                    link: `/CustomObject/BusinessRuleActions/${app.$route.params.customObjectId}&businessRuleId=${app.$route.params.businessRuleId}`,
                  },
                  {
                    name: "BusinessRuleActions.NewBusinessRuleAction",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectNewBusinessRuleActionUpdateRecord",
            path: "/CustomObject/NewBusinessRuleActionUpdateRecord/:customObjectId&businessRuleId=:businessRuleId",
            component: () =>
              import(
                "@/views/CustomObject/BusinessRule/BusinessRuleAction/new/NewBusinessRuleActionUpdateRecord.vue"
              ),
            meta: {
              pageTitle: "",
              viewName: "businessRules",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRules",
                    link: `/CustomObject/BusinessRules/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRuleActions",
                    link: `/CustomObject/BusinessRuleActions/${app.$route.params.customObjectId}&businessRuleId=${app.$route.params.businessRuleId}`,
                  },
                  {
                    name: "BusinessRuleActions.NewBusinessRuleAction",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectNewBusinessRuleActionSendNotification",
            path: "/CustomObject/NewBusinessRuleActionSendNotification/:customObjectId&businessRuleId=:businessRuleId",
            component: () =>
              import(
                "@/views/CustomObject/BusinessRule/BusinessRuleAction/new/NewBusinessRuleActionSendNotification.vue"
              ),
            meta: {
              pageTitle: "",
              viewName: "businessRules",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRules",
                    link: `/CustomObject/BusinessRules/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRuleActions",
                    link: `/CustomObject/BusinessRuleActions/${app.$route.params.customObjectId}&businessRuleId=${app.$route.params.businessRuleId}`,
                  },
                  {
                    name: "BusinessRuleActions.NewBusinessRuleAction",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectNewBusinessRuleActionSendHttpRequest",
            path: "/CustomObject/NewBusinessRuleActionSendHttpRequest/:customObjectId&businessRuleId=:businessRuleId",
            component: () =>
              import(
                "@/views/CustomObject/BusinessRule/BusinessRuleAction/new/NewBusinessRuleActionSendHttpRequest.vue"
              ),
            meta: {
              pageTitle: "",
              viewName: "businessRules",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRules",
                    link: `/CustomObject/BusinessRules/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRuleActions",
                    link: `/CustomObject/BusinessRuleActions/${app.$route.params.customObjectId}&businessRuleId=${app.$route.params.businessRuleId}`,
                  },
                  {
                    name: "BusinessRuleActions.NewBusinessRuleAction",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectNewBusinessRuleActionSendReport",
            path: "/CustomObject/NewBusinessRuleActionSendReport/:customObjectId&businessRuleId=:businessRuleId",
            component: () =>
              import(
                "@/views/CustomObject/BusinessRule/BusinessRuleAction/new/NewBusinessRuleActionSendReport.vue"
              ),
            meta: {
              pageTitle: "",
              viewName: "businessRules",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRules",
                    link: `/CustomObject/BusinessRules/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRuleActions",
                    link: `/CustomObject/BusinessRuleActions/${app.$route.params.customObjectId}&businessRuleId=${app.$route.params.businessRuleId}`,
                  },
                  {
                    name: "BusinessRuleActions.NewBusinessRuleAction",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectNewBusinessRuleActionSendDashboard",
            path: "/CustomObject/NewBusinessRuleActionSendDashboard/:customObjectId&businessRuleId=:businessRuleId",
            component: () =>
              import(
                "@/views/CustomObject/BusinessRule/BusinessRuleAction/new/NewBusinessRuleActionSendDashboard.vue"
              ),
            meta: {
              pageTitle: "",
              viewName: "businessRules",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRules",
                    link: `/CustomObject/BusinessRules/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRuleActions",
                    link: `/CustomObject/BusinessRuleActions/${app.$route.params.customObjectId}&businessRuleId=${app.$route.params.businessRuleId}`,
                  },
                  {
                    name: "BusinessRuleActions.NewBusinessRuleAction",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectNewBusinessRuleActionSendXtraReport",
            path: "/CustomObject/NewBusinessRuleActionSendXtraReport/:customObjectId&businessRuleId=:businessRuleId",
            component: () =>
              import(
                "@/views/CustomObject/BusinessRule/BusinessRuleAction/new/NewBusinessRuleActionSendXtraReport.vue"
              ),
            meta: {
              pageTitle: "",
              viewName: "businessRules",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRules",
                    link: `/CustomObject/BusinessRules/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRuleActions",
                    link: `/CustomObject/BusinessRuleActions/${app.$route.params.customObjectId}&businessRuleId=${app.$route.params.businessRuleId}`,
                  },
                  {
                    name: "BusinessRuleActions.NewBusinessRuleAction",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectEditBusinessRuleActionCreateRecord",
            path: "/CustomObject/EditBusinessRuleActionCreateRecord/:customObjectId&businessRuleId=:businessRuleId&businessRuleActionId=:businessRuleActionId",
            component: () =>
              import(
                "@/views/CustomObject/BusinessRule/BusinessRuleAction/edit/EditBusinessRuleActionCreateRecord.vue"
              ),
            meta: {
              pageTitle: "",
              viewName: "businessRules",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRules",
                    link: `/CustomObject/BusinessRules/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRuleActions",
                    link: `/CustomObject/BusinessRuleActions/${app.$route.params.customObjectId}&businessRuleId=${app.$route.params.businessRuleId}`,
                  },
                  {
                    name: "BusinessRuleActions.EditActionCreateRecord",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectEditBusinessRuleActionUpdateRecord",
            path: "/CustomObject/EditBusinessRuleActionUpdateRecord/:customObjectId&businessRuleId=:businessRuleId&businessRuleActionId=:businessRuleActionId",
            component: () =>
              import(
                "@/views/CustomObject/BusinessRule/BusinessRuleAction/edit/EditBusinessRuleActionUpdateRecord.vue"
              ),
            meta: {
              pageTitle: "",
              viewName: "businessRules",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRules",
                    link: `/CustomObject/BusinessRules/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRuleActions",
                    link: `/CustomObject/BusinessRuleActions/${app.$route.params.customObjectId}&businessRuleId=${app.$route.params.businessRuleId}`,
                  },
                  {
                    name: "BusinessRuleActions.EditActionCreateRecord",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectEditBusinessRuleActionSendNotification",
            path: "/CustomObject/EditBusinessRuleActionSendNotification/:customObjectId&businessRuleId=:businessRuleId&businessRuleActionId=:businessRuleActionId",
            component: () =>
              import(
                "@/views/CustomObject/BusinessRule/BusinessRuleAction/edit/EditBusinessRuleActionSendNotification.vue"
              ),
            meta: {
              pageTitle: "",
              viewName: "businessRules",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRules",
                    link: `/CustomObject/BusinessRules/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRuleActions",
                    link: `/CustomObject/BusinessRuleActions/${app.$route.params.customObjectId}&businessRuleId=${app.$route.params.businessRuleId}`,
                  },
                  {
                    name: "BusinessRuleActions.EditActionCreateRecord",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectEditBusinessRuleActionSendHttpRequest",
            path: "/CustomObject/EditBusinessRuleActionSendHttpRequest/:customObjectId&businessRuleId=:businessRuleId&businessRuleActionId=:businessRuleActionId",
            component: () =>
              import(
                "@/views/CustomObject/BusinessRule/BusinessRuleAction/edit/EditBusinessRuleActionSendHttpRequest.vue"
              ),
            meta: {
              pageTitle: "",
              viewName: "businessRules",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRules",
                    link: `/CustomObject/BusinessRules/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRuleActions",
                    link: `/CustomObject/BusinessRuleActions/${app.$route.params.customObjectId}&businessRuleId=${app.$route.params.businessRuleId}`,
                  },
                  {
                    name: "BusinessRuleActions.EditActionCreateRecord",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectEditBusinessRuleActionSendReport",
            path: "/CustomObject/EditBusinessRuleActionSendReport/:customObjectId&businessRuleId=:businessRuleId&businessRuleActionId=:businessRuleActionId",
            component: () =>
              import(
                "@/views/CustomObject/BusinessRule/BusinessRuleAction/edit/EditBusinessRuleActionSendReport.vue"
              ),
            meta: {
              pageTitle: "",
              viewName: "businessRules",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRules",
                    link: `/CustomObject/BusinessRules/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRuleActions",
                    link: `/CustomObject/BusinessRuleActions/${app.$route.params.customObjectId}&businessRuleId=${app.$route.params.businessRuleId}`,
                  },
                  {
                    name: "BusinessRuleActions.EditActionCreateRecord",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectEditBusinessRuleActionSendXtraReport",
            path: "/CustomObject/EditBusinessRuleActionSendXtraReport/:customObjectId&businessRuleId=:businessRuleId&businessRuleActionId=:businessRuleActionId",
            component: () =>
              import(
                "@/views/CustomObject/BusinessRule/BusinessRuleAction/edit/EditBusinessRuleActionSendXtraReport.vue"
              ),
            meta: {
              pageTitle: "",
              viewName: "businessRules",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRules",
                    link: `/CustomObject/BusinessRules/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRuleActions",
                    link: `/CustomObject/BusinessRuleActions/${app.$route.params.customObjectId}&businessRuleId=${app.$route.params.businessRuleId}`,
                  },
                  {
                    name: "BusinessRuleActions.EditActionCreateRecord",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectEditBusinessRuleActionSendDashboard",
            path: "/CustomObject/EditBusinessRuleActionSendDashboard/:customObjectId&businessRuleId=:businessRuleId&businessRuleActionId=:businessRuleActionId",
            component: () =>
              import(
                "@/views/CustomObject/BusinessRule/BusinessRuleAction/edit/EditBusinessRuleActionSendDashboard.vue"
              ),
            meta: {
              pageTitle: "",
              viewName: "businessRules",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRules",
                    link: `/CustomObject/BusinessRules/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.BusinessRuleActions",
                    link: `/CustomObject/BusinessRuleActions/${app.$route.params.customObjectId}&businessRuleId=${app.$route.params.businessRuleId}`,
                  },
                  {
                    name: "BusinessRuleActions.EditActionCreateRecord",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectKanban",
            path: "/CustomObject/Kanban/:customObjectId",
            component: () => import("@/views/CustomObject/Kanban/Kanban.vue"),
            meta: {
              pageTitle: "",
              viewName: "kanban",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.Kanban",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectNewKanban",
            path: "/CustomObject/NewKanban/:customObjectId",
            component: () =>
              import("@/views/CustomObject/Kanban/NewKanban.vue"),
            meta: {
              pageTitle: "",
              viewName: "kanban",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.Kanban",
                    link: `/CustomObject/Kanban/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "KanbanView.EditKanban",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectEditKanban",
            path: "/CustomObject/EditKanban/:customObjectId&kanbanId=:kanbanId",
            component: () =>
              import("@/views/CustomObject/Kanban/EditKanban.vue"),
            meta: {
              pageTitle: "",
              viewName: "kanban",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.Kanban",
                    link: `/CustomObject/Kanban/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "KanbanView.EditKanban",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectMap",
            path: "/CustomObject/Map/:customObjectId",
            component: () => import("@/views/CustomObject/MapView/MapView.vue"),
            meta: {
              pageTitle: "",
              viewName: "map",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.Map",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectNewMap",
            path: "/CustomObject/NewMap/:customObjectId",
            component: () =>
              import("@/views/CustomObject/MapView/NewMapView.vue"),
            meta: {
              pageTitle: "",
              viewName: "map",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.Map",
                    link: `/CustomObject/Map/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "MapView.EditMap",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectEditMap",
            path: "/CustomObject/EditMap/:customObjectId&mapId=:mapId",
            component: () =>
              import("@/views/CustomObject/MapView/EditMapView.vue"),
            meta: {
              pageTitle: "",
              viewName: "map",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.Map",
                    link: `/CustomObject/Map/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "MapView.EditMap",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectViewFilters",
            path: "/CustomObject/ViewFilters/:customObjectId",
            component: () =>
              import("@/views/CustomObject/ViewFilter/ViewFilters.vue"),
            meta: {
              pageTitle: "",
              viewName: "viewfilters",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.ViewFilters",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectNewViewFilter",
            path: "/CustomObject/NewViewFilter/:customObjectId",
            component: () =>
              import("@/views/CustomObject/ViewFilter/NewViewFilter.vue"),
            meta: {
              pageTitle: "",
              viewName: "viewfilters",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.ViewFilters",
                    link: `/CustomObject/ViewFilters/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "ViewFilters.NewViewFilter",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectViewFilterEditPageFilter",
            path: "/CustomObject/EditPageFilter/:customObjectId&viewFilterId=:viewFilterId",
            component: () =>
              import("@/views/CustomObject/ViewFilter/EditPageFilter.vue"),
            meta: {
              pageTitle: "",
              viewName: "viewfilters",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.ViewFilters",
                    link: `/CustomObject/ViewFilters/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "ViewFilters.PageFilter",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectEditViewFilter",
            path: "/CustomObject/EditViewFilter/:customObjectId&viewFilterId=:viewFilterId",
            component: () =>
              import("@/views/CustomObject/ViewFilter/EditViewFilter.vue"),
            meta: {
              pageTitle: "",
              viewName: "viewfilters",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.ViewFilters",
                    link: `/CustomObject/ViewFilters/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "ViewFilters.EditViewFilter",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectSetScripts",
            path: "/CustomObject/SetScripts/:customObjectId",
            component: () =>
              import("@/views/CustomObject/SetScript/SetScripts.vue"),
            meta: {
              pageTitle: "",
              viewName: "setscripts",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.Scripts",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectNewSetScript",
            path: "/CustomObject/NewSetScript/:customObjectId",
            component: () =>
              import("@/views/CustomObject/SetScript/NewSetScript.vue"),
            meta: {
              pageTitle: "",
              viewName: "setscripts",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.Scripts",
                    link: `/CustomObject/SetScripts/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "SetScript.NewSetScript",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectEditSetScript",
            path: "/CustomObject/EditSetScript/:customObjectId&scriptId=:scriptId",
            component: () =>
              import("@/views/CustomObject/SetScript/EditSetScript.vue"),
            meta: {
              pageTitle: "",
              viewName: "setscripts",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.Scripts",
                    link: `/CustomObject/SetScripts/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "SetScript.EditSetScript",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectObjectRelations",
            path: "/CustomObject/ObjectRelations/:customObjectId",
            component: () =>
              import("@/views/CustomObject/ObjectRelation/ObjectRelations.vue"),
            meta: {
              pageTitle: "",
              viewName: "objectrelations",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.ObjectRelations",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectNewObjectRelation",
            path: "/CustomObject/NewObjectRelation/:customObjectId",
            component: () =>
              import(
                "@/views/CustomObject/ObjectRelation/NewObjectRelation.vue"
              ),
            meta: {
              pageTitle: "",
              viewName: "objectrelations",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.ObjectRelations",
                    link: `/CustomObject/ObjectRelations/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "ObjectRelations.NewObjectRelation",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectEditObjectRelation",
            path: "/CustomObject/EditObjectRelation/:customObjectId&objectRelationId=:objectRelationId",
            component: () =>
              import(
                "@/views/CustomObject/ObjectRelation/EditObjectRelation.vue"
              ),
            meta: {
              pageTitle: "",
              viewName: "objectrelations",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.ObjectRelations",
                    link: `/CustomObject/ObjectRelations/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "ObjectRelations.EditObjectRelation",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectDependencyFields",
            path: "/CustomObject/DependencyFields/:customObjectId",
            component: () =>
              import(
                "@/views/CustomObject/DependencyField/DependencyFields.vue"
              ),
            meta: {
              pageTitle: "",
              viewName: "dependencyFields",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.DependencyFields",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectNewDependencyField",
            path: "/CustomObject/NewDependencyField/:customObjectId",
            component: () =>
              import(
                "@/views/CustomObject/DependencyField/NewDependencyField.vue"
              ),
            meta: {
              pageTitle: "",
              viewName: "dependencyFields",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.DependencyFields",
                    link: `/CustomObject/DependencyFields/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "DependencyFields.NewDependencyField",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectEditDependencyField",
            path: "/CustomObject/EditDependencyField/:customObjectId&dependencyFieldId=:dependencyFieldId",
            component: () =>
              import(
                "@/views/CustomObject/DependencyField/EditDependencyField.vue"
              ),
            meta: {
              pageTitle: "",
              viewName: "dependencyFields",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.DependencyFields",
                    link: `/CustomObject/DependencyFields/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "DependencyFields.EditDependencyField",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectDetailDependencyField",
            path: "/CustomObject/DetailDependencyField/:customObjectId&dependencyFieldId=:dependencyFieldId",
            component: () =>
              import(
                "@/views/CustomObject/DependencyField/DetailDependencyField.vue"
              ),
            meta: {
              pageTitle: "",
              viewName: "dependencyFields",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.DependencyFields",
                    link: `/CustomObject/DependencyFields/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "DependencyFields.DetailDependencyField",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectFormTemplates",
            path: "/CustomObject/FormTemplates/:customObjectId",
            component: () =>
              import("@/views/CustomObject/FormTemplate/FormTemplates.vue"),
            meta: {
              pageTitle: "",
              viewName: "formTemplates",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.FormTemplates",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectNewFormTemplate",
            path: "/CustomObject/NewFormTemplate/:customObjectId",
            component: () =>
              import("@/views/CustomObject/FormTemplate/NewFormTemplate.vue"),
            meta: {
              pageTitle: "",
              viewName: "formTemplates",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.FormTemplates",
                    link: `/CustomObject/FormTemplates/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "FormTemplates.NewFormTemplate",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectEditFormTemplate",
            path: "/CustomObject/EditFormTemplate/:customObjectId&formTemplateId=:formTemplateId",
            component: () =>
              import("@/views/CustomObject/FormTemplate/EditFormTemplate.vue"),
            meta: {
              pageTitle: "",
              viewName: "formTemplates",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.FormTemplates",
                    link: `/CustomObject/FormTemplates/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "FormTemplates.EditFormTemplate",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectPageLayouts",
            path: "/CustomObject/PageLayouts/:customObjectId",
            component: () =>
              import("@/views/CustomObject/PageLayout/PageLayouts.vue"),
            meta: {
              pageTitle: "",
              viewName: "pagelayouts",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.PageLayouts",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectNewPageLayout",
            path: "/CustomObject/NewPageLayout/:customObjectId",
            component: () =>
              import("@/views/CustomObject/PageLayout/NewPageLayout.vue"),
            meta: {
              pageTitle: "",
              viewName: "pagelayouts",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.PageLayouts",
                    link: `/CustomObject/PageLayouts/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "PageLayouts.NewPageLayout",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectEditPageLayout",
            path: "/CustomObject/EditPageLayout/:customObjectId&pageLayoutId=:pageLayoutId",
            component: () =>
              import("@/views/CustomObject/PageLayout/EditPageLayout.vue"),
            meta: {
              pageTitle: "",
              viewName: "pagelayouts",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.PageLayouts",
                    link: `/CustomObject/PageLayouts/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "PageLayouts.EditPageLayout",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectCalendars",
            path: "/CustomObject/CalendarViews/:customObjectId",
            component: () =>
              import("@/views/CustomObject/CalendarView/CalendarViews.vue"),
            meta: {
              pageTitle: "",
              viewName: "calendars",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.CalendarSettings",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectNewCalendar",
            path: "/CustomObject/NewCalendar/:customObjectId",
            component: () =>
              import("@/views/CustomObject/CalendarView/NewCalendar.vue"),
            meta: {
              pageTitle: "",
              viewName: "calendars",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.CalendarSettings",
                    link: `/CustomObject/CalendarViews/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CalendarViews.NewCalendar",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectEditCalendar",
            path: "/CustomObject/EditCalendar/:customObjectId&calendarViewId=:calendarViewId",
            component: () =>
              import("@/views/CustomObject/CalendarView/EditCalendar.vue"),
            meta: {
              pageTitle: "",
              viewName: "calendars",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.CalendarSettings",
                    link: `/CustomObject/CalendarViews/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CalendarViews.EditCalendar",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectTrees",
            path: "/CustomObject/TreeViews/:customObjectId",
            component: () =>
              import("@/views/CustomObject/TreeView/TreeViews.vue"),
            meta: {
              pageTitle: "",
              viewName: "treeViews",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.TreeViewSettings",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectNewTree",
            path: "/CustomObject/NewTree/:customObjectId",
            component: () =>
              import("@/views/CustomObject/TreeView/NewTree.vue"),
            meta: {
              pageTitle: "",
              viewName: "treeViews",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.TreeViewSettings",
                    link: `/CustomObject/TreeViews/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "TreeViews.NewTree",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectEditTree",
            path: "/CustomObject/EditTree/:customObjectId&treeViewId=:treeViewId",
            component: () =>
              import("@/views/CustomObject/TreeView/EditTree.vue"),
            meta: {
              pageTitle: "",
              viewName: "treeViews",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.TreeViewSettings",
                    link: `/CustomObject/TreeViews/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "TreeViews.EditTree",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectButtons",
            path: "/CustomObject/Buttons/:customObjectId",
            component: () => import("@/views/CustomObject/Button/Buttons.vue"),
            meta: {
              pageTitle: "",
              viewName: "buttons",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.Buttons",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectNewButton",
            path: "/CustomObject/NewButton/:customObjectId",
            component: () =>
              import("@/views/CustomObject/Button/NewButton.vue"),
            meta: {
              pageTitle: "",
              viewName: "buttons",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.Buttons",
                    link: `/CustomObject/Buttons/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomButtons.NewButton",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectEditButton",
            path: "/CustomObject/EditButton/:customObjectId&buttonId=:buttonId",
            component: () =>
              import("@/views/CustomObject/Button/EditButton.vue"),
            meta: {
              pageTitle: "",
              viewName: "buttons",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.Buttons",
                    link: `/CustomObject/Buttons/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomButtons.EditButton",
                  },
                ];
              },
            },
          },
          {
            name: "CustomObjectRelations",
            path: "/CustomObject/Relations/:customObjectId",
            component: () =>
              import("@/views/CustomObject/Relation/Relations.vue"),
            meta: {
              pageTitle: "",
              viewName: "relations",
              breadcrumb(app) {
                return [
                  {
                    name: "Sidebar.CustomObjectManagements",
                  },
                  {
                    name: "Sidebar.CustomObjects",
                    link: "/CustomObject/List",
                  },
                  {
                    name: "CustomObjects.DetailTitle",
                    link: `/CustomObject/Detail/${app.$route.params.customObjectId}`,
                  },
                  {
                    name: "CustomObjects.Relations",
                  },
                ];
              },
            },
          },
        ],
      },
      {
        name: "MenuList",
        path: "/Menu/List",
        component: () => import("@/views/Menu/List.vue"),
        meta: {
          pageTitle: "Menus.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.Menus",
              },
            ];
          },
        },
      },
      {
        name: "MenuNew",
        path: "/Menu/New",
        component: () => import("@/views/Menu/New.vue"),
        meta: {
          pageTitle: "Menus.NewTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.Menus",
                link: "/Menu/List",
              },
              {
                name: "Menus.NewTitle",
              },
            ];
          },
        },
      },
      {
        name: "MenuEdit",
        path: "/Menu/Edit/:menuId",
        component: () => import("@/views/Menu/Edit.vue"),
        meta: {
          pageTitle: "Menus.EditTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.Menus",
                link: "/Menu/List",
              },
              {
                name: "Menus.MenuItems.Title",
                link: `/Menu/ListItem/${app.$route.params.menuId}`,
              },
              {
                name: "Menus.EditTitle",
              },
            ];
          },
        },
      },
      {
        name: "MenuListItem",
        path: "/Menu/ListItem/:menuId",
        component: () => import("@/views/Menu/ListItem.vue"),
        meta: {
          pageTitle: "Menus.MenuItems.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.Menus",
                link: "/Menu/List",
              },
              {
                name: "Menus.MenuItems.Title",
              },
            ];
          },
        },
      },
      {
        name: "MenuNewItem",
        path: "/Menu/NewItem/:menuId?",
        component: () => import("@/views/Menu/NewItem.vue"),
        meta: {
          pageTitle: "Menus.MenuItems.NewTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.Menus",
                link: "/Menu/List",
              },
              {
                name: "Menus.MenuItems.Title",
                link: `/Menu/ListItem/${app.$route.params.menuId}`,
              },
              {
                name: "Menus.MenuItems.NewTitle",
              },
            ];
          },
        },
      },
      {
        name: "MenuEditItem",
        path: "/Menu/EditItem/:menuId&menuItemId=:menuItemId?",
        component: () => import("@/views/Menu/EditItem.vue"),
        meta: {
          pageTitle: "Menus.MenuItems.EditTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.Menus",
                link: "/Menu/List",
              },
              {
                name: "Menus.MenuItems.Title",
                link: `/Menu/ListItem/${app.$route.params.menuId}`,
              },
              {
                name: "Menus.MenuItems.EditTitle",
              },
            ];
          },
        },
      },
      {
        name: "MenuNewSubItem",
        path: "/Menu/NewSubItem/:menuId&menuItemId=:menuItemId?",
        component: () => import("@/views/Menu/NewSubItem.vue"),
        meta: {
          pageTitle: "Menus.MenuItems.NewSubItemTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.Menus",
                link: "/Menu/List",
              },
              {
                name: "Menus.MenuItems.Title",
                link: `/Menu/ListItem/${app.$route.params.menuId}`,
              },
              {
                name: "Menus.MenuItems.DetailTitle",
                link: `/Menu/Detail/${app.$route.params.menuId}&menuItemId=${app.$route.params.menuItemId}`,
              },
              {
                name: "Menus.MenuItems.NewSubItemTitle",
              },
            ];
          },
        },
      },
      {
        name: "MenuEditSubItem",
        path: "/Menu/EditSubItem/:menuId&menuItemId=:menuItemId&menuSubItemId=:menuSubItemId?",
        component: () => import("@/views/Menu/EditSubItem.vue"),
        meta: {
          pageTitle: "Menus.MenuItems.EditSubItemTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.Menus",
                link: "/Menu/List",
              },
              {
                name: "Menus.MenuItems.Title",
                link: `/Menu/ListItem/${app.$route.params.menuId}`,
              },
              {
                name: "Menus.MenuItems.DetailTitle",
                link: `/Menu/Detail/${app.$route.params.menuId}&menuItemId=${app.$route.params.menuItemId}`,
              },
              {
                name: "Menus.MenuItems.EditSubItemTitle",
              },
            ];
          },
        },
      },
      {
        name: "MenuItemListDetail",
        path: "/Menu/Detail/:menuId&menuItemId=:menuItemId",
        component: () => import("@/views/Menu/Detail.vue"),
        meta: {
          pageTitle: "Menus.MenuItems.DetailTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.Menus",
                link: "/Menu/List",
              },
              {
                name: "Menus.MenuItems.Title",
                link: `/Menu/ListItem/${app.$route.params.menuId}`,
              },
              {
                name: "Menus.MenuItems.DetailTitle",
              },
            ];
          },
        },
      },
      {
        name: "CompanyParameterList",
        path: "/CompanyParameter/List",
        component: () => import("@/views/CompanyParameter/List.vue"),
        meta: {
          pageTitle: "ProjectParameters.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.ProjectParameters",
                link: "/CompanyParameter/List",
              },
            ];
          },
        },
      },
      {
        name: "CompanyParameterNew",
        path: "/CompanyParameter/New",
        component: () => import("@/views/CompanyParameter/New.vue"),
        meta: {
          pageTitle: "ProjectParameters.NewTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.ProjectParameters",
                link: "/CompanyParameter/List",
              },
              {
                name: "ProjectParameters.NewTitle",
              },
            ];
          },
        },
      },
      {
        name: "CompanyParameterEdit",
        path: "/CompanyParameter/Edit/:companyParameterId",
        component: () => import("@/views/CompanyParameter/Edit.vue"),
        meta: {
          pageTitle: "ProjectParameters.EditTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.ProjectParameters",
                link: "/CompanyParameter/List",
              },
              {
                name: "ProjectParameters.EditTitle",
              },
            ];
          },
        },
      },
      {
        name: "ProjectList",
        path: "/Project/List",
        component: () => import("@/views/Project/List.vue"),
        meta: {
          pageTitle: "Projects.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SuperAdminSettings",
              },
              {
                name: "Sidebar.Projects",
                link: "/Project/List",
              },
            ];
          },
        },
      },
      {
        name: "ProjectNew",
        path: "/Project/New",
        component: () => import("@/views/Project/New.vue"),
        meta: {
          pageTitle: "Projects.NewTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SuperAdminSettings",
              },
              {
                name: "Sidebar.Projects",
                link: "/Project/List",
              },
              {
                name: "Projects.NewTitle",
              },
            ];
          },
        },
      },
      {
        name: "ProjectEdit",
        path: "/Project/Edit/:projectId",
        component: () => import("@/views/Project/EditWithTabs.vue"),
        meta: {
          pageTitle: "Projects.EditTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SuperAdminSettings",
              },
              {
                name: "Sidebar.Projects",
                link: "/Project/List",
              },
              {
                name: "Projects.EditTitle",
              },
            ];
          },
        },
      },
      {
        name: "ProjectLayout",
        path: "/",
        component: () => import("@/views/Shared/LayoutProject.vue"),
        meta: {
          pageTitle: "",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SuperAdminSettings",
              },
              {
                name: "Sidebar.Projects",
                link: "/Project/List",
              },
              {
                name: "Projects.DetailTitle",
              },
            ];
          },
        },
        children: [
          // {
          //   name: "ProjectDetail",
          //   path: "/Project/Detail/:projectId",
          //   component: () => import("@/views/Project/Detail.vue"),
          //   meta: {
          //     pageTitle: "",
          //     viewName: "detail",
          //     breadcrumb() {
          //       return [
          //         {
          //           name: "Sidebar.SuperAdminSettings",
          //         },
          //         {
          //           name: "Sidebar.Projects",
          //           link: "/Project/List",
          //         },
          //         {
          //           name: "Projects.DetailTitle",
          //         },
          //       ];
          //     },
          //   },
          // },
        ],
      },
      {
        name: "DatabaseSettingList",
        path: "/DatabaseSetting/List",
        component: () => import("@/views/DatabaseSetting/List.vue"),
        meta: {
          pageTitle: "DatabaseSettings.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SuperAdminSettings",
              },
              {
                name: "Sidebar.DatabaseSettings",
                link: "/Project/List",
              },
            ];
          },
        },
      },
      {
        name: "DatabaseSettingNew",
        path: "/DatabaseSetting/New",
        component: () => import("@/views/DatabaseSetting/New.vue"),
        meta: {
          pageTitle: "DatabaseSettings.NewTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SuperAdminSettings",
              },
              {
                name: "Sidebar.DatabaseSettings",
                link: "/DatabaseSetting/List",
              },
              {
                name: "DatabaseSettings.NewTitle",
              },
            ];
          },
        },
      },
      {
        name: "DatabaseSettingEdit",
        path: "/DatabaseSetting/Edit/:databaseSettingId",
        component: () => import("@/views/DatabaseSetting/Edit.vue"),
        meta: {
          pageTitle: "DatabaseSettings.EditTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SuperAdminSettings",
              },
              {
                name: "Sidebar.DatabaseSettings",
                link: "/DatabaseSetting/List",
              },
              {
                name: "DatabaseSettings.EditTitle",
              },
            ];
          },
        },
      },
      {
        name: "ApiTokenList",
        path: "/ApiToken/List",
        component: () => import("@/views/ApiToken/List.vue"),
        meta: {
          pageTitle: "ApiToken.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.ApiToken",
                link: "/ApiToken/List",
              },
            ];
          },
        },
      },
      {
        name: "ApiTokenNew",
        path: "/ApiToken/New",
        component: () => import("@/views/ApiToken/New.vue"),
        meta: {
          pageTitle: "ApiToken.NewTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.ApiToken",
                link: "/ApiToken/List",
              },
              {
                name: "ApiToken.NewTitle",
              },
            ];
          },
        },
      },
      {
        name: "ApiTokenEdit",
        path: "/ApiToken/Edit/:apiTokenId",
        component: () => import("@/views/ApiToken/Edit.vue"),
        meta: {
          pageTitle: "ApiToken.EditTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.ApiToken",
                link: "/ApiToken/List",
              },
              {
                name: "ApiToken.EditTitle",
              },
            ];
          },
        },
      },
      {
        name: "ConfigurationList",
        path: "/Configuration/List",
        component: () => import("@/views/Configuration/List.vue"),
        meta: {
          pageTitle: "Configurations.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.Configurations",
                link: "/Configuration/List",
              },
            ];
          },
        },
      },
      {
        name: "ConfigurationNew",
        path: "/Configuration/New",
        component: () => import("@/views/Configuration/New.vue"),
        meta: {
          pageTitle: "Configurations.NewTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.Configurations",
                link: "/Configuration/List",
              },
              {
                name: "Configurations.NewTitle",
              },
            ];
          },
        },
      },
      {
        name: "ConfigurationNewSetXrm",
        path: "/Configuration/NewSetXrm",
        component: () => import("@/views/Configuration/NewSetXRM.vue"),
        meta: {
          pageTitle: "Configurations.NewTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.Configurations",
                link: "/Configuration/List",
              },
              {
                name: "Configurations.NewTitle",
              },
            ];
          },
        },
      },
      {
        name: "ConfigurationEditSetXRM",
        path: "/Configuration/EditSetXRM/:configurationId",
        component: () => import("@/views/Configuration/EditSetXRM.vue"),
        meta: {
          pageTitle: "Configurations.EditTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.Configurations",
                link: "/Configuration/List",
              },
              {
                name: "Configurations.EditTitle",
              },
            ];
          },
        },
      },
      {
        name: "ConfigurationEdit",
        path: "/Configuration/Edit/:configurationId",
        component: () => import("@/views/Configuration/Edit.vue"),
        meta: {
          pageTitle: "Configurations.EditTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.Configurations",
                link: "/Configuration/List",
              },
              {
                name: "Configurations.EditTitle",
              },
            ];
          },
        },
      },
      {
        name: "SetXRMConfigurationList",
        path: "/SetXRMConfiguration/List",
        component: () => import("@/views/SetXRMConfiguration/List.vue"),
        meta: {
          pageTitle: "Configurations.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SuperAdminSettings",
              },
              {
                name: "Sidebar.Configurations",
                link: "/SetXRMConfiguration/List",
              },
            ];
          },
        },
      },
      {
        name: "SetXRMConfigurationNew",
        path: "/SetXRMConfiguration/New",
        component: () => import("@/views/SetXRMConfiguration/New.vue"),
        meta: {
          pageTitle: "Configurations.NewTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SuperAdminSettings",
              },
              {
                name: "Sidebar.Configurations",
                link: "/SetXRMConfiguration/List",
              },
              {
                name: "Configurations.NewTitle",
              },
            ];
          },
        },
      },
      {
        name: "SetXRMConfigurationEdit",
        path: "/SetXRMConfiguration/Edit/:configurationId",
        component: () => import("@/views/SetXRMConfiguration/Edit.vue"),
        meta: {
          pageTitle: "Configurations.EditTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SuperAdminSettings",
              },
              {
                name: "Sidebar.Configurations",
                link: "/SetXRMConfiguration/List",
              },
              {
                name: "Configurations.EditTitle",
              },
            ];
          },
        },
      },
      {
        name: "SetXRMConfigurationEditCustom",
        path: "/SetXRMConfiguration/Edit/:configurationId",
        component: () =>
          import("@/views/SetXRMConfiguration/EditAllCustom.vue"),
        meta: {
          pageTitle: "Configurations.EditTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SuperAdminSettings",
              },
              {
                name: "Sidebar.Configurations",
                link: "/SetXRMConfiguration/List",
              },
              {
                name: "Configurations.EditTitle",
              },
            ];
          },
        },
      },
      {
        name: "CustomObjectDeleteRuleList",
        path: "/CustomObjectDeletionRule/List",
        component: () => import("@/views/CustomObjectDeletionRule/List.vue"),
        meta: {
          pageTitle: "CustomObjectDeleteRules.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.CustomObjectDeletionRules",
              },
            ];
          },
        },
      },
      {
        name: "DataSummaryList",
        path: "/DataSummary/List",
        component: () => import("@/views/DataSummary/List.vue"),
        meta: {
          pageTitle: "DataSummary.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.DataSummary",
              },
            ];
          },
        },
      },
      {
        name: "HolidayList",
        path: "/Holiday/List",
        component: () => import("@/views/Holiday/List.vue"),
        meta: {
          pageTitle: "Holidays.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.Holidays",
              },
            ];
          },
        },
      },
      {
        name: "HolidayNew",
        path: "/Holiday/New",
        component: () => import("@/views/Holiday/New.vue"),
        meta: {
          pageTitle: "Holidays.NewTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.Holidays",
                link: "/Holiday/List",
              },
              {
                name: "Holidays.NewTitle",
              },
            ];
          },
        },
      },
      {
        name: "HolidayEdit",
        path: "/Holiday/Edit/:holidayId",
        component: () => import("@/views/Holiday/Edit.vue"),
        meta: {
          pageTitle: "Holidays.EditTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.Holidays",
                link: "/Holiday/List",
              },
              {
                name: "Holidays.EditTitle",
              },
            ];
          },
        },
      },
      {
        name: "DeletionRuleNew",
        path: "/CustomObjectDeletionRule/New",
        component: () => import("@/views/CustomObjectDeletionRule/New.vue"),
        meta: {
          pageTitle: "DeletionRule.NewDeleteRule",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.CustomObjectDeletionRules",
                link: "/CustomObjectDeletionRule/List",
              },
              {
                name: "DeletionRule.NewDeleteRule",
              },
            ];
          },
        },
      },
      {
        name: "DeletionRuleList",
        path: "/CustomObjectDeletionRule/List",
        component: () => import("@/views/CustomObjectDeletionRule/List.vue"),
        meta: {
          pageTitle: "CustomObjectDeleteRules.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.CustomObjectDeletionRules",
              },
            ];
          },
        },
      },
      {
        name: "DeletionRuleEdit",
        path: "/CustomObjectDeletionRule/Edit/:deletionRuleId",
        component: () => import("@/views/CustomObjectDeletionRule/Edit.vue"),
        meta: {
          pageTitle: "DeletionRule.EditDeleteRule",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.CustomObjectDeletionRules",
                link: "/CustomObjectDeletionRule/List",
              },
              {
                name: "DeletionRule.EditDeleteRule",
              },
            ];
          },
        },
      },
      {
        name: "CustomPageList",
        path: "/CustomPage/List",
        component: () => import("@/views/CustomPage/List.vue"),
        meta: {
          pageTitle: "CustomPage.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.CustomPage",
              },
            ];
          },
        },
      },
      {
        name: "CustomPageNew",
        path: "/CustomPage/New",
        component: () => import("@/views/CustomPage/New.vue"),
        meta: {
          pageTitle: "CustomPage.NewTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.CustomPage",
                link: "/CustomPage/List",
              },
              {
                name: "CustomPage.NewTitle",
              },
            ];
          },
        },
      },
      {
        name: "CustomPageEdit",
        path: "/CustomPage/Edit/:customPageId",
        component: () => import("@/views/CustomPage/Edit.vue"),
        meta: {
          pageTitle: "CustomPage.EditTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.CustomPage",
                link: "/CustomPage/List",
              },
              {
                name: "CustomPage.EditTitle",
              },
            ];
          },
        },
      },
      {
        name: "CustomPageEditHtmlScriptCss",
        path: "/CustomPage/EditHtmlScriptCss/:customPageId",
        component: () => import("@/views/CustomPage/EditHtmlScriptCss.vue"),
        meta: {
          pageTitle: "CustomPage.EditHtmlTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.CustomPage",
                link: "/CustomPage/List",
              },
              {
                name: "CustomPage.EditHtmlTitle",
              },
            ];
          },
        },
      },
      {
        name: "SystemLogList",
        path: "/SystemLog/List",
        component: () => import("@/views/SystemLog/List.vue"),
        meta: {
          pageTitle: "SystemLogs.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.SystemLogs",
              },
            ];
          },
        },
      },
      {
        name: "RecordLogList",
        path: "/RecordLog/List",
        component: () => import("@/views/RecordLog/List.vue"),
        meta: {
          pageTitle: "RecordLogs.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.RecordLogs",
              },
            ];
          },
        },
      },
      {
        name: "FormulaErrorLog",
        path: "/FormulaErrorLog/List",
        component: () => import("@/views/FormulaErrorLog/List.vue"),
        meta: {
          pageTitle: "FormulaErrorLogs.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.FormulaErrorLogs",
              },
            ];
          },
        },
      },
      {
        name: "SystemLoginInformationList",
        path: "/SystemLoginInformation/List",
        component: () => import("@/views/SystemLoginInformation/List.vue"),
        meta: {
          pageTitle: "SystemLoginInformations.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.SystemLoginInformation",
              },
            ];
          },
        },
      },
      {
        name: "ImportBulkRecordDataList",
        path: "/ImportBulkRecordData/List",
        component: () => import("@/views/ImportBulkRecordData/List.vue"),
        meta: {
          pageTitle: "ImportBulkRecordData.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.ImportBulkRecordData",
              },
            ];
          },
        },
      },
      {
        name: "ImportBulkRecordDataNew",
        path: "/ImportBulkRecordData/New",
        component: () => import("@/views/ImportBulkRecordData/New.vue"),
        meta: {
          pageTitle: "ImportBulkRecordData.NewImportBulkRecordData",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.ImportBulkRecordData",
                link: "/ImportBulkRecordData/List",
              },
              {
                name: "ImportBulkRecordData.NewImportBulkRecordData",
              },
            ];
          },
        },
      },
      {
        name: "NotificationSettingList",
        path: "/NotificationSetting/List",
        component: () => import("@/views/NotificationSetting/List.vue"),
        meta: {
          pageTitle: "NotificationSettings.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.NotificationSettings",
              },
            ];
          },
        },
      },
      {
        name: "NotificationSettingNew",
        path: "/NotificationSetting/New",
        component: () => import("@/views/NotificationSetting/New.vue"),
        meta: {
          pageTitle: "NotificationSettings.NewTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.NotificationSettings",
                link: "/NotificationSetting/List",
              },
              {
                name: "NotificationSettings.NewTitle",
              },
            ];
          },
        },
      },
      {
        name: "UserCache",
        path: "/User/Cache/:userId",
        component: () => import("@/views/User/Cache.vue"),
        meta: {
          pageTitle: "Users.CacheTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.OrganizationManagement",
              },
              {
                name: "Sidebar.Users",
                link: "/User/List",
              },
              {
                name: "Users.CacheTitle",
              },
            ];
          },
        },
      },
      {
        name: "BusinessRuleCacheByCustomObject",
        path: "/CustomObject/BrCache/:customObjectId",
        component: () =>
          import(
            "@/components/page-partials/custom-objects/BusinessRulesByCustomObjectFromCache.vue"
          ),
        meta: {
          pageTitle: "CustomObjects.BrCacheTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.CustomObjectManagements",
              },
              {
                name: "Sidebar.CustomObjects",
                link: "/CustomObject/List",
              },
              {
                name: "CustomObjects.BrCacheTitle",
              },
            ];
          },
        },
      },
      {
        name: "NotificationSettingEdit",
        path: "/NotificationSetting/Edit/:notifierSettingId",
        component: () => import("@/views/NotificationSetting/Edit.vue"),
        meta: {
          pageTitle: "NotificationSettings.EditTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.NotificationSettings",
                link: "/NotificationSetting/List",
              },
              {
                name: "CustomObjects.EditTitle",
              },
            ];
          },
        },
      },
      {
        name: "UserList",
        path: "/User/List",
        component: () => import("@/views/User/List.vue"),
        meta: {
          pageTitle: "Users.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.OrganizationManagement",
              },
              {
                name: "Sidebar.Users",
              },
            ];
          },
        },
      },
      {
        name: "UserNew",
        path: "/User/New",
        component: () => import("@/views/User/New.vue"),
        meta: {
          pageTitle: "Users.NewTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.OrganizationManagement",
              },
              {
                name: "Sidebar.Users",
                link: "/User/List",
              },
              {
                name: "Users.NewTitle",
              },
            ];
          },
        },
      },
      {
        name: "UserEdit",
        path: "/User/Edit/:userId",
        component: () => import("@/views/User/Edit.vue"),
        meta: {
          pageTitle: "Users.EditTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.OrganizationManagement",
              },
              {
                name: "Sidebar.Users",
                link: "/User/List",
              },
              {
                name: "Users.EditTitle",
              },
            ];
          },
        },
      },
      {
        name: "UserCache",
        path: "/User/Cache/:userId",
        component: () => import("@/views/User/Cache.vue"),
        meta: {
          pageTitle: "Users.CacheTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.OrganizationManagement",
              },
              {
                name: "Sidebar.Users",
                link: "/User/List",
              },
              {
                name: "Users.CacheTitle",
              },
            ];
          },
        },
      },
      {
        name: "UserGroupList",
        path: "/UserGroup/List",
        component: () => import("@/views/UserGroup/List.vue"),
        meta: {
          pageTitle: "UserGroups.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.OrganizationManagement",
              },
              {
                name: "Sidebar.UserGroups",
              },
            ];
          },
        },
      },
      {
        name: "UserGroupNew",
        path: "/UserGroup/New",
        component: () => import("@/views/UserGroup/New.vue"),
        meta: {
          pageTitle: "UserGroups.NewTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.OrganizationManagement",
              },
              {
                name: "Sidebar.UserGroups",
                link: "/UserGroup/List",
              },
              {
                name: "UserGroups.NewTitle",
              },
            ];
          },
        },
      },
      {
        name: "UserGroupEdit",
        path: "/UserGroup/Edit/:userGroupId",
        component: () => import("@/views/UserGroup/Edit.vue"),
        meta: {
          pageTitle: "UserGroups.EditTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.OrganizationManagement",
              },
              {
                name: "Sidebar.UserGroups",
                link: "/UserGroup/List",
              },
              {
                name: "UserGroups.EditTitle",
              },
            ];
          },
        },
      },
      {
        name: "UserGroupDetail",
        path: "/UserGroup/Detail/:userGroupId",
        component: () => import("@/views/UserGroup/Detail.vue"),
        meta: {
          pageTitle: "UserGroups.DetailTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.OrganizationManagement",
              },
              {
                name: "Sidebar.UserGroups",
                link: "/UserGroup/List",
              },
              {
                name: "UserGroups.DetailTitle",
              },
            ];
          },
        },
      },
      {
        name: "UserPermission",
        path: "/User/Permission/:userId",
        component: () => import("@/views/User/Permission.vue"),
        meta: {
          pageTitle: "PermissionGroups.PermissionSettings",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.OrganizationManagement",
              },
              {
                name: "Sidebar.Users",
                link: "/User/List",
              },
              {
                name: "Users.PermissionSettings",
              },
            ];
          },
        },
      },
      {
        name: "PermissionGroupList",
        path: "/PermissionGroup/List",
        component: () => import("@/views/PermissionGroup/List.vue"),
        meta: {
          pageTitle: "PermissionGroups.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.OrganizationManagement",
              },
              {
                name: "Sidebar.PermissionGroups",
              },
            ];
          },
        },
      },
      {
        name: "PermissionGroupGroups",
        path: "/PermissionGroup/Groups/:permissionGroupId",
        component: () => import("@/views/PermissionGroup/Groups.vue"),
        meta: {
          pageTitle: "PermissionGroups.PermissionSettings",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.OrganizationManagement",
              },
              {
                name: "Sidebar.PermissionGroups",
                link: "/PermissionGroup/List",
              },
              {
                name: "PermissionGroups.PermissionSettings",
              },
            ];
          },
        },
      },
      {
        name: "PermissionGroupNew",
        path: "/PermissionGroup/New",
        component: () => import("@/views/PermissionGroup/New.vue"),
        meta: {
          pageTitle: "PermissionGroups.NewTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.OrganizationManagement",
              },
              {
                name: "Sidebar.PermissionGroups",
                link: "/PermissionGroup/List",
              },
              {
                name: "PermissionGroups.NewTitle",
              },
            ];
          },
        },
      },
      {
        name: "PermissionGroupEdit",
        path: "/PermissionGroup/Edit/:permissionGroupId",
        component: () => import("@/views/PermissionGroup/Edit.vue"),
        meta: {
          pageTitle: "PermissionGroups.EditTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.OrganizationManagement",
              },
              {
                name: "Sidebar.PermissionGroups",
                link: "/PermissionGroup/List",
              },
              {
                name: "PermissionGroups.EditTitle",
              },
            ];
          },
        },
      },
      {
        name: "PermissionGroupDetail",
        path: "/PermissionGroup/Detail/:permissionGroupId",
        component: () => import("@/views/PermissionGroup/Detail.vue"),
        meta: {
          pageTitle: "PermissionGroups.DetailTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.OrganizationManagement",
              },
              {
                name: "Sidebar.PermissionGroups",
                link: "/PermissionGroup/List",
              },
              {
                name: "PermissionGroups.DetailTitle",
              },
            ];
          },
        },
      },

      {
        name: "RestrictedPermissionGroupList",
        path: "/RestrictedPermissionGroup/List",
        component: () => import("@/views/RestrictedPermissionGroup/List.vue"),
        meta: {
          pageTitle: "RestrictedPermissionGroups.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.OrganizationManagement",
              },
              {
                name: "Sidebar.RestrictedPermissionGroups",
              },
            ];
          },
        },
      },
      {
        name: "RestrictedPermissionGroupGroups",
        path: "/RestrictedPermissionGroup/Groups/:permissionGroupId",
        component: () => import("@/views/RestrictedPermissionGroup/Groups.vue"),
        meta: {
          pageTitle: "RestrictedPermissionGroup.PermissionSettings",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.OrganizationManagement",
              },
              {
                name: "Sidebar.RestrictedPermissionGroups",
                link: "/RestrictedPermissionGroup/List",
              },
              {
                name: "RestrictedPermissionGroup.PermissionSettings",
              },
            ];
          },
        },
      },
      {
        name: "RestrictedPermissionGroupNew",
        path: "/RestrictedPermissionGroup/New",
        component: () => import("@/views/RestrictedPermissionGroup/New.vue"),
        meta: {
          pageTitle: "RestrictedPermissionGroups.NewTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.OrganizationManagement",
              },
              {
                name: "Sidebar.RestrictedPermissionGroups",
                link: "/RestrictedPermissionGroup/List",
              },
              {
                name: "RestrictedPermissionGroups.NewTitle",
              },
            ];
          },
        },
      },
      {
        name: "RestrictedPermissionGroupEdit",
        path: "/RestrictedPermissionGroup/Edit/:permissionGroupId",
        component: () => import("@/views/RestrictedPermissionGroup/Edit.vue"),
        meta: {
          pageTitle: "RestrictedPermissionGroups.EditTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.OrganizationManagement",
              },
              {
                name: "Sidebar.RestrictedPermissionGroups",
                link: "/RestrictedPermissionGroup/List",
              },
              {
                name: "RestrictedPermissionGroups.EditTitle",
              },
            ];
          },
        },
      },
      {
        name: "RestrictedPermissionGroupDetail",
        path: "/RestrictedPermissionGroup/Detail/:permissionGroupId",
        component: () => import("@/views/RestrictedPermissionGroup/Detail.vue"),
        meta: {
          pageTitle: "RestrictedPermissionGroups.DetailTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.OrganizationManagement",
              },
              {
                name: "Sidebar.RestrictedPermissionGroups",
                link: "/RestrictedPermissionGroup/List",
              },
              {
                name: "RestrictedPermissionGroups.DetailTitle",
              },
            ];
          },
        },
      },
      {
        name: "UsageList",
        path: "/Usage/List",
        component: () => import("@/views/Usage/List.vue"),
        meta: {
          pageTitle: "UsageCounts.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SuperAdminSettings",
              },
              {
                name: "Sidebar.UsageCounts",
              },
            ];
          },
        },
      },
      {
        name: "SuperAdminUserList",
        path: "/SuperAdmin/UserList",
        component: () => import("@/views/SuperAdmin/UserList.vue"),
        meta: {
          pageTitle: "Users.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SuperAdminSettings",
              },
              {
                name: "Sidebar.Users",
              },
            ];
          },
        },
      },
      {
        name: "SuperAdminUserDetail",
        path: "/SuperAdmin/UserDetail/:userId",
        component: () => import("@/views/SuperAdmin/UserDetail.vue"),
        meta: {
          pageTitle: "Users.DetailTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.OrganizationManagement",
              },
              {
                name: "Sidebar.SuperAdminSettings",
                link: "/SuperAdmin/UserList",
              },
              {
                name: "Users.DetailTitle",
              },
            ];
          },
        },
      },
      {
        name: "SystemMessageTemplateNew",
        path: "/SystemMessageTemplates/New",
        component: () => import("@/views/SystemMessageTemplates/New.vue"),
        meta: {
          pageTitle: "SystemMessageTemplates.NewMessageTemplate",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SuperAdminSettings",
              },
              {
                name: "Sidebar.SystemMessageTemplates",
                link: "/SystemMessageTemplates/List",
              },
              {
                name: "SystemMessageTemplates.NewMessageTemplate",
              },
            ];
          },
        },
      },
      {
        name: "SystemMessageTemplateList",
        path: "/SystemMessageTemplates/List",
        component: () => import("@/views/SystemMessageTemplates/List.vue"),
        meta: {
          pageTitle: "Sidebar.SystemMessageTemplates",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SuperAdminSettings",
              },
              {
                name: "Sidebar.SystemMessageTemplates",
              },
            ];
          },
        },
      },
      {
        name: "SystemMessageTemplateEdit",
        path: "/SystemMessageTemplates/Edit/:systemMessageTemplateId",
        component: () => import("@/views/SystemMessageTemplates/Edit.vue"),
        meta: {
          pageTitle: "SystemMessageTemplates.EditMessageTemplate",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SuperAdminSettings",
              },
              {
                name: "Sidebar.SystemMessageTemplates",
                link: "/SystemMessageTemplates/List",
              },
              {
                name: "SystemMessageTemplates.EditMessageTemplate",
              },
            ];
          },
        },
      },
      {
        name: "PageLayoutTemplateNew",
        path: "/PageLayoutTemplate/New",
        component: () => import("@/views/PageLayoutTemplate/New.vue"),
        meta: {
          pageTitle: "PageLayoutTemplates.NewPageLayoutTemplate",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SuperAdminSettings",
              },
              {
                name: "Sidebar.PageLayoutTemplates",
                link: "/PageLayoutTemplate/List",
              },
              {
                name: "PageLayoutTemplates.NewPageLayoutTemplate",
              },
            ];
          },
        },
      },
      {
        name: "PageLayoutTemplateList",
        path: "/PageLayoutTemplate/List",
        component: () => import("@/views/PageLayoutTemplate/List.vue"),
        meta: {
          pageTitle: "PageLayoutTemplates.ListPageLayoutTemplate",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SuperAdminSettings",
              },
              {
                name: "Sidebar.PageLayoutTemplates",
              },
            ];
          },
        },
      },
      {
        name: "PageLayoutTemplateEdit",
        path: "/PageLayoutTemplate/Edit/:pageLayoutTemplateId",
        component: () => import("@/views/PageLayoutTemplate/Edit.vue"),
        meta: {
          pageTitle: "PageLayoutTemplates.EditPageLayoutTemplate",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SuperAdminSettings",
              },
              {
                name: "Sidebar.PageLayoutTemplates",
                link: "/PageLayoutTemplate/List",
              },
              {
                name: "PageLayoutTemplates.EditMessageTemplate",
              },
            ];
          },
        },
      },
      {
        name: "LocalizationList",
        path: "/Localization/List",
        component: () => import("@/views/LocalizationKey/List.vue"),
        meta: {
          pageTitle: "Sidebar.MultiLanguageSettings",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.MultiLanguageSettings",
              },
            ];
          },
        },
      },
      {
        name: "LocalizationNew",
        path: "/Localization/New",
        component: () => import("@/views/LocalizationKey/New.vue"),
        meta: {
          pageTitle: "Localizations.NewTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.MultiLanguageSettings",
                link: "/Localization/List",
              },
              {
                name: "Localizations.NewTitle",
              },
            ];
          },
        },
      },
      {
        name: "LocalizationEdit",
        path: "/Localization/Edit/:localizationId",
        component: () => import("@/views/LocalizationKey/Edit.vue"),
        meta: {
          pageTitle: "Localizations.EditTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.MultiLanguageSettings",
                link: "/Localization/List",
              },
              {
                name: "Localizations.EditTitle",
              },
            ];
          },
        },
      },
      {
        name: "LayoutCompanyInformation",
        path: "/CompanyInformation/Edit",
        component: () => import("@/views/Shared/LayoutCompanyInformation.vue"),
        meta: {
          pageTitle: "CompanyInformation.DetailTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "CompanyInformation.DetailTitle",
              },
            ];
          },
        },
      },
      {
        name: "LocalizationLanguageList",
        path: "/LocalizationLanguage/List",
        component: () => import("@/views/LocalizationLanguage/List.vue"),
        meta: {
          pageTitle: "Sidebar.LocalizationSettings",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SuperAdminSettings",
              },
              {
                name: "Sidebar.LocalizationSettings",
              },
            ];
          },
        },
      },
      {
        name: "LocalizationLanguageNew",
        path: "/LocalizationLanguage/New",
        component: () => import("@/views/LocalizationLanguage/New.vue"),
        meta: {
          pageTitle: "LocalizationLanguages.NewTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SuperAdminSettings",
              },
              {
                name: "Sidebar.LocalizationSettings",
                link: "/LocalizationLanguage/List",
              },
              {
                name: "LocalizationLanguages.NewTitle",
              },
            ];
          },
        },
      },
      {
        name: "LocalizationLanguageEdit",
        path: "/LocalizationLanguage/Edit/:localizationLanguageId",
        component: () => import("@/views/LocalizationLanguage/Edit.vue"),
        meta: {
          pageTitle: "LocalizationLanguages.EditTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SuperAdminSettings",
              },
              {
                name: "Sidebar.LocalizationSettings",
                link: "/LocalizationLanguage/List",
              },
              {
                name: "LocalizationLanguages.EditTitle",
              },
            ];
          },
        },
      },
      {
        name: "InternalError",
        path: "/InternalError",
        component: () =>
          import("@/components/page-partials/system/InternalError.vue"),
        meta: {
          isHelpPage: true,
          pageTitle: "Components.LostWave.Title",
          breadcrumb(app) {
            return [
              {
                name: "BaseModelFields.Help",
              },
              {
                name: "Components.LostWave.Title",
              },
            ];
          },
        },
      },
      {
        name: "PermissionNone",
        path: "/PermissionNone",
        component: () =>
          import("@/components/page-partials/system/PermissionNone.vue"),
        meta: {
          isHelpPage: true,
          pageTitle: "Components.PermissionNone.Title",
          breadcrumb(app) {
            return [
              {
                name: "BaseModelFields.Help",
              },
              {
                name: "Components.PermissionNone.Title",
              },
            ];
          },
        },
      },
      {
        name: "PageUnderConstruction",
        path: "/PageUnderConstruction",
        component: () =>
          import("@/components/page-partials/system/PageUnderConstruction.vue"),
        meta: {
          isHelpPage: true,
          pageTitle: "Components.PageUnderConstruction.Title",
          breadcrumb(app) {
            return [
              {
                name: "BaseModelFields.Help",
              },
              {
                name: "Components.PageUnderConstruction.Title",
              },
            ];
          },
        },
      },
      {
        name: "BusinessProcessList",
        path: "/BusinessProcess/List",
        component: () => import("@/views/BusinessProcess/List.vue"),
        meta: {
          pageTitle: "BusinessProcess.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.BusinessProcess",
              },
            ];
          },
        },
      },
      {
        name: "BusinessProcessDashboard",
        path: "/BusinessProcess/Dashboard",
        component: () => import("@/views/BusinessProcess/Dashboard.vue"),
        meta: {
          pageTitle: "Sidebar.Dashboard",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.BusinessProcessManagement",
              },
              {
                name: "Sidebar.Dashboard",
              },
            ];
          },
        },
      },
      {
        name: "BusinessProcessNew",
        path: "/BusinessProcess/New",
        component: () => import("@/views/BusinessProcess/New.vue"),
        meta: {
          pageTitle: "BusinessProcess.NewTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectGeneralSettings",
              },
              {
                name: "Sidebar.BusinessProcess",
                link: "/BusinessProcess/List",
              },
              {
                name: "BusinessProcess.NewTitle",
              },
            ];
          },
        },
      },
      {
        name: "BusinessProcessEdit",
        path: "/BusinessProcess/Edit/:businessProcessId",
        component: () => import("@/views/BusinessProcess/Edit.vue"),
        meta: {
          pageTitle: "BusinessProcess.EditTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.OrganizationManagement",
              },
              {
                name: "Sidebar.BusinessProcess",
                link: "/BusinessProcess/List",
              },
              {
                name: "BusinessProcess.EditTitle",
              },
            ];
          },
        },
      },
      {
        name: "SetCodeFunctionList",
        path: "/SetCode/Function/List",
        component: () => import("@/views/SetCode/Function/List.vue"),
        meta: {
          pageTitle: "SetCodeFunctions.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SetCode",
              },
              {
                name: "Sidebar.SetCodeFunctions",
              },
            ];
          },
        },
      },
      {
        name: "SetCodeFunctionNew",
        path: "/SetCode/Function/New",
        component: () => import("@/views/SetCode/Function/New.vue"),
        meta: {
          pageTitle: "SetCodeFunctions.New",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SetCode",
              },
              {
                name: "Sidebar.SetCodeFunctions",
                link: "/SetCode/Function/List",
              },
              {
                name: "SetCodeFunctions.New",
              },
            ];
          },
        },
      },
      {
        name: "SetCodeFunctionEdit",
        path: "/SetCode/Function/Edit/:functionId",
        component: () => import("@/views/SetCode/Function/Edit.vue"),
        meta: {
          pageTitle: "SetCodeFunctions.Edit",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SetCode",
              },
              {
                name: "Sidebar.SetCodeFunctions",
                link: "/SetCode/Function/List",
              },
              {
                name: "SetCodeFunctions.Edit",
              },
            ];
          },
        },
      },
      {
        name: "SetCodeModelList",
        path: "/SetCode/Model/List",
        component: () => import("@/views/SetCode/Model/List.vue"),
        meta: {
          pageTitle: "SetCodeModels.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SetCode",
              },
              {
                name: "Sidebar.SetCodeModels",
                link: "/Project/List",
              },
            ];
          },
        },
      },
      {
        name: "SetCodeModelNew",
        path: "/SetCode/Model/New",
        component: () => import("@/views/SetCode/Model/New.vue"),
        meta: {
          pageTitle: "SetCodeModels.NewTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SetCode",
              },
              {
                name: "Sidebar.SetCodeModels",
                link: "/SetCode/Model/List",
              },
              {
                name: "SetCodeModels.NewTitle",
              },
            ];
          },
        },
      },
      {
        name: "SetCodeModelEdit",
        path: "/SetCode/Model/Edit/:modelId",
        component: () => import("@/views/SetCode/Model/Edit.vue"),
        meta: {
          pageTitle: "SetCodeModels.EditTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SetCode",
              },
              {
                name: "Sidebar.SetCodeModels",
                link: "/SetCode/Model/List",
              },
              {
                name: "SetCodeModels.EditTitle",
              },
            ];
          },
        },
      },
      {
        name: "SetCodeDatabaseList",
        path: "/SetCode/Database/List",
        component: () => import("@/views/SetCode/Database/List.vue"),
        meta: {
          pageTitle: "SetCodeDatabases.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SetCode",
              },
              {
                name: "Sidebar.SetCodeDatabases",
                link: "/Project/List",
              },
            ];
          },
        },
      },
      {
        name: "SetCodeDatabaseNew",
        path: "/SetCode/Database/New",
        component: () => import("@/views/SetCode/Database/New.vue"),
        meta: {
          pageTitle: "SetCodeDatabases.NewTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SetCode",
              },
              {
                name: "Sidebar.SetCodeDatabases",
                link: "/SetCode/Database/List",
              },
              {
                name: "SetCodeDatabases.NewTitle",
              },
            ];
          },
        },
      },
      {
        name: "SetCodeDatabaseEdit",
        path: "/SetCode/Database/Edit/:databaseId",
        component: () => import("@/views/SetCode/Database/Edit.vue"),
        meta: {
          pageTitle: "SetCodeDatabases.EditTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SetCode",
              },
              {
                name: "Sidebar.SetCodeDatabases",
                link: "/SetCode/Database/List",
              },
              {
                name: "SetCodeDatabases.EditTitle",
              },
            ];
          },
        },
      },
      {
        name: "SetCodeHttpConnectionList",
        path: "/SetCode/HttpConnection/List",
        component: () => import("@/views/SetCode/HttpConnection/List.vue"),
        meta: {
          pageTitle: "HttpConnections.Title",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SetCode",
              },
              {
                name: "Sidebar.HttpConnections",
                link: "/Project/List",
              },
            ];
          },
        },
      },
      {
        name: "SetCodeHttpConnectionNew",
        path: "/SetCode/HttpConnection/New",
        component: () => import("@/views/SetCode/HttpConnection/New.vue"),
        meta: {
          pageTitle: "HttpConnections.NewButton",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SetCode",
              },
              {
                name: "Sidebar.HttpConnections",
                link: "/SetCode/HttpConnection/List",
              },
              {
                name: "HttpConnections.NewTitle",
              },
            ];
          },
        },
      },
      {
        name: "SetCodeHttpConnectionEdit",
        path: "/SetCode/HttpConnection/Edit/:httpId",
        component: () => import("@/views/SetCode/HttpConnection/Edit.vue"),
        meta: {
          pageTitle: "HttpConnections.EditTitle",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.SetCode",
              },
              {
                name: "Sidebar.HttpConnections",
                link: "/SetCode/HttpConnection/List",
              },
              {
                name: "HttpConnections.EditTitle",
              },
            ];
          },
        },
      },
      {
        name: "FieldsDesignList",
        path: "/ProjectDesign/Field/List",
        component: () => import("@/views/ProjectDesign/Field/List.vue"),
        meta: {
          pageTitle: "CustomObjects.Fields",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectDesign",
              },
              {
                name: "CustomObjects.Fields",
              },
            ];
          },
        },
      },
      {
        name: "PageLayoutDesignList",
        path: "/ProjectDesign/PageLayout/List",
        component: () => import("@/views/ProjectDesign/PageLayout/List.vue"),
        meta: {
          pageTitle: "CustomObjects.PageLayouts",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectDesign",
              },
              {
                name: "CustomObjects.PageLayouts",
              },
            ];
          },
        },
      },
      {
        name: "ViewFilterDesignList",
        path: "/ProjectDesign/ViewFilter/List",
        component: () => import("@/views/ProjectDesign/ViewFilter/List.vue"),
        meta: {
          pageTitle: "CustomObjects.ViewFilters",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectDesign",
              },
              {
                name: "CustomObjects.ViewFilters",
              },
            ];
          },
        },
      },
      {
        name: "ObjectRelationDesignList",
        path: "/ProjectDesign/ObjectRelation/List",
        component: () =>
          import("@/views/ProjectDesign/ObjectRelation/List.vue"),
        meta: {
          pageTitle: "CustomObjects.ObjectRelations",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectDesign",
              },
              {
                name: "CustomObjects.ObjectRelations",
              },
            ];
          },
        },
      },
      {
        name: "ValidationRulesDesignList",
        path: "/ProjectDesign/ValidationRules/List",
        component: () =>
          import("@/views/ProjectDesign/ValidationRules/List.vue"),
        meta: {
          pageTitle: "CustomObjects.ValidationRules",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectDesign",
              },
              {
                name: "CustomObjects.ValidationRules",
              },
            ];
          },
        },
      },
      {
        name: "ScriptsDesignList",
        path: "/ProjectDesign/SetScript/List",
        component: () => import("@/views/ProjectDesign/SetScript/List.vue"),
        meta: {
          pageTitle: "CustomObjects.Scripts",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectDesign",
              },
              {
                name: "CustomObjects.Scripts",
              },
            ];
          },
        },
      },
      {
        name: "ButtonsDesignList",
        path: "/ProjectDesign/Button/List",
        component: () => import("@/views/ProjectDesign/Button/List.vue"),
        meta: {
          pageTitle: "CustomObjects.Buttons",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectDesign",
              },
              {
                name: "CustomObjects.Buttons",
              },
            ];
          },
        },
      },
      {
        name: "DesignFromTemplateList",
        path: "/ProjectDesign/FormTemplate/List",
        component: () => import("@/views/ProjectDesign/FormTemplate/List.vue"),
        meta: {
          pageTitle: "CustomObjects.FormTemplates",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectDesign",
              },
              {
                name: "CustomObjects.FormTemplates",
              },
            ];
          },
        },
      },
      {
        name: "DesignKanbanList",
        path: "/ProjectDesign/Kanban/List",
        component: () => import("@/views/ProjectDesign/Kanban/List.vue"),
        meta: {
          pageTitle: "CustomObjects.Kanban",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectDesign",
              },
              {
                name: "CustomObjects.Kanban",
              },
            ];
          },
        },
      },
      {
        name: "DesignCalendarList",
        path: "/ProjectDesign/Calendar/List",
        component: () => import("@/views/ProjectDesign/Calendar/List.vue"),
        meta: {
          pageTitle: "CustomObjects.CalendarSettings",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectDesign",
              },
              {
                name: "CustomObjects.CalendarSettings",
              },
            ];
          },
        },
      },
      {
        name: "DesignMapList",
        path: "/ProjectDesign/Map/List",
        component: () => import("@/views/ProjectDesign/Map/List.vue"),
        meta: {
          pageTitle: "CustomObjects.Map",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectDesign",
              },
              {
                name: "CustomObjects.Map",
              },
            ];
          },
        },
      },
      {
        name: "DesignTreeList",
        path: "/ProjectDesign/TreeView/List",
        component: () => import("@/views/ProjectDesign/TreeView/List.vue"),
        meta: {
          pageTitle: "CustomObjects.TreeViewSettings",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectDesign",
              },
              {
                name: "CustomObjects.TreeViewSettings",
              },
            ];
          },
        },
      },
      {
        name: "DesignDependencyFieldList",
        path: "/ProjectDesign/DependencyField/List",
        component: () =>
          import("@/views/ProjectDesign/DependencyField/List.vue"),
        meta: {
          pageTitle: "CustomObjects.DependencyFields",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectDesign",
              },
              {
                name: "CustomObjects.DependencyFields",
              },
            ];
          },
        },
      },
      {
        name: "DesignMessageTemplateList",
        path: "/ProjectDesign/MessageTemplate/List",
        component: () =>
          import("@/views/ProjectDesign/MessageTemplate/List.vue"),
        meta: {
          pageTitle: "CustomObjects.MessageTemplates",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectDesign",
              },
              {
                name: "CustomObjects.MessageTemplates",
              },
            ];
          },
        },
      },
      {
        name: "DesignMessageTemplateNew",
        path: "/ProjectDesign/MessageTemplate/New",
        component: () =>
          import("@/views/ProjectDesign/MessageTemplate/New.vue"),
        meta: {
          pageTitle: "MessageTemplates.NewButton",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectDesign",
              },
              {
                name: "CustomObjects.MessageTemplates",
                link: "/ProjectDesign/MessageTemplate/List",
              },
              {
                name: "MessageTemplates.NewButton",
              },
            ];
          },
        },
      },
      {
        name: "DesignMessageTemplateEdit",
        path: "/ProjectDesign/MessageTemplate/Edit/:messageTemplateId",
        component: () =>
          import("@/views/ProjectDesign/MessageTemplate/Edit.vue"),
        meta: {
          pageTitle: "MessageTemplates.EditMessageTemplate",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectDesign",
              },
              {
                name: "CustomObjects.MessageTemplates",
                link: "/ProjectDesign/MessageTemplate/List",
              },
              {
                name: "MessageTemplates.EditMessageTemplate",
              },
            ];
          },
        },
      },
      {
        name: "DesignBusinessRuleList",
        path: "/ProjectDesign/BusinessRule/List",
        component: () => import("@/views/ProjectDesign/BusinessRule/List.vue"),
        meta: {
          pageTitle: "CustomObjects.BusinessRules",
          breadcrumb(app) {
            return [
              {
                name: "Sidebar.ProjectDesign",
              },
              {
                name: "CustomObjects.BusinessRules",
              },
            ];
          },
        },
      },
    ],
  },
  {
    name: "Login",
    path: "/sign-in",
    component: () => import("@/views/Membership/Login.vue"),
    meta: {
      isAuthRequired: false,
      isMembershipPages: true,
      isSignInPage: true,
    },
  },
  // {
  //   name: "Register",
  //   path: "/sign-up",
  //   component: () => import("@/views/Membership/Register.vue"),
  //   meta: {
  //     isAuthRequired: false,
  //     isMembershipPages: true,
  //   },
  // },
  {
    name: "ForgotPassword",
    path: "/password-reset",
    component: () => import("@/views/Membership/ForgotPassword.vue"),
    meta: {
      isAuthRequired: false,
      isMembershipPages: true,
      isPasswordResetPage: true,
    },
  },
  {
    name: "ChangePassword",
    path: "/password-change",
    props: (route) => ({
      token: route.query.token,
    }),
    component: () => import("@/views/Membership/ChangePassword.vue"),
    meta: {
      isAuthRequired: false,
      isMembershipPages: true,
      isPasswordChangePage: true,
    },
  },
  {
    name: "NotFound",
    path: "/:catchAll(.*)",
    component: () => import("@/components/page-partials/system/404.vue"),
    meta: {
      isAuthRequired: true,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, _, next) => {
  const _isAuthenticated = store.getters._isAuthenticated,
    isAuthRequired = to.meta.isAuthRequired,
    noRedirectParameter = to.meta.isHelpPage,
    query = noRedirectParameter
      ? {}
      : {
          redirect: to.fullPath,
        };

  if (isAuthRequired && !_isAuthenticated)
    next({
      name: "Login",
      query: query,
    });

  if (
    _isAuthenticated &&
    (to.name == "Login" ||
      to.name == "Register" ||
      to.name == "ForgotPassword" ||
      to.name == "ChangePassword")
  )
    next({
      name: "HomePage",
    });

  if (isAuthRequired) {
    if (_isAuthenticated) next();
    else
      next({
        name: "Login",
        query: query,
      });
  } else {
    next();
  }

  // Scroll page to top on every route change
  if (!store.getters._isMobile) {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
});

export default router;
