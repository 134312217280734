<template>
  <a
    class="nav-link language-dropdown-toggle"
    href="#"
    id="languageDropDown"
    data-bs-toggle="dropdown"
  >
    <img
      :src="
        require(`@/assets/images/flags/${this.$store.state.activeLang}.png`)
      "
      :alt="this.$store.state.activeLang"
    />
  </a>
  <ul
    class="dropdown-menu dropdown-menu-end language-dropdown"
    aria-labelledby="languageDropDown"
  >
    <li
      v-for="locale in locales.filter((item) => {
        return item.code !== this.$store.state.activeLang;
      })"
      :key="locale.code"
      @click="changeLocale(locale.code)"
    >
      <a class="dropdown-item">
        <img
          :src="require(`@/assets/images/flags/${locale.code}.png`)"
          :alt="locale.code"
        />
        {{
          $t(
            "Header." + locale.code,
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </a>
    </li>
  </ul>
</template>
<script>
import { getSupportedLocales } from "@/core/i18n/supported-locales";
export default {
  data: () => ({ locales: getSupportedLocales() }),
  methods: {
    changeLocale(code) {
      this.$root.setCurrentUserLanguage(code);
    },
  },
};
</script>
