<template>
  <div class="search">
    <form>
      <input
        class="form-control"
        type="text"
        :placeholder="
          $t('Header.Search', {}, { locale: this.$store.state.activeLang })
        "
        aria-label="Search"
      />
    </form>
    <a href="#" class="toggle-search"><i class="bi bi-x-lg"></i></a>
  </div>
  <div class="app-header">
    <nav class="navbar navbar-light navbar-expand-lg">
      <div class="container-fluid">
        <div class="navbar-nav" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a
                class="nav-link sidebar-toggle-button"
                @click="onSideBarToggleButtonClick"
                href="#"
                ><i
                  class="bi bi-style"
                  :class="{
                    'bi-arrow-bar-left': !isSxAsideHidden,
                    'bi-arrow-bar-right': isSxAsideHidden,
                  }"
                ></i
              ></a>
            </li>
          </ul>
        </div>
        <div class="d-flex">
          <ul class="navbar-nav">
            <!-- <li class="nav-item">
              <a class="nav-link toggle-search" href="#"
                ><i class="bi bi-search bi-xlg"></i
              ></a>
            </li> -->
            <li class="nav-item hidden-on-mobile">
              <CheckInternetConnection />
            </li>
            <li class="nav-item hidden-on-mobile">
              <LocaleSwitcher />
            </li>
            <li class="nav-item hidden-on-mobile">
              <a class="nav-link cursor-pointer">
                <i
                  class="bi bi-arrows-angle-expand bi-xlg"
                  @click="toggleFullScreen"
                ></i>
              </a>
            </li>
            <li>
              <a class="nav-link cursor-pointer" @click="logOut()">
                <i class="bi bi-power bi-xxlg"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import $ from "jquery";
import { removeTooltips } from "@/core/helpers/dom";
import LocaleSwitcher from "@/components/custom/locale-switchers/LocaleSwitcher";
// import Notifications from "@/components/layout/Notifications.vue";
import CheckInternetConnection from "@/components/custom/connection-checker/Checker";

export default {
  name: "Header",
  components: {
    LocaleSwitcher,
    CheckInternetConnection,
    // Notifications,
  },
  data() {
    return {
      isFullScreenView: false,
      asideMinimize: Object.readCookie("SxAsideMinimize"),
      asideMinimizeCookieName: "SxAsideMinimize",
      asideMinimizeOnValue: "on",
      asideMinimizeOffValue: "off",
      isSxAsideHidden: false,
    };
  },
  methods: {
    onSideBarToggleButtonClick() {
      var sxAsideMinimize = Object.readCookie(this.asideMinimizeCookieName),
        value = this.asideMinimizeOnValue;
      if (!String.isNullOrWhiteSpace(sxAsideMinimize)) {
        value =
          sxAsideMinimize == this.asideMinimizeOnValue
            ? this.asideMinimizeOffValue
            : this.asideMinimizeOnValue;
      }
      Object.setCookie(this.asideMinimizeCookieName, value, 1);
    },
    toggleFullScreen() {
      removeTooltips();
      if (!document.fullscreenElement) {
        this.isFullScreenView = true;
        document.documentElement.requestFullscreen();
      } else if (document.exitFullscreen) {
        this.isFullScreenView = false;
        document.exitFullscreen();
      }
    },
    logOut() {
      this.$root.signOut();
    },
    asideConfiguration() {
      var sxAsideMinimize = Object.readCookie("SxAsideMinimize");
      if (
        !String.isNullOrWhiteSpace(sxAsideMinimize) &&
        sxAsideMinimize == "on"
      ) {
        this.isSxAsideHidden = true;
      }
    },
    appHeader: function () {
      $(".toggle-search").on("click", function (e) {
        $(".app").toggleClass("search-visible");
        e.preventDefault();
      });

      $(".sidebar-toggle-button").on("click", function (e) {
        e.preventDefault();
        toggleSidebar();
      });

      $(".hide-app-sidebar-mobile").on("click", function (e) {
        e.preventDefault();
        toggleSidebar();
      });

      if (!this.$isMobile()) {
        this.asideConfiguration();
      }

      function toggleSidebar() {
        if ($(".app").hasClass("menu-off-canvas")) {
          return false;
        }
        $(".app").toggleClass("sidebar-hidden");
        if ($(".app").hasClass("sidebar-hidden")) {
          setTimeout(function () {
            $(".app-sidebar .logo").addClass("hidden-sidebar-logo");
          }, 200);
          if ($(window).width() > 1199) {
            $(".sidebar-toggle-button i").attr(
              "class",
              "bi bi-arrow-bar-right bi-style"
            );
          } else {
            $(".sidebar-toggle-button i").attr(
              "class",
              "bi bi-arrow-bar-left bi-style"
            );
          }
        } else {
          $(".app-sidebar .logo").removeClass("hidden-sidebar-logo");
          if ($(window).width() > 1199) {
            $(".sidebar-toggle-button i").attr(
              "class",
              "bi bi-arrow-bar-left bi-style"
            );
          } else {
            $(".sidebar-toggle-button i").attr(
              "class",
              "bi bi-arrow-bar-right bi-style"
            );
          }
        }
        return false;
      }
    },
  },
  mounted() {
    this.appHeader();
  },
};
</script>
