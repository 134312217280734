<template>
  <div
    class="modal fade"
    :id="`modalJsonViewer`"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    :aria-labelledby="`modalJsonViewerLabel`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header p-4">
          <h5 class="modal-title" :id="`modalJsonViewerLabel`">
            <i class="bi bi-filetype-json"></i>
            {{
              $t(
                "Components.JsonViewerModal.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" :class="{ 'p-0': !jsonIsNull }">
          <template v-if="isLoading">
            <SetLoader />
          </template>
          <template v-else-if="jsonIsNull">
            <div class="alert alert-warning">
              {{
                $t(
                  "PageLayouts.PageDesignEmpty",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
          </template>
          <json-viewer
            v-if="!jsonIsNull"
            :value="json"
            :expand-depth="5"
            copyable
            sort
          ></json-viewer>
        </div>
        <div class="modal-footer p-4">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
            <i class="bi bi-x"></i>
            {{
              $t(
                "Components.JsonViewerModal.Close",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "vue-json-viewer/style.css";
import JsonViewer from "vue-json-viewer";
export default {
  name: "JsonViewerModal",
  props: ["type", "publicId", "formulaName", "previewData", "isChanged"],
  data() {
    return {
      json: {},
      jsonIsNull: true,
      isLoading: false,
    };
  },
  components: {
    JsonViewer,
  },
  watch: {
    isChanged() {
      this.defaultProperties();

      if (
        String.isNullOrWhiteSpace(this.publicId) ||
        String.isNullOrWhiteSpace(this.type)
      )
        return;

      switch (this.type) {
        case "pagelayout":
          this.getPageLayoutInfo(this.publicId);
          break;
        case "pagelayoutCopy":
          this.getPageLayout(this.publicId);
          break;
        default:
          break;
      }
    },
    previewData() {
      this.defaultProperties();

      if (!String.isNullOrWhiteSpace(this.previewData)) {
        this.jsonIsNull = false;
        this.json = JSON.parse(this.previewData);
      }
    },
  },
  methods: {
    defaultProperties() {
      this.jsonIsNull = true;
      this.json = null;
    },
    getPageLayout(id) {
      this.isLoading = true;
      this.$prodGatewayAxios
        .get(String.format("/Lcdp-PageLayoutGet?id={0}", id))
        .then((response) => {
          this.isLoading = false;
          var pageLayoutData = response.data;
          if (String.isNullOrWhiteSpace(pageLayoutData.layout)) {
            this.jsonIsNull = true;
            this.json = null;
            return;
          }

          var layout = JSON.parse(pageLayoutData.layout);
          function clearUnnecessaryElements(item) {
            if (item && item.items?.length > 0) {
              item.items = item.items.filter(
                (child) =>
                  child.type !== "field" &&
                  child.type !== "lookup" &&
                  child.type !== "button"
              );
              item.items.forEach((child) => {
                clearUnnecessaryElements(child);
              });
            }
          }

          clearUnnecessaryElements({
            items: layout,
          });

          this.jsonIsNull = false;
          this.json = layout;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    getPageLayoutInfo(publicId) {
      this.isLoading = true;
      this.$prodGatewayAxios
        .get(
          String.format(
            "/rws-SetController-PageLayoutItems?pageLayoutPublicId={0}",
            publicId
          )
        )
        .then((response) => {
          this.isLoading = false;

          var result = response.data;
          this.jsonIsNull = !result.isOk || result.fields.length == 0;
          if (result.isOk) {
            var arr = [];
            result.fields.forEach((element) => {
              arr.push({ key: element.formulaName, value: "" });
            });

            if (arr.length > 0) {
              var firstField = result.fields[0],
                coKey = firstField.customObjectKeyName,
                coId = firstField.customObjectPublicId,
                recordPublicId = null;

              this.json = {
                customObjectKey: coKey,
                customObjectPublicId: coId,
                recordPublicId: recordPublicId,
                pageLayoutId: this.publicId,
                layout: {
                  formulaName: this.formulaName,
                  publicId: this.publicId,
                },
                values: arr,
              };
            }
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    getPageLayoutCopyInfo(publicId) {
      this.isLoading = true;
      this.$prodGatewayAxios
        .get(
          String.format("/Lcdp-CopyPageLayoutWithNoItem?publicId={0}", publicId)
        )
        .then((response) => {
          this.isLoading = false;
          var result = response.data;
          this.jsonIsNull = !result.isOk;
          if (result.isOk) {
            this.json = JSON.parse(result.result);
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
};
</script>
